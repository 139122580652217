import { PeopleType } from './types';

import { HttpService } from './http-service';

export class PeopleService {
  private httpService: HttpService;
  private static instance: PeopleService;

  constructor() {
    this.httpService = HttpService.getInstance();
  }

  public async getAllPeople(): Promise<Array<PeopleType>> {
    const people  = await this.httpService.get('people', null);
    return people as Array<PeopleType>;
  }

  public async createPerson(newPerson: PeopleType): Promise<PeopleType> {
    const person = await this.httpService.post('people', newPerson, true);
    return person as PeopleType;
  }

  public static getInstance(): PeopleService {
    if (!PeopleService.instance) {
        PeopleService.instance = new PeopleService();
    }

    return PeopleService.instance;
  }
}

export type {
  PeopleType,
};
