import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { ButtonPropsType } from './types';

import Styles from './styles.module.css';

export default function Button(props: ButtonPropsType) {
  const {
    onClick, label, disabled, buttonClassName,
  } = props;

  const onClickHandler = (event: any) => {
    event.preventDefault();
    onClick();
  };

  return (
    <>
      <Row className="mx-0 w-100">
        <Col className="px-0 d-flex justify-content-start align-items-center w-100 py-2">
          <button className={buttonClassName ? buttonClassName : Styles.button} onClick={onClickHandler} disabled={disabled}>{label}</button>
        </Col>
      </Row>
    </>
  );
}
