import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { useDispatch } from "react-redux"

import { InformationSectionPropsType } from "./types"

import {
  AuthenticatedUserType,
  PersonPublicInformationType
} from "../../../../services/types"

import Button from "../../../../components/button"
import Input from "../../../../components/input"
import { UsersService } from "../../../../services/users-service"
import { LoginActions } from "../../../login/slice"
import { InformationSectionLabels } from "./labels"

import Styles from "./styles.module.css"

const service: UsersService = UsersService.getInstance()

export default function InformationSection(props: InformationSectionPropsType) {
  const {
    firstName,
    lastName,
    publicInformation,
    email,
    userId,
    currentLanguage,
    phoneNumber,
    isPublic,
    imageRoute,
    role
  } = props

  const publicInformationInSpanish: PersonPublicInformationType | undefined =
    publicInformation.find(
      (item: PersonPublicInformationType): boolean =>
        item.language.toLowerCase() === "spanish"
    )

  const publicInformationInEnglish: PersonPublicInformationType | undefined =
    publicInformation.find(
      (item: PersonPublicInformationType): boolean =>
        item.language.toLowerCase() === "english"
    )

  const [formActive, setFormActive] = useState<boolean>(false)
  const [passwordFormActive, setPasswordFormActive] = useState<boolean>(false)
  const [newFirstName, setNewFirstName] = useState<string>(firstName)
  const [newLastName, setNewLastName] = useState<string>(lastName)
  const [newEmail, setNewEmail] = useState<string>(email)
  const [newImageRoute, setNewImageRoute] = useState<string>(imageRoute)
  const [newPhoneNumber, setNewPhoneNumber] = useState<string>(phoneNumber)

  const [newJobTitleInSpanish, setNewJobTitleInSpanish] = useState<string>(
    (publicInformationInSpanish as PersonPublicInformationType).jobTitle
  )

  const [newJobTitleInEnglish, setNewJobTitleInEnglish] = useState<string>(
    (publicInformationInEnglish as PersonPublicInformationType).jobTitle
  )

  const [previousPassword, setPreviousPassword] = useState<string>("")
  const [newPassword, setNewPassword] = useState<string>("")
  const [repeatedNewPassword, setRepeatedNewPassword] = useState<string>("")
  const dispatch = useDispatch()

  const updateUser = async () => {
    dispatch(LoginActions.startEditingAuthenticatedUser())

    const updatedUser: AuthenticatedUserType | null = await service.updateUser(
      userId,
      newFirstName,
      newLastName,
      [
        {
          ...(publicInformationInEnglish as PersonPublicInformationType),
          jobTitle: newJobTitleInEnglish
        },
        {
          ...(publicInformationInSpanish as PersonPublicInformationType),
          jobTitle: newJobTitleInSpanish
        }
      ],
      isPublic,
      newPhoneNumber,
      newEmail,
      role,
      newImageRoute
    )

    dispatch(
      LoginActions.setAuthenticatedUser(updatedUser as AuthenticatedUserType)
    )
    dispatch(LoginActions.finishEditingAuthenticatedUser())
  }

  const isPasswordChangeFormValid = () => {
    if (!previousPassword || !newPassword || !repeatedNewPassword) {
      return false
    }

    if (previousPassword === newPassword) {
      return false
    }

    if (repeatedNewPassword !== newPassword) {
      return false
    }

    return true
  }

  const resetPassword = async () => {
    setPasswordFormActive(false)
  }

  const getCurrentJobTitle = () =>
    currentLanguage === "english"
      ? (publicInformationInEnglish as PersonPublicInformationType).jobTitle
      : (publicInformationInSpanish as PersonPublicInformationType).jobTitle

  return (
    <>
      {!formActive && !passwordFormActive && (
        <>
          <Row className="mx-0">
            <Col className="px-0">
              <span className={Styles.name}>{`${firstName} ${lastName}`}</span>
            </Col>
          </Row>

          <Row className="mx-0">
            <Col className="px-0">
              <span className={Styles.jobTitle}>{getCurrentJobTitle()}</span>
            </Col>
          </Row>

          <Row className="mx-0">
            <Col className="px-0">
              <span className={Styles.email}>{email}</span>
            </Col>
          </Row>
        </>
      )}

      {formActive && (
        <>
          <Row className="w-100 mx-0">
            <Col className="px-0">
              <Input
                placeholder={
                  InformationSectionLabels.EditInformationForm[currentLanguage]
                    .FirstNamePlaceholder
                }
                label={
                  InformationSectionLabels.EditInformationForm[currentLanguage]
                    .FirstNameLabel
                }
                value={newFirstName}
                setValue={setNewFirstName}
              />
            </Col>
          </Row>

          <Row className="w-100 mx-0">
            <Col className="px-0">
              <Input
                placeholder={
                  InformationSectionLabels.EditInformationForm[currentLanguage]
                    .LastNamePlaceholder
                }
                label={
                  InformationSectionLabels.EditInformationForm[currentLanguage]
                    .LastNameLabel
                }
                value={newLastName}
                setValue={setNewLastName}
              />
            </Col>
          </Row>

          <Row className="w-100 mb-4 mx-0">
            <Col className="px-0">
              <Input
                placeholder={
                  InformationSectionLabels.EditInformationForm[currentLanguage]
                    .JobTitleInSpanishPlaceholder
                }
                label={
                  InformationSectionLabels.EditInformationForm[currentLanguage]
                    .JobTitleInSpanishLabel
                }
                value={newJobTitleInSpanish}
                setValue={setNewJobTitleInSpanish}
              />
            </Col>
          </Row>

          <Row className="w-100 mb-4 mx-0">
            <Col className="px-0">
              <Input
                placeholder={
                  InformationSectionLabels.EditInformationForm[currentLanguage]
                    .JobTitleInEnglishPlaceholder
                }
                label={
                  InformationSectionLabels.EditInformationForm[currentLanguage]
                    .JobTitleInEnglishLabel
                }
                value={newJobTitleInEnglish}
                setValue={setNewJobTitleInEnglish}
              />
            </Col>
          </Row>

          <Row className="w-100 mx-0">
            <Col className="px-0">
              <Input
                placeholder={
                  InformationSectionLabels.EditInformationForm[currentLanguage]
                    .EmailPlaceholder
                }
                label={
                  InformationSectionLabels.EditInformationForm[currentLanguage]
                    .EmailLabel
                }
                value={newEmail}
                setValue={setNewEmail}
              />
            </Col>
          </Row>

          <Row className="w-100 mx-0">
            <Col className="px-0">
              <Input
                placeholder={
                  InformationSectionLabels.EditInformationForm[currentLanguage]
                    .PhoneNumberPlaceholder
                }
                label={
                  InformationSectionLabels.EditInformationForm[currentLanguage]
                    .PhoneNumberLabel
                }
                value={newPhoneNumber}
                setValue={setNewPhoneNumber}
              />
            </Col>
          </Row>

          <Row className="w-100 mx-0">
            <Col className="px-0">
              <Input
                placeholder={
                  InformationSectionLabels.EditInformationForm[currentLanguage]
                    .ImageRoutePlaceholder
                }
                label={
                  InformationSectionLabels.EditInformationForm[currentLanguage]
                    .ImageRouteLabele
                }
                value={newImageRoute}
                setValue={setNewImageRoute}
              />
            </Col>
          </Row>
        </>
      )}

      {passwordFormActive && (
        <>
          <Row className="w-100 mx-0">
            <Col className="px-0">
              <Input
                label={
                  InformationSectionLabels.EditPasswordForm[currentLanguage]
                    .CurrentPasswordLabel
                }
                placeholder={
                  InformationSectionLabels.EditPasswordForm[currentLanguage]
                    .CurrentPasswordPlaceholder
                }
                value={previousPassword}
                setValue={setPreviousPassword}
                type="password"
              />
            </Col>
          </Row>

          <Row className="w-100 mx-0">
            <Col className="px-0">
              <Input
                label={
                  InformationSectionLabels.EditPasswordForm[currentLanguage]
                    .NewPasswordLabel
                }
                placeholder={
                  InformationSectionLabels.EditPasswordForm[currentLanguage]
                    .NewPasswordPlaceholder
                }
                value={newPassword}
                setValue={setNewPassword}
                type="password"
              />
            </Col>
          </Row>

          <Row className="w-100 mb-4 mx-0">
            <Col className="px-0">
              <Input
                label={
                  InformationSectionLabels.EditPasswordForm[currentLanguage]
                    .NewPasswordAgainLabel
                }
                placeholder={
                  InformationSectionLabels.EditPasswordForm[currentLanguage]
                    .NewPasswordAgainPlaceholder
                }
                value={repeatedNewPassword}
                setValue={setRepeatedNewPassword}
                type="password"
              />
            </Col>
          </Row>
        </>
      )}

      <Row className="mt-4 mx-0 w-100">
        <Col className="px-0 d-flex justify-content-start">
          {!passwordFormActive && (
            <Button
              label={
                formActive
                  ? InformationSectionLabels.SaveInfoButton.Save[
                      currentLanguage
                    ]
                  : InformationSectionLabels.SaveInfoButton.Edit[
                      currentLanguage
                    ]
              }
              disabled={false}
              onClick={() => {
                setFormActive(!formActive)

                if (formActive) {
                  updateUser()
                }
              }}
              buttonClassName={Styles.button}
            />
          )}

          {!formActive && (
            <Button
              label={
                passwordFormActive
                  ? InformationSectionLabels.SavePasswordButton.SavePassword[
                      currentLanguage
                    ]
                  : InformationSectionLabels.SavePasswordButton.ChangePassword[
                      currentLanguage
                    ]
              }
              disabled={
                passwordFormActive ? !isPasswordChangeFormValid() : false
              }
              onClick={() => {
                if (passwordFormActive) {
                  resetPassword()
                } else {
                  setPasswordFormActive(true)
                }
              }}
              buttonClassName={Styles.button}
            />
          )}

          {(formActive || passwordFormActive) && (
            <Button
              label={InformationSectionLabels.CancelButton[currentLanguage]}
              disabled={false}
              onClick={() => {
                setFormActive(false)
                setPasswordFormActive(false)
              }}
              buttonClassName={Styles.button}
            />
          )}
        </Col>
      </Row>
    </>
  )
}
