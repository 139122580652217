import FormData from "form-data"

export class HttpService {
  private static instance: HttpService
  baseApiUrl: string
  baseUrl: string

  constructor() {
    this.baseApiUrl = "https://api.page.ogilvyhn.com/v1/"
    this.baseUrl = "http://credenciales.ogilvyhn.com/"
  }

  public getBaseUrl(): string {
    return this.baseUrl
  }

  private convertParametersIntoQueryString(parameters: any): string {
    if (!parameters) return ""

    const formattedValues: Array<string> = Object.keys(parameters).map(
      (key: string): string => {
        const formattedKey: string = key.replace(" ", "%20")
        const formattedValue: string = String(parameters[key]).replace(
          " ",
          "%20"
        )

        return `${formattedKey}=${formattedValue}`
      }
    )

    return formattedValues.length > 0 ? `?${formattedValues.join("&")}` : ""
  }

  private getHeaders(shouldBeAuthenticated: boolean): any {
    const headers: any = {
      "Content-Type": "application/json"
    }

    if (shouldBeAuthenticated) {
      const token: string | null = localStorage.getItem("token")
      headers["Authorization"] = `Bearer ${token}`
    }

    return headers
  }

  public async get(
    route: string,
    parameters: any,
    shouldBeAuthenticated: boolean = true
  ): Promise<any> {
    const headers = this.getHeaders(shouldBeAuthenticated)

    const options = {
      method: "GET",
      headers
    }

    const response: any = await fetch(
      `${this.baseApiUrl}${route}${this.convertParametersIntoQueryString(
        parameters
      )}`,
      options
    )

    const responseAsJson = await response.json()

    if (responseAsJson.code === 401) {
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      throw new Error("Unauthorized")
    }

    return responseAsJson
  }

  public async post(
    route: string,
    body: any,
    shouldBeAuthenticated: boolean
  ): Promise<any> {
    const headers = this.getHeaders(shouldBeAuthenticated)

    const options = {
      method: "POST",
      headers,
      body: JSON.stringify(body)
    }

    const response: any = await fetch(`${this.baseApiUrl}${route}`, options)
    const responseAsJson = await response.json()

    if (responseAsJson.code === 401) {
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      throw new Error("Unauthorized")
    }

    return responseAsJson
  }

  public async upload(
    route: string,
    file: File,
    shouldBeAuthenticated: boolean
  ): Promise<any> {
    const headers = this.getHeaders(shouldBeAuthenticated)
    delete headers["Content-Type"]

    let form: any = new FormData()
    form.append("profile_picture", file)

    const options = {
      method: "POST",
      headers,
      body: form
    }

    const response: any = await fetch(`${this.baseApiUrl}${route}`, options)
    // const response: any = await fetch('http://201.190.5.84:3396/upload', options);
    const responseAsJson = await response.json()

    if (responseAsJson.code === 401) {
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      throw new Error("Unauthorized")
    }

    return responseAsJson
  }

  public async put(
    route: string,
    body: any,
    shouldBeAuthenticated: boolean
  ): Promise<any> {
    const headers = this.getHeaders(shouldBeAuthenticated)

    const options = {
      method: "PUT",
      headers,
      body: JSON.stringify(body)
    }

    const response: any = await fetch(`${this.baseApiUrl}${route}`, options)
    const responseAsJson = await response.json()

    if (responseAsJson.code === 401) {
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      throw new Error("Unauthorized")
    }

    return responseAsJson
  }

  public async delete(
    route: string,
    shouldBeAuthenticated: boolean = true
  ): Promise<any> {
    const headers = this.getHeaders(shouldBeAuthenticated)

    const options = {
      method: "DELETE",
      headers
    }

    const response: any = await fetch(`${this.baseApiUrl}${route}`, options)

    const responseAsJson = await response.json()

    if (responseAsJson.code === 401) {
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      throw new Error("Unauthorized")
    }

    return responseAsJson
  }

  public static getInstance(): HttpService {
    if (!HttpService.instance) {
      HttpService.instance = new HttpService()
    }

    return HttpService.instance
  }
}
