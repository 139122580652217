import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { MenuPropsType } from './types';
import { PageType } from '../../../../services/types';

import PageMenuItem from './page-menu-item';

import Styles from './styles.module.css';

const COLORS: Array<string> = [
  'rgb(66,66,160)',
  'rgb(184,182,182)',
  'rgb(214,94,46)',
  'rgb(66,66,160)',
  'rgb(184,182,182)',
  'rgb(214,94,46)',
  'rgb(66,66,160)',
  'rgb(184,182,182)',
  'rgb(214,94,46)',
  'rgb(66,66,160)',
  'rgb(184,182,182)',
  'rgb(214,94,46)',
];

export default function PagesList(props: MenuPropsType) {
  const { pages, isAtTheTopOfThePage, selectPage } = props;

  const getPageMenuItem = (page: PageType, index: number) => {
    return (
      <PageMenuItem
        id={page.id}
        title={page.name}
        color={COLORS[index]}
        timer={100 + (index * 100)}
        route={`${page.id}`}
        selectPage={selectPage}
      />
    );
  };

  return (
    <Row className={`${Styles.container} mx-4 px-4`}>
      {
        pages.map((page: PageType, index: number) => (
          <Col
            className={`p-1`}
            xl={4} lg={6} md={6} sm={12} xs={12}
            key={page.id}
          >
            {getPageMenuItem(page, index)}
          </Col>
        ))
      }
    </Row>
  );
}
