/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { SubcategorySelectedFormPropsType } from './types';
import { GraphicSupportType } from '../../../../services/types';

import Input from '../../../../components/input';
import ColorInput from '../../../../components/color-input';
import ContentsType from '../contents-type';
import WorksContentType from '../works-content-type';
import PeopleContentType from '../people-content-type';
import GraphicSupportTypeForm from '../graphic-support-type-form';
import useSubcategoryFormLogic from './use-subcategory-form-logic';

export default function SubcategorySeletedForm(props: SubcategorySelectedFormPropsType) {
  const { categoryId, information, updateSubcategoryInState } = props;

  const {
    updateTitle,
    updateLabelFontColor,
    updateLabelBorderColor,
    updateLabelBackgroundColor,
    updateContents,
    updateWorks,
    updatePeople,
    updateGraphicSupport,
  } = useSubcategoryFormLogic({ information, updateSubcategoryInState });

  return (
    <>
      <div className="mb-4">
        <Input
          value={information.title}
          setValue={updateTitle}
          placeholder="Título"
          label="Título de la subcategoria"
        />
      </div>

      <div className="mb-4">
        <Row>
          <Col xl={4} lg={4} md={6} sm={12} xs={12}>
            <ColorInput
              value={information.label.fontColor}
              setValue={updateLabelFontColor}
              placeholder="Color"
              label="Color de fuente del título"
            />
          </Col>

          <Col xl={4} lg={4} md={6} sm={12} xs={12}>
            <ColorInput
              value={information.label.borderColor}
              setValue={updateLabelBorderColor}
              placeholder="Color"
              label="Color de borde del título"
            />
          </Col>
          
          <Col xl={4} lg={4} md={6} sm={12} xs={12}>
            <ColorInput
              value={information.label.backgroundColor}
              setValue={updateLabelBackgroundColor}
              placeholder="Color"
              label="Color de fondo del título"
            />
          </Col>
        </Row>
        
      </div>

      {
        information.type === "content-list" && (
          <ContentsType contents={information.contents} updateContents={updateContents} />
        )
      }

      {
        information.type === "works" && (
          <WorksContentType works={information.works} updateWorks={updateWorks} categoryId={categoryId} />
        )
      }

      {
        information.type === "people" && (
          <PeopleContentType people={information.people} updatePeople={updatePeople} />
        )
      }

      {
        information.type === "graphic-support" && (
          <GraphicSupportTypeForm
            graphicSupport={information.graphicSupport as GraphicSupportType}
            updateGraphicSupport={updateGraphicSupport}
          />
        )
      }

    </>
  )
}
