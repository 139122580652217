import React, { useState } from "react"
import useDeepCompareEffect from "use-deep-compare-effect"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { PeopleTypePropsType } from "./types"
import { PeopleType } from "../../../../services/types"

import Input from "../../../../components/input"
import AddPersonModal from "../../../../components/add-person-modal"

import Styles from "./styles.module.css"

export default function PeopleSectionType(props: PeopleTypePropsType) {
  const [people, setPeople] = useState(props.people)
  const [isModalDisplayed, displayModal] = useState<boolean>(false)

  useDeepCompareEffect(() => {
    setPeople(props.people)
  }, [props.people])

  const setName = (newName: string, index: number) => {
    const newPeople = [...people]
    newPeople[index] = { ...newPeople[index], name: newName }
    props.updatePeople([...newPeople])
  }

  const setJobTitle = (newJobTitle: string, index: number) => {
    const newPeople = [...people]
    newPeople[index] = { ...newPeople[index] }
    props.updatePeople([...newPeople])
  }

  const setEmail = (newEmail: string, index: number) => {
    const newPeople = [...people]
    newPeople[index] = { ...newPeople[index], email: newEmail }
    props.updatePeople([...newPeople])
  }

  const setPhoneNumber = (newPhoneNumber: string, index: number) => {
    const newPeople = [...people]
    newPeople[index] = { ...newPeople[index], phoneNumber: newPhoneNumber }
    props.updatePeople([...newPeople])
  }

  const setDescription = (newDescription: string, index: number) => {
    const newPeople = [...people]
    newPeople[index] = { ...newPeople[index] }
    props.updatePeople([...newPeople])
  }

  const setImageRoute = (newImageRoute: string, index: number) => {
    const newPeople = [...people]
    newPeople[index] = { ...newPeople[index], imageRoute: newImageRoute }
    props.updatePeople([...newPeople])
  }

  const deletePerson = (index: number) => {
    const newPeople = [...people]
    newPeople.splice(index, 1)
    props.updatePeople([...newPeople])
  }

  const createPerson = (
    name: string,
    jobTitle: string,
    email: string,
    phoneNumber: string,
    description: string,
    imageRoute: string
  ) => {
    const newPerson: PeopleType = {
      name,
      email,
      phoneNumber,
      imageRoute,
      branch: people[0].branch,
      id: "____",
      publicInformation: []
    }

    const newPeople = [...people]
    newPeople.push(newPerson)

    props.updatePeople(newPeople)
  }

  return (
    <div className={Styles.worksTypeContainer}>
      <AddPersonModal
        show={isModalDisplayed}
        branch={people[0].branch}
        onHide={() => displayModal(false)}
        createPerson={createPerson}
      />

      <Row className="mx-0 w-100">
        <Col className="px-0 pb-4 d-flex justify-content-center align-items-center w-100 py-2">
          <span className={`${Styles.title} pl-4`}>Talento Humano</span>
        </Col>
      </Row>

      <Row className="mx-0 w-100">
        <Col className="px-0 pb-4 d-flex justify-content-end align-items-center w-100 py-2">
          <div
            className={`${Styles.addUserContainer} d-flex align-items-center`}
            onClick={() => displayModal(true)}
          >
            <img
              className={`${Styles.addUserIcon} mr-2`}
              src="/icons/plus.png"
              alt="plus-icon"
            />
            <span className={Styles.addUserText}>Agregar</span>
          </div>
        </Col>
      </Row>

      <Row className="mx-0 w-100 py-4">
        {people.map((person: PeopleType, index: number) => {
          return (
            <Col
              key={person.id}
              xl={6}
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="mb-4 pb-4"
            >
              <div
                className={`${Styles.indexBlock} d-flex align-items-center justify-content-center`}
              >
                {index + 1}
              </div>

              <div className="mb-1">
                <Input
                  value={person.name}
                  setValue={(newName: string) => setName(newName, index)}
                  placeholder="Nombre"
                  label={`Nombre del miembro del equipo`}
                />
              </div>

              <div className="mb-1">
                <Input
                  value={""}
                  setValue={(newJobTitle: string) =>
                    setJobTitle(newJobTitle, index)
                  }
                  placeholder="Puesto de trabajo"
                  label={`Puesto de trabajo del miembro del equipo`}
                />
              </div>

              <div className="mb-1">
                <Input
                  value={person.email}
                  setValue={(newEmail: string) => setEmail(newEmail, index)}
                  placeholder="Correo electrónico"
                  label={`Correo electrónico del miembro del equipo`}
                />
              </div>

              <div className="mb-1">
                <Input
                  value={person.phoneNumber}
                  setValue={(newPhoneNumber: string) =>
                    setPhoneNumber(newPhoneNumber, index)
                  }
                  placeholder="Número de teléfono"
                  label={`Número de teléfono del miembro del equipo`}
                />
              </div>

              <div className="mb-1">
                <Input
                  value={""}
                  setValue={(newDescription: string) =>
                    setDescription(newDescription, index)
                  }
                  placeholder="Descripción"
                  label={`Descripción del miembro del equipo`}
                />
              </div>

              <div className="mb-1">
                <Input
                  value={person.imageRoute}
                  setValue={(newImageRoute: string) =>
                    setImageRoute(newImageRoute, index)
                  }
                  placeholder="Ruta"
                  label={`Ruta de la imagen de perfil`}
                />
              </div>

              <div className="mb-4 d-flex justify-content-end">
                <button
                  className={Styles.delete}
                  onClick={() => deletePerson(index)}
                >
                  Eliminar
                </button>
              </div>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}
