import { CategoryType, SubcategoryType } from './types';

import { HttpService } from './http-service';

export class CategoriesService {
  private httpService: HttpService;
  private static instance: CategoriesService;

  constructor() {
    this.httpService = HttpService.getInstance();
  }

  public async getCategories(language: string): Promise<Array<CategoryType>> {
    const categories  = await this.httpService.get(`categories/${language}`, null);
    return categories as Array<CategoryType>;
  }

  public async getCategory(categoryId: string): Promise<CategoryType> {
    const category  = await this.httpService.get(`categories/single/${categoryId}`, null);
    return category as CategoryType;
  }

  public async updateCategory(category: CategoryType): Promise<CategoryType> {
    const updatedCategory = await this.httpService.put(`categories/single/${category.id}`, category, true);
    return updatedCategory as CategoryType;
  }

  public static getInstance(): CategoriesService {
    if (!CategoriesService.instance) {
        CategoriesService.instance = new CategoriesService();
    }

    return CategoriesService.instance;
  }
}

export type {
  CategoryType,
  SubcategoryType,
};
