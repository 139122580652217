/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { UserInfoPropsType } from "./types"

import { Roles } from "../../roles"

import Styles from "./styles.module.css"

export default function UserInfo(props: UserInfoPropsType) {
  const {
    user: { email, firstName, lastName, jobTitle, imageRoute, role },
    editUser,
    deleteUser
  } = props

  const getRole = () =>
    Roles.find((item) => item.name === role)?.displayName ?? ""

  const getRoleClassName = () => {
    if (role === "ADMIN") return Styles.roleAdmin
    if (role === "VISITOR") return Styles.roleVisitor
    if (role === "HR") return Styles.roleHr
    if (role === "AUTHOR") return Styles.roleAuthor
    if (role === "EDITOR") return Styles.roleEditor

    return ""
  }

  return (
    <Row className="mx-0 mb-4">
      <Col
        className={`${Styles.imageContainer} d-flex justify-content-end align-items-start`}
        xl={4}
        lg={4}
        md={12}
        sm={12}
        xs={12}
      >
        <img
          className={`${Styles.profilePicure} rounded-circle`}
          src={imageRoute}
          alt="profile-picture"
        />
      </Col>

      <Col
        xl={8}
        lg={8}
        md={12}
        sm={12}
        xs={12}
        className={`${Styles.informationContainer} d-flex flex-column justify-content-center align-items-start`}
      >
        <Row className="mx-0">
          <Col className="px-0">
            <span className={Styles.name}>{`${firstName} ${lastName}`}</span>
          </Col>
        </Row>

        <Row className="mx-0">
          <Col className="px-0">
            <span className={Styles.jobTitle}>{jobTitle}</span>
          </Col>
        </Row>

        <Row className="mx-0">
          <Col className="px-0">
            <span className={Styles.email}>{email}</span>
          </Col>
        </Row>

        <Row className="mx-0">
          <Col className="px-0 pt-3">
            <span className={`${getRoleClassName()} py-1 px-4`}>
              {getRole()}
            </span>
          </Col>
        </Row>

        <Row className="mx-0">
          <Col className="px-0 pt-3">
            <img
              className={`${Styles.editIcon} mr-3`}
              src="/icons/pencil.png"
              alt="trash-icon"
              onClick={editUser}
            />

            <img
              className={Styles.deleteIcon}
              src="/icons/trash.png"
              alt="trash-icon"
              onClick={deleteUser}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
