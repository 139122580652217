// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__6oLTV {\n  height: 800px;\n  width: auto;\n  padding-left: 100px;\n  padding-right: 100px;\n}\n\n.styles_controlContainer__3j14E {\n  width: 100%;\n  height: 400px;\n}\n\n.styles_control__PKDyP {\n  width: 100px;\n}\n\n.styles_control__PKDyP img {\n  height: 40px;\n  cursor: pointer;\n}\n\n.styles_center__2cZ-d {\n  width: calc(100% - 200px);\n}\n\n.styles_peopleContainer__2t4Li {\n  width: 100%;\n  height: 100%;\n  display: block;\n  overflow-x: hidden;\n  white-space: nowrap;\n  scroll-behavior: smooth;\n}\n", "",{"version":3,"sources":["webpack://src/pages/category/components/gallery-people/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".container {\n  height: 800px;\n  width: auto;\n  padding-left: 100px;\n  padding-right: 100px;\n}\n\n.controlContainer {\n  width: 100%;\n  height: 400px;\n}\n\n.control {\n  width: 100px;\n}\n\n.control img {\n  height: 40px;\n  cursor: pointer;\n}\n\n.center {\n  width: calc(100% - 200px);\n}\n\n.peopleContainer {\n  width: 100%;\n  height: 100%;\n  display: block;\n  overflow-x: hidden;\n  white-space: nowrap;\n  scroll-behavior: smooth;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__6oLTV",
	"controlContainer": "styles_controlContainer__3j14E",
	"control": "styles_control__PKDyP",
	"center": "styles_center__2cZ-d",
	"peopleContainer": "styles_peopleContainer__2t4Li"
};
export default ___CSS_LOADER_EXPORT___;
