import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { PaginationControlsPropsType } from './types';

import Styles from './styles.module.css';

export default function PaginationControls(props: PaginationControlsPropsType) {
  const {
    previousButton,
    disablePreviousButton,
    nextButton,
    disableNextButton,
  } = props;

  return (
    <Row className={`mx-0 p-4 w-100`}>
      <Col className={`px-4 d-flex justify-content-end align-items-center`}>
        <img
          className={`${disablePreviousButton ? Styles.disabledIcon : Styles.icon}`}
          src="/icons/arrow-left-gray.png"
          onClick={() => {
            if (!disablePreviousButton) {
              previousButton();
            }
          }}
          alt="arrow_left"
        />
      </Col>

      <span className="mx-1" />

      <Col className={`px-4 d-flex justify-content-start align-items-center`}>
        <img
          className={`${disableNextButton ? Styles.disabledIcon : Styles.icon}`}
          src="/icons/arrow-right-gray.png"
          onClick={() => {
            if (!disableNextButton) {
              nextButton();
            }
          }}
          alt="arrow_right"
        />
      </Col>
    </Row>
  );
}
