import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { PeopleTypePropsType } from './types';

import Styles from './styles.module.css';

export default function PeopleSectionType(props: PeopleTypePropsType) {
  return (
    <div className={Styles.worksTypeContainer}>
      <Row className="mx-0 w-100 pb-4">
        <Col className="px-0 pb-4 d-flex justify-content-center align-items-center w-100 py-2">
          <span className={`${Styles.title} pl-4`}>Talento Humano</span>
        </Col>
      </Row>
      <Row className="mx-0 w-100 pb-4 px-4">
        <Col className="px-4 pb-4 d-flex justify-content-center align-items-center w-100 py-2">
          <span className={`${Styles.title} pl-4`}>Para editar las personas listadas en esta sección, edita dentro del contenido privado las subscategorías de <strong>Talento Humano SPS</strong> y <strong>Talento Humano TGU</strong> en la categoría <strong>Compañía</strong>.</span>
        </Col>
      </Row>
    </div>
  );
}
