/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useLocation } from 'react-router-dom';

import useHeightChangerByScrolling from './height-changer-by-scrolling';
import MenuIcon from './menu-icon';
import LanguageButton from './language-button';

import Styles from './styles.module.css';

export default function Navbar() {
  const location = useLocation();
  const { classNameForHeight } = useHeightChangerByScrolling();

  const getLogo = () => {
    return location.pathname.includes('editar') || location.pathname.includes('usuarios') || location.pathname.includes('blogs') || location.pathname.includes('crear-blog') || location.pathname.includes('editar-blog')
      ? (
        <Link to="/editar">
          <img className={`${Styles.adminLogoImage} mr-4`} src="/images/logo-admin.png" alt="ogilvy-admin-logo" />
        </Link>
      )
      : (
        <Link to="/">
          <img className={`${Styles.logoImage} mr-4`} src="/images/logo.png" alt="ogilvy-logo" />
        </Link>
      );
  };

  return (
    <Row className={`${Styles.container} ${classNameForHeight} mx-0 bg-white px-4`}>
      <Col xs={8} className="d-flex align-items-center px-4">
        {getLogo()}
      </Col>

      <Col xs={4} className="d-flex align-items-center justify-content-end px-4">
        <LanguageButton />
        <MenuIcon />
      </Col>
    </Row>
  )
}
