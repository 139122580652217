import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { AddWorkModalPropsType } from './types';

import Input from '../input';
import TextArea from '../textarea';

import Styles from './styles.module.css';
import useResponsiveImagesEditor from "../../pages/edit-page/components/responsive-images-editor";

export default function AddWorkModal(props: AddWorkModalPropsType) {
  const {
    show, onHide, createWork,
  } = props;

  const [title, setTitle] = useState<string>('');
  const [isTitleValid, setTitleValidity] = useState<boolean>(true);

  const [subtitle, setSubtitle] = useState<string>('');
  const [isSubtitleValid, setSubtitleValidity] = useState<boolean>(true);

  const [paragraph, setParagraph] = useState<string>('');
  const [isParagraphValid, setParagraphValidity] = useState<boolean>(true);

  const [videoUrl, setVideoUrl] = useState<string>('');
  const [isVideoUrlValid, setVideoUrlValidity] = useState<boolean>(true);

  const [order, setOrder] = useState<string>('');
  const [isOrderValid, setOrderValid] = useState<boolean>(true);

  const {
    setResponsiveImageWidth,
    setResponsiveImageHeight,
    setResponsiveImagePath,
    setResponsiveImageOrder,
    addNewResponsiveImage,
    removeResponsiveImage,
    updatedResponsiveImages,
  } = useResponsiveImagesEditor({ responsiveImages: []  });

  const clearForm = () => {
    setTitle('');
    setSubtitle('');
    setParagraph('');
    setVideoUrl('');
    setOrder('');

    setTitleValidity(true);
    setSubtitleValidity(true);
    setParagraphValidity(true);
    setVideoUrlValidity(true);
    setOrderValid(true);
  };

  const determineIfTitleIsValid = () => {
    setTitleValidity(title.trim() !== '');
  };

  const determineIfSubtitleIsValid = () => {
    setSubtitleValidity(subtitle.trim() !== '');
  };

  const determineIfParagraphIsValid = () => {
    setParagraphValidity(paragraph.trim() !== '');
  };

  const determineIfVideoUrlIsValid = () => {
    setVideoUrlValidity(videoUrl.trim() !== '');
  };

  const determineIfOrderIsValid = () => {
    setOrderValid(order.trim() !== '' && !isNaN(Number(order)));
  };

  const isFormValid = () => {
    return isTitleValid && isSubtitleValid && isParagraphValid && isVideoUrlValid
      && isOrderValid && updatedResponsiveImages.length > 0;
  };

  const onSave = () => {
    determineIfTitleIsValid();
    determineIfSubtitleIsValid();
    determineIfParagraphIsValid();
    determineIfVideoUrlIsValid();
    determineIfOrderIsValid();

    if (!isFormValid()) return;

    createWork(title, subtitle, paragraph, videoUrl, updatedResponsiveImages, order);
    clearForm();
    onHide();
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="modal-question"
      centered
      show={show}
      onHide={() => {
        clearForm();
        onHide();
      }}
    >
      <Modal.Body>
        <Row className="mx-0 py-3">
          <Col className="d-flex justify-content-start align-items-center">
            <span className={Styles.modalTitle}>{'Agregar un nuevo caso de éxito'}</span>
          </Col>

          <Col className="d-flex justify-content-end align-items-center">
            <img
              onClick={() => {
                clearForm();
                onHide();
              }}
              className={Styles.closeIcon}
              src="/icons/cancel-gray.png"
              alt="close-icon"
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">  
          <Col className="px-3">
            <Input
              placeholder="Título"
              label="Título del caso"
              value={title}
              setValue={setTitle}
              isValid={isTitleValid}
              onBlur={() => determineIfTitleIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mb-4 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Subtítulo"
              label="Subtítulo del caso"
              value={subtitle}
              setValue={setSubtitle}
              isValid={isSubtitleValid}
              onBlur={() => determineIfSubtitleIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <TextArea
              placeholder="Descripción "
              label="Explicación del logro realizado"
              value={paragraph}
              setValue={setParagraph}
              isValid={isParagraphValid}
              onBlur={() => determineIfParagraphIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <div className="mb-1">
              {
                updatedResponsiveImages.map((responsiveImage, index) => (
                  <div key={`responsiveImage_${index + 1}`}>
                    <Row>
                      <Col xl={2}>
                        <Input
                          value={responsiveImage.order.toString()}
                          setValue={(newOrder: string) => setResponsiveImageOrder(index, newOrder)}
                          placeholder="Orden"
                          label={`Orden`}
                          type="text"
                        />
                      </Col>

                      <Col xl={8}>
                        <Input
                          value={responsiveImage.image}
                          setValue={(newImagePath: string) => setResponsiveImagePath(index, newImagePath)}
                          placeholder="URL de imagen"
                          label={`URL de imagen`}
                          type="text"
                        />
                      </Col>

                      <Col xl={2} className={`d-flex justify-content-center align-items-center`}>
                        <img
                          className={`${Styles.trashIcon} mr-2`}
                          src="/icons/trash.png"
                          alt="trash-icon"
                          onClick={() => removeResponsiveImage(index)}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xl={2}></Col>

                      <Col xl={4}>
                        <Input
                          value={responsiveImage.width}
                          setValue={(newWidth: string) => setResponsiveImageWidth(index, newWidth)}
                          placeholder="Ancho"
                          label={`Ancho`}
                          type="text"
                        />
                      </Col>

                      <Col xl={4}>
                        <Input
                          value={responsiveImage.height}
                          setValue={(newHeight: string) => setResponsiveImageHeight(index, newHeight)}
                          placeholder="Largo"
                          label={`Largo`}
                          type="text"
                        />
                      </Col>
                      <Col xl={2}></Col>
                    </Row>
                  </div>
                ))
              }

              <div
                className={`${Styles.addUserContainer} d-flex align-items-center mt-4`}
                onClick={() => addNewResponsiveImage()}
              >
                <img
                  className={`${Styles.addUserIcon} mr-2`}
                  src="/icons/plus.png"
                  alt="plus-icon"
                />
                <span className={Styles.addUserText}>Agregar</span>
              </div >
            </div>
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ruta"
              label="Ruta del video"
              value={videoUrl}
              setValue={setVideoUrl}
              isValid={isVideoUrlValid}
              onBlur={() => determineIfVideoUrlIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Orden"
              label="¿En qué orden se mostrará el trabajo?"
              value={order}
              setValue={setOrder}
              isValid={isOrderValid}
              onBlur={() => determineIfOrderIsValid()}
            />
          </Col>
        </Row>

        <Row className="mx-0 py-3">
          <Col className="d-flex justify-content-end align-items-center">
            <button
              className={`${Styles.yesButton} mr-3`}
              disabled={false}
              onClick={onSave}
            >
              Guardar
            </button>

            <button
              className={Styles.noButton}
              onClick={() => {
                clearForm();
                onHide();
              }}
            >
              Cancelar
            </button>
          </Col>
        </Row>
      </Modal.Body>

    </Modal>
  );
}
