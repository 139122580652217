import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { GridContentPropsType, ContentType } from './types';

import Content from './content';
import Styles from './styles.module.css';

export default function GridContent(props: GridContentPropsType) {
  const { contents } = props;

  return (
    <Row className={`bigContainer mx-4 px-3 my-4`}>
      {
        contents.map((content: ContentType, index: number) => {
          let contentClassName: string = Styles.contentContainer;

          const isIndexRestricted: boolean = (index === 2 || index === 4);

          if (isIndexRestricted) {
            contentClassName = '';
          }

          return (
            <Col
              className={`p-3 scrollbar-1 ${contentClassName} ${Styles.mainContentColumn}`}
              xl={4}
              key={content.title}
            >
              <Content
                title={content.title}
                description={content.description}
              />
            </Col>
          );
        })
      }
    </Row>
  )
}
