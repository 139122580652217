import { MenuPageItemType } from "./types"

import { CategoryType } from "../../services/categories-service"
import { AuthenticatedUserType } from "../../services/types"

const GeneralPagesByLanguage = {
  spanish: {
    myProfile: {
      title: "Mi perfil",
      sections: [],
      route: "/perfil",
      roles: ["ADMIN", "VISITOR", "EDITOR", "HR", "AUTHOR"],
      isRestrictedToAdmin: false
    },
    users: {
      title: "Usuarios",
      sections: [],
      route: "/usuarios",
      roles: ["ADMIN"],
      isRestrictedToAdmin: true
    },
    editPrivateContent: {
      title: "Edición de contenido privado",
      sections: [],
      route: "/editar",
      roles: ["ADMIN", "EDITOR"],
      isRestrictedToAdmin: true
    },
    editPublicContent: {
      title: "Edición de contenido público",
      sections: [],
      route: "/editar-contenido-publico",
      roles: ["ADMIN", "EDITOR"],
      isRestrictedToAdmin: true
    },
    blogs: {
      title: "Blogs",
      sections: [],
      route: "/blogs",
      roles: ["ADMIN", "AUTHOR"],
      isRestrictedToAdmin: true
    },
    signOut: {
      title: "Cerrar sesión",
      sections: [],
      route: "/cerrar-sesion",
      roles: ["ADMIN", "VISITOR", "EDITOR", "HR", "AUTHOR"],
      isRestrictedToAdmin: false
    }
  },
  english: {
    myProfile: {
      title: "My profile",
      sections: [],
      route: "/profile",
      roles: ["ADMIN", "VISITOR", "EDITOR", "HR", "AUTHOR"],
      isRestrictedToAdmin: false
    },
    users: {
      title: "Usuarios",
      sections: [],
      route: "/usuarios",
      roles: ["ADMIN"],
      isRestrictedToAdmin: true
    },
    editPrivateContent: {
      title: "Edición de contenido privado",
      sections: [],
      route: "/editar",
      roles: ["ADMIN", "EDITOR"],
      isRestrictedToAdmin: true
    },
    editPublicContent: {
      title: "Edición de contenido público",
      sections: [],
      route: "/editar-contenido-publico",
      roles: ["ADMIN", "EDITOR"],
      isRestrictedToAdmin: true
    },
    blogs: {
      title: "Blogs",
      sections: [],
      route: "/blogs",
      roles: ["ADMIN", "AUTHOR"],
      isRestrictedToAdmin: true
    },
    signOut: {
      title: "Sign out",
      sections: [],
      route: "/sign-out",
      roles: ["ADMIN", "VISITOR", "EDITOR", "HR", "AUTHOR"],
      isRestrictedToAdmin: false
    }
  }
}

export const constructPages = (
  categories: Array<CategoryType>,
  language: string,
  authenticatedUser: AuthenticatedUserType
): Array<MenuPageItemType> => {
  const pages: Array<MenuPageItemType> = categories.map(
    (category: CategoryType): MenuPageItemType => {
      return {
        title: category.title,
        sections: [],
        route: `/categoria/${category.id}`,
        isRestrictedToAdmin: false
      }
    }
  )

  if (
    GeneralPagesByLanguage[language].myProfile.roles.includes(
      authenticatedUser.role
    )
  )
    pages.push(GeneralPagesByLanguage[language].myProfile)

  if (
    GeneralPagesByLanguage[language].users.roles.includes(
      authenticatedUser.role
    )
  )
    pages.push(GeneralPagesByLanguage[language].users)

  if (
    GeneralPagesByLanguage[language].editPrivateContent.roles.includes(
      authenticatedUser.role
    )
  )
    pages.push(GeneralPagesByLanguage[language].editPrivateContent)

  if (
    GeneralPagesByLanguage[language].editPublicContent.roles.includes(
      authenticatedUser.role
    )
  )
    pages.push(GeneralPagesByLanguage[language].editPublicContent)

  if (
    GeneralPagesByLanguage[language].blogs.roles.includes(
      authenticatedUser.role
    )
  )
    pages.push(GeneralPagesByLanguage[language].blogs)

  pages.push(GeneralPagesByLanguage[language].signOut)

  return [...pages]
}
