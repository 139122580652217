import React, { useCallback, useEffect, useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { FourthSectionPropsType } from "./types"

import Input from "../../../../components/input"
import HtmlEditor from "../../../../components/html-editor"
import Select from "../../../../components/select"

import Styles from "./styles.module.css"

const THEMES: Array<string> = ["Oscuro", "Claro"]
const ACTUA_THEMES: Array<string> = ["dark", "light"]

export default function FourthSection(props: FourthSectionPropsType) {
  const { id, typeName, language, updateFourthSection } = props

  const [title, setSectionTitle] = useState<string>(props.title)
  const [paragraph, setSectionParagraph] = useState<string>(props.paragraph)
  const [theme, setSectionTheme] = useState<number>(
    ACTUA_THEMES.findIndex((item: string) => item === props.theme)
  )

  const updateSection = useCallback((): void => {
    updateFourthSection({
      title,
      paragraph,
      theme: ACTUA_THEMES[theme],
      id,
      typeName,
      language
    })
  }, [id, typeName, language, title, paragraph, theme])

  useEffect(() => {
    updateSection()
  }, [title, paragraph, theme])

  return (
    <div className={Styles.worksTypeContainer}>
      <Row className="mx-0 w-100 pb-4">
        <Col className="px-0 pb-4 d-flex justify-content-center align-items-center w-100 py-2">
          <span className={`${Styles.title} pl-4`}>Cuarta sección</span>
        </Col>
      </Row>

      <Row className="mx-0 w-100 p-4">
        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <Input
              value={title}
              setValue={(newTitle: string) => setSectionTitle(newTitle)}
              placeholder="Título"
              label={`Título de la sección`}
            />
          </div>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <HtmlEditor
              value={paragraph}
              setValue={(newParagraph: string) =>
                setSectionParagraph(newParagraph)
              }
              placeholder="Párrafo"
              label={`Párrafo donde se describe el proósito de la Compañía`}
            />
          </div>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <Select
              optionSelected={theme}
              selectOption={(newTheme: number) => setSectionTheme(newTheme)}
              optionsLabels={THEMES}
              label={`Plantilla`}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}
