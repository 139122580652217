import React, {useEffect, useState} from "react";
import { ResponsiveImageType } from "../../../../services/types";

interface UseResponsiveImagesLogicPropsType {
  responsiveImages: Array<ResponsiveImageType>,
}

export default function useResponsiveImagesEditor(props: UseResponsiveImagesLogicPropsType) {
  const [responsiveImages, setResponsiveImages] = useState<ResponsiveImageType[]>(props.responsiveImages as ResponsiveImageType[]);
  const [timesResponsiveImagesAreUpdated, responsiveImagesUpdated] = useState<number>(0);

  useEffect(() => {
    setResponsiveImages(props.responsiveImages);
  }, [props.responsiveImages.length]);

  const setResponsiveImageWidth = (index, width) => {
    const newResponsiveImages: ResponsiveImageType[] = [];

    responsiveImages.forEach((item, currentIndex) => {
      if (index === currentIndex) {
        newResponsiveImages.push({
          ...item,
          width: width || '',
        });
      } else {
        newResponsiveImages.push({ ...item });
      }
    });

    setResponsiveImages(newResponsiveImages);
    responsiveImagesUpdated(timesResponsiveImagesAreUpdated + 1);
  };

  const setResponsiveImageHeight = (index, height) => {
    const newResponsiveImages: ResponsiveImageType[] = [];

    responsiveImages.forEach((item, currentIndex) => {
      if (index === currentIndex) {
        newResponsiveImages.push({
          ...item,
          height: height || '',
        });
      } else {
        newResponsiveImages.push({ ...item });
      }
    });

    setResponsiveImages(newResponsiveImages);
    responsiveImagesUpdated(timesResponsiveImagesAreUpdated + 1);
  };

  const setResponsiveImagePath = (index, imagePath) => {
    const newResponsiveImages: ResponsiveImageType[] = [];

    responsiveImages.forEach((item, currentIndex) => {
      if (index === currentIndex) {
        newResponsiveImages.push({
          ...item,
          image: imagePath,
        });
      } else {
        newResponsiveImages.push({ ...item });
      }
    });

    setResponsiveImages(newResponsiveImages);
    responsiveImagesUpdated(timesResponsiveImagesAreUpdated + 1);
  };

  const setResponsiveImageOrder = (index, order) => {
    const newResponsiveImages: ResponsiveImageType[] = [];

    responsiveImages.forEach((item, currentIndex) => {
      if (index === currentIndex) {
        newResponsiveImages.push({
          ...item,
          order: Number(order),
        });
      } else {
        newResponsiveImages.push({ ...item });
      }
    });

    setResponsiveImages(newResponsiveImages);
    responsiveImagesUpdated(timesResponsiveImagesAreUpdated + 1);
  };

  const addNewResponsiveImage = () => {
    setResponsiveImages([
      ...responsiveImages,
      {
        width: '',
        height: '',
        image: '',
        order: responsiveImages.length + 1,
      },
    ]);
    responsiveImagesUpdated(timesResponsiveImagesAreUpdated + 1);
  };

  const removeResponsiveImage = (index: number) => {
    const firstPart = responsiveImages.slice(0, index);
    const secondPart = responsiveImages.slice(index + 1, responsiveImages.length);

    setResponsiveImages([...firstPart, ...secondPart]);
    responsiveImagesUpdated(timesResponsiveImagesAreUpdated + 1);
  };

  const addManyResponsiveImages = (newResponsiveImages: Array<ResponsiveImageType>) => {
    setResponsiveImages([
      ...newResponsiveImages,
    ]);
    responsiveImagesUpdated(timesResponsiveImagesAreUpdated + 1);
  };

  return {
    setResponsiveImageWidth,
    setResponsiveImageHeight,
    setResponsiveImagePath,
    setResponsiveImageOrder,
    addNewResponsiveImage,
    removeResponsiveImage,
    addManyResponsiveImages,
    updatedResponsiveImages: responsiveImages,
    timesResponsiveImagesAreUpdated,
  };
}