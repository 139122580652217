// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__388Nj {\n  width: 100%;\n  height: 100%;\n  padding: 2.5em;\n}\n\n@media screen and (max-width: 699px) {\n  .styles_container__388Nj {\n    height: calc(100vh - 150px);\n    padding: 0;\n  }\n}\n\n.styles_image__3D4BK {\n  height: 100%;\n  width: 100%;\n  object-fit: cover;\n}\n", "",{"version":3,"sources":["webpack://src/components/video-section/background/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE;IACE,2BAA2B;IAC3B,UAAU;EACZ;AACF;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  padding: 2.5em;\n}\n\n@media screen and (max-width: 699px) {\n  .container {\n    height: calc(100vh - 150px);\n    padding: 0;\n  }\n}\n\n.image {\n  height: 100%;\n  width: 100%;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__388Nj",
	"image": "styles_image__3D4BK"
};
export default ___CSS_LOADER_EXPORT___;
