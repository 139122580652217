// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_option__33hA5 {\n  font-size: 25px;\n  font-family: OgilvySans_Light;\n  background-color: white;\n  color: rgb(59, 58, 58);\n  border: 1px solid rgb(59, 58, 58);\n  transition: background-color 0.5s ease, color 0.5s ease;\n  cursor: pointer;\n}\n\n.styles_option__33hA5:hover, .styles_selected__KWL_R {\n  color: white;\n  background-color: rgb(59, 58, 58);\n  transition: background-color 0.5s ease, color 0.5s ease;\n}\n\n@media screen and (max-width: 600px) {\n  .styles_option__33hA5, .styles_option__33hA5:hover, .styles_selected__KWL_R{\n    font-size: 15px !important;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/pages/edit-category/components/form-select/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,6BAA6B;EAC7B,uBAAuB;EACvB,sBAAsB;EACtB,iCAAiC;EACjC,uDAAuD;EACvD,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,iCAAiC;EACjC,uDAAuD;AACzD;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".option {\n  font-size: 25px;\n  font-family: OgilvySans_Light;\n  background-color: white;\n  color: rgb(59, 58, 58);\n  border: 1px solid rgb(59, 58, 58);\n  transition: background-color 0.5s ease, color 0.5s ease;\n  cursor: pointer;\n}\n\n.option:hover, .selected {\n  color: white;\n  background-color: rgb(59, 58, 58);\n  transition: background-color 0.5s ease, color 0.5s ease;\n}\n\n@media screen and (max-width: 600px) {\n  .option, .option:hover, .selected{\n    font-size: 15px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option": "styles_option__33hA5",
	"selected": "styles_selected__KWL_R"
};
export default ___CSS_LOADER_EXPORT___;
