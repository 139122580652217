import React, { useEffect, useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Editor from "react-simple-code-editor"
import { highlight, languages } from "prismjs/components/prism-core"
import "prismjs/components/prism-clike"
import "prismjs/components/prism-javascript"
import "prismjs/themes/prism.css" //Example style, you can use another

import { InputPropsType } from "./types"

import Styles from "./styles.module.css"

export default function HtmlEditor(props: InputPropsType) {
  const { value, placeholder, setValue, label, isValid = true, onBlur } = props

  const [invalidFieldClassName, setInvalidFieldClassName] = useState<string>("")

  const onChange = (newText: string) => {
    setValue(newText)
  }

  useEffect(() => {
    setInvalidFieldClassName(() => (isValid ? "" : Styles.invalid))
  }, [isValid])

  return (
    <>
      <Row className="mx-0 w-100">
        <Col className="px-0 d-flex justify-content-start align-items-center w-100 py-2">
          <span className={`${Styles.label}`}>{label}</span>
        </Col>
      </Row>

      <Row className="mx-0 w-100">
        <Col className="px-0 d-flex justify-content-center align-items-center w-100 py-2">
          <Editor
            className={`${Styles.input} ${invalidFieldClassName} w-100`}
            value={value}
            onValueChange={onChange}
            highlight={(code) => highlight(code, languages.js)}
            padding={10}
            style={{
              fontFamily: "monospace",
              fontSize: 25,
              border: "1px solid"
            }}
          />
        </Col>
      </Row>
    </>
  )
}
