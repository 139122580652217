import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Styles from './styles.module.css';

export default function SecondPart() {
  return (
    <Row className="mx-0 px-4 h-100">
      <Col className="w-100 d-flex flex-column justify-content-center px-0">
      
        <Row className={`mx-0 w-100 pb-4`}>
          <Col
            className="d-flex flex-column"
            xl={6} lg={6} md={12} sm={12} xs={12}
          >
            <span className={`${Styles.city} pb-4`}>Tegucigalpa</span>
            <span className={Styles.address}>Edificio la concordia,</span>
            <span className={Styles.address}>Colonia Luis Ianda,</span>
            <span className={Styles.address}>Segunda planta 19838</span>
            <span className={`${Styles.phoneNumber} pt-4`}>+504 2236 6780</span>
            <span className={Styles.email}>tgu@ogilvy.com</span>
          </Col>

          <Col
            className="d-flex flex-column"
            xl={6} lg={6} md={12} sm={12} xs={12}
          >
            <span className={`${Styles.city} pb-4`}>San Pedro Sula</span>
            <span className={Styles.address}>Edificio la concordia,</span>
            <span className={Styles.address}>Colonia Luis Ianda,</span>
            <span className={Styles.address}>Segunda planta 19838</span>
            <span className={`${Styles.phoneNumber} pt-4`}>+504 2558 9156</span>
            <span className={Styles.email}>sps@ogilvy.com</span>
          </Col>
        </Row>

        <Row className={`mx-0 w-100`}>
          <Col className={`d-flex justify-content-center align-items-center`}>
            <img className={`${Styles.image}`} src="/images/symbols.png" alt="logo-25" />
          </Col>
        </Row>

      </Col>
    </Row>
  )
}
