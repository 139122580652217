import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector, useDispatch } from 'react-redux';

import { CategoryType } from '../../../../services/types';

import Input from '../../../../components/input';
import TextArea from '../../../../components/textarea';
import ColorInput from '../../../../components/color-input';
import { CategoriesActions } from '../../../category/slice';
import { RootState } from '../../../root-reducer';
import useSubcategoryFormLogic from './use-category-form-logic';

export default function CategoryForm() {
  const dispatch = useDispatch();

  const {
    categoryToEdit,
  } = useSelector((state: RootState) => state.categories);

  const updateCategoryInState = (category: CategoryType) => {
    dispatch(CategoriesActions.editCategory(category));
  };

  const {
    updateTitle,
    updateDescription,
    updateIcon,
    updateColor,
    updateVideoUrl,
    updateBackgroundVideoUrl,
  } = useSubcategoryFormLogic({
    information: categoryToEdit as CategoryType,
    updateCategoryInState,
  });

  return (
    <Row className={`w-100 mx-0 p-4`}>
      <Col className={`p-4`}>

        <div className="mb-4">
          <Input
            value={categoryToEdit!.title as string}
            setValue={updateTitle}
            placeholder="Título"
            label="Título de la categoría"
          />
        </div>

        <div className="mb-4">
          <TextArea
            value={categoryToEdit!.description as string}
            setValue={updateDescription}
            placeholder="Descripción"
            label="Descripción de la categoría"
          />
        </div>

        <div className="mb-4">
          <Row>
            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
              <Input
                value={categoryToEdit!.icon as string}
                setValue={updateIcon}
                placeholder="Url"
                label="Url del ícono principal"
              />
            </Col>

            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
              <ColorInput
                value={categoryToEdit!.color as string}
                setValue={updateColor}
                placeholder="Color"
                label="Color de fondo del cuadro en el menú"
              />
            </Col>
            
            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
              <Input
                value={categoryToEdit!.video as string}
                setValue={updateVideoUrl}
                placeholder="Url"
                label="Url del video"
              />
            </Col>
          </Row>
        </div>

        <div className="mb-4">
          <Row>
            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
              <Input
                value={categoryToEdit!.backgroundVideoImage as string}
                setValue={updateBackgroundVideoUrl}
                placeholder="Url"
                label="Url de la imagen de fondo del contenedor del video"
              />
            </Col>
          </Row>
        </div>

      </Col>
    </Row>
  );
}
