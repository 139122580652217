import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { BackgroundImagePropsType } from './types';

import Styles from './styles.module.css';

export default function BackgroundImage(props: BackgroundImagePropsType) {
  const { imageRoute, isAtTheTopOfThePage, text } = props;
  const [containerClass] = useState(isAtTheTopOfThePage ? 'smallContainer' : 'bigContainer');
  const [finalOpacity, setFinalOpacity] = useState('');

  useEffect(
    () => {
      const timeout = setTimeout(() => {
        setFinalOpacity(Styles.finalOpacity);
      }, 200);

      return () => clearTimeout(timeout);
    },
    [],
  );

  return (
    <>
      <Row className={`${Styles.textContainer} mx-0 w-100`}>
        <Col xl={6} lg={6} md={4} sm={0} xs={0} />
        <Col xl={6} lg={6} md={8} sm={12} xs={12} className="d-flex justify-content-end">
          <h2 className={Styles.text}>{text}</h2>
        </Col>
      </Row>
      
      <Row className={`${containerClass} ${Styles.initialOpacity} ${finalOpacity} mx-0`}>
        <Col className="px-0 d-flex justify-content-center align-items-center">
          <img className={Styles.image} src={imageRoute} />
        </Col>
      </Row>
    </>
  );
}
