import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { VideoSectionPropsType } from './types';

import Background from './background';
import Video from './video';

import Styles from './styles.module.css';

export default function VideoSection(props: VideoSectionPropsType) {
  const { videoUrl, backgroundImageRoute, isAtTheTopOfThePage } = props;

  const [containerClass] = useState(isAtTheTopOfThePage ? 'smallContainer' : 'bigContainer');

  return (
    <>
      <Row className={`${Styles.container} ${containerClass} mx-0`}>
        <Col className="d-flex align-items-center justify-content-center px-0 h-100">

          <Background imageRoute={backgroundImageRoute} />

          <Video videoUrl={videoUrl} />

        </Col>
      </Row>
    </>
  )
}
