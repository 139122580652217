import React, { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { ContentsTypePropsType } from './types';
import { ContentType } from '../../../../services/types';

import Input from '../../../../components/input';
import TextArea from '../../../../components/textarea';

import Styles from './styles.module.css';

export default function ContentsType(props: ContentsTypePropsType) {
  const [contents, setContents] = useState(props.contents);

  useDeepCompareEffect(() => {
    setContents(props.contents);
  }, [props.contents]);

  const setTitle = (newTitle: string, index: number) => {
    const newContents = [...contents];
    newContents[index] = { ...newContents[index], title: newTitle };
    props.updateContents([...newContents]);
  };

  const setDescription = (newDescription: string, index: number) => {
    const newContents = [...contents];
    newContents[index] = { ...newContents[index], description: newDescription };
    props.updateContents([...newContents]);
  };

  return (
    <div className={Styles.contentsTypeContainer}>
      <Row className="mx-0 w-100 pb-4">
        <Col className="px-0 pb-4 d-flex justify-content-center align-items-center w-100 py-2">
          <span className={`${Styles.title} pl-4`}>Lista de contenidos</span>
        </Col>
      </Row>

      <Row className="mx-0 w-100 py-4">
        {
          contents.map((content: ContentType, index: number) => {
            return (
              <Col
                key={`content_${index+1}_content.title`}
                 xl={6} lg={6} md={12} sm={12} xs={12}
                className="mb-4 pb-4"
              >
                <div className={`${Styles.indexBlock} d-flex align-items-center justify-content-center`}>
                  {index + 1}
                </div>

                <div className="mb-1">
                  <Input
                    value={content.title}
                    setValue={(newTitle: string) => setTitle(newTitle, index)}
                    placeholder="Título"
                    label={`Título de contenido`}
                  />
                </div>
                <div className={Styles.descriptionContainer}>
                  <TextArea
                    value={content.description}
                    setValue={(newDescription: string) => setDescription(newDescription, index)}
                    placeholder="Descripción"
                    label={`Descripción de contenido`}
                  />
                </div>
              </Col>
            );
          })
        }
      </Row>
    </div>
  );
}
