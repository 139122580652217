import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { PageType } from "../../services/types"

import { PagesService } from "../../services/pages-service"

import Helmet from "../../components/helmet"
import PagesList from "./components/pages-list"
import Footer from "../../components/footer"
import { PagesActions } from "../edit-page/slice"
import { RootState } from "../root-reducer"
import Spinner from "../../components/spinner"

import Styles from "./styles.module.css"

export default function EditMenuPagesListPage() {
  const { pages, loadingPages } = useSelector((state: RootState) => state.pages)
  const dispatch = useDispatch()
  const { push } = useHistory()

  const fetchPages = async () => {
    if (pages.length !== 0) {
      return
    }

    try {
      dispatch(PagesActions.startFetching())

      const service: PagesService = PagesService.getInstance()
      const retrievedPages: Array<PageType> = await service.getPages()

      dispatch(PagesActions.setPages(retrievedPages))
      dispatch(PagesActions.finishFetching())
    } catch (error) {
      if (error.message === "Unauthorized") {
        push("/")
      }
    }
  }

  useEffect(() => {
    fetchPages()
  }, [])

  const selectPage = (pageId: string) => {
    dispatch(PagesActions.selectPage(pageId))
  }

  if (loadingPages) {
    return <Spinner />
  }

  return (
    <div className={Styles.container}>
      <Helmet title="Ogilvy | Admin - Menu de Edición de Contenido Público" />

      <PagesList pages={pages} selectPage={selectPage} isAtTheTopOfThePage />

      <Footer />
    </div>
  )
}
