import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { ContentPropsType } from './index';

import FooterStyles from './footer.module.css';

export default function ContentForLandscape(props: ContentPropsType) {
  const {
    copyright, tguPhoneNumber, spsPhoneNumber,
    email, youtubeUrl, facebookUrl, twitterUrl,
  } = props;

  return (
    <Row>
      <Col xs={1} />

      <Col xs={2} sm={2} md={2} lg={2} xl={2} className="d-flex align-items-center">
        <span className={FooterStyles.copyright}>
          {copyright}
        </span>
      </Col>

      <Col xs={3} sm={3} md={3} lg={5} xl={5} className="d-flex align-items-center">

        <Row className="w-100">
          <Col>

            <Row>
              <Col className="d-flex justify-content-end">
                <span className={FooterStyles.contact}>
                  {tguPhoneNumber}
                </span>
              </Col>
            </Row>

            <Row>
              <Col className="d-flex justify-content-end">
                <span className={FooterStyles.contact}>
                  {spsPhoneNumber}
                </span>
              </Col>
            </Row>

          </Col>

          <Col className="d-flex align-items-center">
            <span className={FooterStyles.contact}>
              {email}
            </span>
          </Col>
        </Row>

      </Col>

      <Col
        xs={2} sm={2} md={2} lg={3} xl={3}
        className={`${FooterStyles.socialMedia} d-flex align-items-center justify-content-end`}
      >
        <a href={youtubeUrl} target="blank" className="mx-2">Youtube</a>
        <a className="mx-2">LinkedIn</a>
        <a href={facebookUrl} target="blank" className="mx-2">Facebook</a>
        <a href={twitterUrl} target="blank" className="mx-2">Twitter</a>
      </Col>

      <Col xs={1} />
    </Row>
  );
}
