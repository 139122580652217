import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Styles from './styles.module.css';

export default function FirstPart() {
  return (
    <Row className={`mx-0 w-100 h-100`}>
      <Col className={`d-flex justify-content-start align-items-center`}>
        <img className={`${Styles.image}`} src="/images/logo-25.png" alt="logo-25" />
      </Col>
    </Row>
  )
}
