// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_contentContainer__33KqA {\n  border-right: 3px rgb(232, 232, 232) dotted;\n}\n\n.styles_title__3ongN {\n  font-size: 18px;\n  font-family: OgilvySans_Bold;\n  color: #868686;\n}\n\n.styles_mainContentColumn__2VN5p {\n  height: 35%;\n  overflow-y: auto;\n}\n", "",{"version":3,"sources":["webpack://src/pages/category/components/grid-content/styles.module.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;AAC7C;;AAEA;EACE,eAAe;EACf,4BAA4B;EAC5B,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".contentContainer {\n  border-right: 3px rgb(232, 232, 232) dotted;\n}\n\n.title {\n  font-size: 18px;\n  font-family: OgilvySans_Bold;\n  color: #868686;\n}\n\n.mainContentColumn {\n  height: 35%;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentContainer": "styles_contentContainer__33KqA",
	"title": "styles_title__3ongN",
	"mainContentColumn": "styles_mainContentColumn__2VN5p"
};
export default ___CSS_LOADER_EXPORT___;
