import { useEffect, useRef, useState } from 'react';

interface useScrollClassesPropsType {
  containerId: string,
  callbackBelowViewport: () => void,
  callbackAboveViewport: () => void
}

export default function useScrollClasses(props: useScrollClassesPropsType) {
  const {
    containerId,
    callbackBelowViewport,
    callbackAboveViewport,
  } = props;

  const [belowViewport, setBelowViewport] = useState(true);
  const belowViewportRef = useRef(belowViewport);

  const [aboveViewport, setAboveViewport] = useState(false);
  const aboveViewportRef = useRef(aboveViewport);

  const onAboveViewport = () => {
    callbackAboveViewport();

    setAboveViewport(() => {
      aboveViewportRef.current = true;
      return true;
    });

    setBelowViewport(() => {
      belowViewportRef.current = false;
      return false;
    });
  };

  const onBelowViewport = () => {
    callbackBelowViewport();

    setAboveViewport(() => {
      aboveViewportRef.current = false;
      return false;
    });

    setBelowViewport(() => {
      belowViewportRef.current = true;
      return true;
    });
  };

  const onScroll = () => {
    const { innerHeight } = window;
    const container = document.getElementById(containerId)?.getBoundingClientRect();

    if (!container) {
      return;
    }

    const baseHeight = (innerHeight + 300);

    const aboveViewPortRefValue = aboveViewportRef?.current;
    
    if (container.bottom <= baseHeight && !aboveViewPortRefValue) {
      onAboveViewport();
    }

    const belowViewPortRefValue = belowViewportRef?.current;

    if (container.bottom > baseHeight && !belowViewPortRefValue) {
      onBelowViewport();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return {};
}
