import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { useDispatch } from "react-redux"

import { AddUserModalPropsType, RoleType } from "./types"

import {
  AddUserObjectType,
  AuthenticatedUserType,
  UserPublicInformationType
} from "../../../../services/types"

import Input from "../../../../components/input"
import Select from "../../../../components/select"
import SwitchControl from "../../../../components/switch-control"
import { UsersService } from "../../../../services/users-service"
import { UserActions } from "../../slice"
import { Roles } from "../../roles"

import Styles from "./styles.module.css"

const usersService = UsersService.getInstance()

export default function AddUserModal(props: AddUserModalPropsType) {
  const { show, onHide, callback } = props

  const [firstName, setFirstName] = useState<string>("")
  const [isFirstNameValid, setFirstNameValidity] = useState<boolean>(true)

  const [lastName, setLastName] = useState<string>("")
  const [isLastNameValid, setLastNameValidity] = useState<boolean>(true)

  const [jobTitleInSpanish, setJobTitleInSpanish] = useState<string>("")
  const [isJobTitleInSpanishValid, setJobTitleInSpanishValidity] =
    useState<boolean>(true)

  const [jobTitleInEnglish, setJobTitleInEnglish] = useState<string>("")
  const [isJobTitleInEnglishValid, setJobTitleInEnglishValidity] =
    useState<boolean>(true)

  const [email, setEmail] = useState<string>("")
  const [isEmailValid, setEmailValidity] = useState<boolean>(true)

  const [password, setPassword] = useState<string>("")
  const [isPasswordValid, setPasswordValidity] = useState<boolean>(true)

  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const [isConfirmPasswordValid, setConfirmPasswordValidity] =
    useState<boolean>(true)

  const [file, setFile] = useState<File | null>(null)
  const [isFileValid, setFileValidity] = useState<boolean>(true)

  const [imageUrl, setImageUrl] = useState<string>("")
  const [isImageUrlValid, setImageUrlValidity] = useState<boolean>(true)

  const [roleSelected, selectRole] = useState<number>(0)

  const [isPublic, setIsPublic] = useState<boolean>(false)

  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [isPhoneNumberValid, setPhoneNumberValidity] = useState<boolean>(true)

  const dispatch = useDispatch()

  const fileUploadHandler = (file: File) => {
    setFile(file)
  }

  const createUser = async () => {
    dispatch(UserActions.startFetchingUsers())

    const foundRole: string = Roles[roleSelected].name

    const newPublicInformation: Array<UserPublicInformationType> = [
      {
        language: "english",
        description: "",
        jobTitle: jobTitleInEnglish
      },
      {
        language: "spanish",
        description: "",
        jobTitle: jobTitleInSpanish
      }
    ]

    const userData: AddUserObjectType = {
      firstName,
      lastName,
      publicInformation: newPublicInformation,
      email,
      password,
      role: foundRole,
      pictureUrl: imageUrl,
      isPublic,
      phoneNumber,
      branch: "tgu"
    }

    try {
      const createdUser: AuthenticatedUserType = (await usersService.createUser(
        userData
      )) as AuthenticatedUserType

      // const uploadResponse = await usersService.uploadProfilePicture(
      //   createdUser.id,
      //   file as any
      // )

      // await usersService.updateUser(
      //   createdUser.id,
      //   firstName,
      //   lastName,
      //   newPublicInformation,
      //   foundRole,
      //   imageUrl
      // )

      if (!createdUser) return

      callback()

      dispatch(UserActions.finishFetchingUsers())
    } catch (error) {
      console.error("ERROR", error)
    }
  }

  const clearForm = () => {
    setFirstName("")
    setLastName("")
    setJobTitleInSpanish("")
    setJobTitleInEnglish("")
    setEmail("")
    setPassword("")
    setConfirmPassword("")
    selectRole(0)
    setFile(null)
    setImageUrl("")
    setIsPublic(false)
    setPhoneNumber("")

    setFirstNameValidity(true)
    setLastNameValidity(true)
    setJobTitleInSpanishValidity(true)
    setJobTitleInEnglishValidity(true)
    setEmailValidity(true)
    setPasswordValidity(true)
    setConfirmPasswordValidity(true)
    setFileValidity(true)
    setImageUrlValidity(true)
    setPhoneNumberValidity(true)
  }

  const determineIfFirstNameIsValid = () => {
    setFirstNameValidity(firstName.trim() !== "")
  }

  const determineIfLastNameIsValid = () => {
    setLastNameValidity(lastName.trim() !== "")
  }

  const determineIfJobTitleInSpanishIsValid = () => {
    setJobTitleInSpanishValidity(jobTitleInSpanish.trim() !== "")
  }

  const determineIfJobTitleInEnglishIsValid = () => {
    setJobTitleInEnglishValidity(jobTitleInEnglish.trim() !== "")
  }

  const determineIfEmailIsValid = () => {
    setEmailValidity(
      email.trim() !== "" &&
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
    )
  }

  const determineIfPasswordIsValid = () => {
    setPasswordValidity(
      password.trim() !== "" &&
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/.test(password)
    )
  }

  const determineIfConfirmPasswordIsValid = () => {
    setConfirmPasswordValidity(
      confirmPassword.trim() !== "" &&
        password.trim() === confirmPassword.trim() &&
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/.test(confirmPassword)
    )
  }

  const determineIfFileIsValid = () => {
    let isSizeValid = false

    if (file) {
      isSizeValid = file.size * 0.000001 <= 2
    }

    setFileValidity(file !== null && isSizeValid)
  }

  const determineIfImageUrlIsValid = () => {
    setImageUrlValidity(imageUrl.trim() !== "")
  }

  const determineIfPhoneNumberIsValid = () => {
    setPhoneNumberValidity(phoneNumber.trim() !== "")
  }

  const isFormValid = () => {
    return (
      isFirstNameValid &&
      isLastNameValid &&
      isJobTitleInSpanishValid &&
      isJobTitleInEnglishValid &&
      isEmailValid &&
      isPasswordValid &&
      isConfirmPasswordValid &&
      isImageUrlValid &&
      isPhoneNumberValid
    )
  }

  const onSave = (e) => {
    e.preventDefault()
    determineIfFirstNameIsValid()
    determineIfLastNameIsValid()
    determineIfJobTitleInSpanishIsValid()
    determineIfJobTitleInEnglishIsValid()
    determineIfEmailIsValid()
    determineIfPasswordIsValid()
    determineIfConfirmPasswordIsValid()
    determineIfImageUrlIsValid()
    determineIfPhoneNumberIsValid()

    if (!isFormValid()) return

    createUser()
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="modal-question"
      centered
      show={show}
      onHide={() => {
        clearForm()
        onHide()
      }}
    >
      <Modal.Body>
        <Row className="mx-0 py-3">
          <Col className="d-flex justify-content-start align-items-center">
            <span className={Styles.modalTitle}>Agregar nuevo usuario</span>
          </Col>

          <Col className="d-flex justify-content-end align-items-center">
            <img
              onClick={() => {
                clearForm()
                onHide()
              }}
              className={Styles.closeIcon}
              src="/icons/cancel-gray.png"
              alt="close-icon"
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa el primer nombre"
              label="Primer Nombre"
              value={firstName}
              setValue={setFirstName}
              isValid={isFirstNameValid}
              onBlur={() => determineIfFirstNameIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa el primer apellido"
              label="Primer Apellido"
              value={lastName}
              setValue={setLastName}
              isValid={isLastNameValid}
              onBlur={() => determineIfLastNameIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa el número telefónico"
              label="Número Telefónico"
              value={phoneNumber}
              setValue={setPhoneNumber}
              isValid={isPhoneNumberValid}
              onBlur={() => determineIfPhoneNumberIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mb-4 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa el puesto de trabajo"
              label="Puesto de Trabajo en Español"
              value={jobTitleInSpanish}
              setValue={setJobTitleInSpanish}
              isValid={isJobTitleInSpanishValid}
              onBlur={() => determineIfJobTitleInSpanishIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mb-4 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa el puesto de trabajo"
              label="Puesto de Trabajo en Inglés"
              value={jobTitleInEnglish}
              setValue={setJobTitleInEnglish}
              isValid={isJobTitleInEnglishValid}
              onBlur={() => determineIfJobTitleInEnglishIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa el correo electrónico"
              label="Correo electrónico"
              value={email}
              setValue={setEmail}
              isValid={isEmailValid}
              onBlur={() => determineIfEmailIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa la contraseña"
              label="Contraseña (8 caracteres max, al menos un número, una letra minúscula y una letra mayúscula)"
              value={password}
              setValue={setPassword}
              type="password"
              isValid={isPasswordValid}
              onBlur={() => determineIfPasswordIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa nuevamente la contraseña"
              label="Confirmar contraseña"
              value={confirmPassword}
              setValue={setConfirmPassword}
              type="password"
              isValid={isConfirmPasswordValid}
              onBlur={() => determineIfConfirmPasswordIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mb-4 mx-0">
          <Col className="px-3">
            <Select
              label="Rol"
              optionSelected={roleSelected}
              selectOption={selectRole}
              optionsLabels={Roles.map((item: RoleType) => item.displayName)}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa el url de la imagen de perfil"
              label="Url de imagen de perfil"
              value={imageUrl}
              setValue={setImageUrl}
              isValid={isImageUrlValid}
              onBlur={() => determineIfImageUrlIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <SwitchControl
              id={`switch-control-public`}
              value={isPublic}
              setValue={(value: boolean) => setIsPublic(value)}
              label="¿Se mostrará este usuario en el sitio público?"
              isValid
            />
          </Col>
        </Row>

        <Row className="mx-0 py-3">
          <Col className="d-flex justify-content-end align-items-center">
            <button
              className={`${Styles.yesButton} mr-3`}
              disabled={false}
              onClick={onSave}
            >
              Guardar
            </button>

            <button
              className={Styles.noButton}
              onClick={() => {
                clearForm()
                onHide()
              }}
            >
              Cancelar
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
