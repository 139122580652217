// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_title__1xZS7 {\n  font-size: 35px;\n  font-family: OgilvySerif_LightItalic;\n  color: rgb(174, 173, 173);\n}\n\n.styles_contentsTypeContainer__8p3qq {\n  padding-top: 4em;\n}\n\n.styles_indexBlock__2fVXg {\n  width: 40px;\n  height: 40px;\n  background-color: black;\n  color: white;\n  font-family: OgilvySerif_LightItalic;\n}\n", "",{"version":3,"sources":["webpack://src/pages/edit-category/components/contents-type/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,oCAAoC;EACpC,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,YAAY;EACZ,oCAAoC;AACtC","sourcesContent":[".title {\n  font-size: 35px;\n  font-family: OgilvySerif_LightItalic;\n  color: rgb(174, 173, 173);\n}\n\n.contentsTypeContainer {\n  padding-top: 4em;\n}\n\n.indexBlock {\n  width: 40px;\n  height: 40px;\n  background-color: black;\n  color: white;\n  font-family: OgilvySerif_LightItalic;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "styles_title__1xZS7",
	"contentsTypeContainer": "styles_contentsTypeContainer__8p3qq",
	"indexBlock": "styles_indexBlock__2fVXg"
};
export default ___CSS_LOADER_EXPORT___;
