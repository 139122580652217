import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BlogsStateType } from './types';
import { BlogType } from '../../services/types';

const initialState: BlogsStateType = {
  fetchingBlogs: false,
  blogs: [],
  totalNumberOfBlogs: 0,
  pageNumber: 1,
  pageSize: 10,
  blogToCreate: undefined,
  blogToEdit: undefined
}

export const blogsSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    startFetchingBlogs: (state) => ({ ...state, fetchingBlogs: true }),

    finishFetchingBlogs: (state) => ({ ...state, fetchingBlogs: false }),

    setBlogs: (state, action: PayloadAction<Array<BlogType>>) => ({
      ...state, blogs: action.payload,
    }),

    setTotalNumberOfBlogs: (state, action: PayloadAction<number>) => ({
      ...state, totalNumberOfBlogs: action.payload,
    }),

    setPageNumber: (state, action: PayloadAction<number>) => ({
      ...state, pageNumber: action.payload,
    }),

    setBlogToCreate: (state, action: PayloadAction<BlogType | undefined>) => ({
      ...state, blogToCreate: action.payload,
    }),

    setBlogToEdit: (state, action: PayloadAction<BlogType | undefined>) => ({
      ...state, blogToEdit: action.payload,
    }),
  },
});

export const BlogsActions = blogsSlice.actions;
