import React from "react"
import { useSelector, useDispatch } from "react-redux"

import {
  CategoryType,
  SubcategoryType,
  ContentType,
  WorkType,
  PeopleType
} from "../../services/types"

import { CategoriesService } from "../../services/categories-service"
import { SubcategoriesService } from "../../services/subcategories-service"

import { CategoriesActions } from "../category/slice"
import { RootState } from "../root-reducer"

export default function useEditCategoryLogic() {
  const dispatch = useDispatch()

  const {
    categoryToEdit,
    subcategoriesToEdit,
    subcategorySelected,
    whichSubcategoriesHaveBeenEdited
  } = useSelector((state: RootState) => state.categories)

  const updateCategory = async (callback: () => void) => {
    const service: CategoriesService = CategoriesService.getInstance()

    dispatch(CategoriesActions.startEditingCategory())

    const updatedCategory = await service.updateCategory(
      categoryToEdit as CategoryType
    )

    dispatch(CategoriesActions.setCategoryToEdit(updatedCategory))
    dispatch(CategoriesActions.finishEditingCategory())

    callback()
  }

  const updateAllUpdatedSubcategoriesInState = async () => {
    const service: SubcategoriesService = SubcategoriesService.getInstance()
    const newSubcategoriesFlags: Array<boolean> = []

    dispatch(CategoriesActions.startEditingSubcategory())

    const promises: Array<Promise<SubcategoryType>> = subcategoriesToEdit.map(
      (
        subcategoryToUpdate: SubcategoryType,
        index: number
      ): Promise<SubcategoryType> => {
        newSubcategoriesFlags.push(false)

        return whichSubcategoriesHaveBeenEdited[index]
          ? service.updateSubcategory(subcategoryToUpdate)
          : Promise.resolve(subcategoryToUpdate)
      }
    )

    const updatedSubcategories = await Promise.all(promises)

    dispatch(CategoriesActions.setSubcategories(updatedSubcategories))
    dispatch(
      CategoriesActions.setWhichSubcategoriesHaveSelected(newSubcategoriesFlags)
    )
    dispatch(CategoriesActions.finishEditingSubcategory())
  }

  const isCategoryToEditValid = (): boolean => {
    const isTitleValid: boolean | string =
      categoryToEdit!.title && categoryToEdit!.title.trim() !== ""
    const isDescriptionValid: boolean | string =
      categoryToEdit!.description && categoryToEdit!.description.trim() !== ""
    const isIconValid: boolean | string =
      categoryToEdit!.icon && categoryToEdit!.icon.trim() !== ""
    const isColorValid: boolean | string =
      categoryToEdit!.color && categoryToEdit!.color.trim() !== ""
    const isVideoValid: boolean | string | undefined =
      categoryToEdit!.video && categoryToEdit!.video.trim() !== ""
    const isBackgroundVideoImageValid: boolean | string | undefined =
      categoryToEdit!.backgroundVideoImage &&
      categoryToEdit!.backgroundVideoImage.trim() !== ""

    return (isTitleValid &&
      isDescriptionValid &&
      isIconValid &&
      isColorValid &&
      isVideoValid &&
      isBackgroundVideoImageValid) as boolean
  }

  const areSubcategoriesToEditValid = (): boolean => {
    if (subcategoriesToEdit.length === 0) return true

    const invalidSubcategory: SubcategoryType | undefined =
      subcategoriesToEdit!.find((subcategory: SubcategoryType) => {
        return !isSubcategoryToEditValid(subcategory)
      })

    return invalidSubcategory === undefined
  }

  const isSubcategoryToEditValid = (subcategory: SubcategoryType): boolean => {
    const isTitleValid: boolean | string =
      subcategory!.title && subcategory!.title.trim() !== ""

    return (
      ((isTitleValid && isLabelValid(subcategory)) as boolean) &&
      areContentsValid(subcategory) &&
      areWorksValid(subcategory) &&
      arePeopleValid(subcategory)
    )
  }

  const isLabelValid = (subcategory: SubcategoryType): boolean => {
    const { label } = subcategory

    const isBackgroundColorValid: boolean | string =
      label.backgroundColor && label.backgroundColor.trim() !== ""
    const isBorderColorValid: boolean | string =
      label.borderColor && label.borderColor.trim() !== ""
    const isFontColorValid: boolean | string =
      label.fontColor && label.fontColor.trim() !== ""

    return (isBackgroundColorValid &&
      isBorderColorValid &&
      isFontColorValid) as boolean
  }

  const areContentsValid = (subcategory: SubcategoryType): boolean => {
    const { contents, type } = subcategory
    if (type !== "content-list") return true

    let isThereAnyContentInvalid: boolean = false

    contents.forEach((content: ContentType): void => {
      const isTitleValid: boolean | string =
        content.title && content.title.trim() !== ""
      const isDescriptionValid: boolean | string =
        content.description && content.description.trim() !== ""

      const isContentValid: boolean = (isTitleValid &&
        isDescriptionValid) as boolean
      if (!isContentValid) isThereAnyContentInvalid = true
    })

    return !isThereAnyContentInvalid
  }

  const areWorksValid = (subcategory: SubcategoryType): boolean => {
    const { works, type } = subcategory
    if (type !== "works") return true

    let isThereAnyWorkInvalid: boolean = false

    works.forEach((work: WorkType): void => {
      const isTitleValid: boolean | string =
        work.title && work.title.trim() !== ""
      const isSubtitleValid: boolean | string =
        work.subtitle && work.subtitle.trim() !== ""
      const isMainImageRouteValid: boolean | string =
        work.mainResponsiveImages.length > 0
      const isParagraphValid: boolean | string =
        work.paragraph && work.paragraph.trim() !== ""
      const isVideoUrlValid: boolean | string =
        work.videoUrl && work.videoUrl.trim() !== ""

      const isWorkValid: boolean = (isTitleValid &&
        isSubtitleValid &&
        isMainImageRouteValid &&
        isParagraphValid &&
        isVideoUrlValid) as boolean

      if (!isWorkValid) isThereAnyWorkInvalid = true
    })

    return !isThereAnyWorkInvalid
  }

  const arePeopleValid = (subcategory: SubcategoryType): boolean => {
    const { people, type } = subcategory
    if (type !== "people") return true

    let isThereAnyPersonInvalid: boolean = false

    people.forEach((person: PeopleType): void => {
      const isImageRouteValid: boolean | string =
        person.imageRoute && person.imageRoute.trim() !== ""
      const isNameValid: boolean | string =
        person.name && person.name.trim() !== ""
      const isEmailValid: boolean | string =
        person.email && person.email.trim() !== ""
      const isPhoneNumberValid: boolean | string =
        person.phoneNumber && person.phoneNumber.trim() !== ""

      const isPersonValid: boolean = (isImageRouteValid &&
        isNameValid &&
        isEmailValid &&
        isPhoneNumberValid) as boolean

      if (!isPersonValid) isThereAnyPersonInvalid = true
    })

    return !isThereAnyPersonInvalid
  }

  return {
    updateCategory,
    updateAllUpdatedSubcategoriesInState,
    isCategoryToEditValid,
    areSubcategoriesToEditValid
  }
}
