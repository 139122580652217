import { combineReducers } from '@reduxjs/toolkit';

import { loginSlice } from './login/slice';
import { categoriesSlice } from './category/slice';
import { usersSlice } from './users/slice';
import { pagesSlice } from './edit-page/slice';
import { blogsSlice } from './blogs/slice';

export const rootReducer = combineReducers({
  login: loginSlice.reducer,
  categories: categoriesSlice.reducer,
  users: usersSlice.reducer,
  pages: pagesSlice.reducer,
  blogs: blogsSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>
