import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Helmet from '../../components/helmet';
import { LoginForm } from './components';
import Footer from '../../components/footer';
import SubFooter from '../../components/sub-footer';

import Styles from './styles.module.css';

export default function LoginPage() {
  const { push } = useHistory();

  useEffect(
    () => {
      const user: string | null = localStorage.getItem('userId');
      const token: string | null = localStorage.getItem('token');

      if (user && token) {
        push('/menu');
      }
    },
    [],
  );

  return (
    <div className={Styles.container}>
      <Helmet title="Ogilvy - Inicio de Sesión" />

      <LoginForm
        backgroundImageRoute="/images/login-background.png"
        isAtTheTopOfThePage
      />

      <SubFooter />

      <Footer />
    </div>
  )
}
