import React from 'react';

import Helmet from '../../components/helmet';
import Footer from '../../components/footer';
import BackgroundImage from '../../components/background-image';
import SubFooter from '../../components/sub-footer';

import Styles from './styles.module.css';

export default function NotFoundPage() {
  return (
    <div className={Styles.container}>
      <Helmet title="Ogilvy - Menú Principal" />

      <BackgroundImage
        imageRoute="/images/not-found.png"
        isAtTheTopOfThePage={false}
        text={'No se encuentra la página que solicita'}
      />

      <SubFooter />

      <Footer />
    </div>
  )
}
