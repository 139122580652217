/* eslint-disable jsx-a11y/alt-text */
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { HeaderIconsPropsType } from '../types';

import Styles from './styles.module.css';

export default function HeaderIcons(props: HeaderIconsPropsType) {
  const { close } = props;

  return (
    <Row className={`${Styles.headerContainer} mx-4`}>
      <Col xs={6} className="d-flex justify-content-start align-items-center py-2">
        <img
          className={`${Styles.icon}`}
          src="/icons/arrow-left-gray.png"
        />
        <img
          className={`${Styles.icon}`}
          src="/icons/arrow-right-gray.png"
        />
      </Col>

      <Col xs={6} className="d-flex justify-content-end align-items-center py-2">
        <img
          className={`${Styles.icon}`}
          src="/icons/cancel-gray.png"
          onClick={close}
        />
      </Col>
    </Row>
  );
}
