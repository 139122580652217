import React from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { LoginActions } from "../../../login/slice"

import { CategoryType } from "../../../../services/types"
import { WorksTypePropsType } from "./types"

import Styles from "./styles.module.css"
import Input from "../../../../components/input";
import useEditCategoryLogic from "../../../edit-category/use-edit-category-logic";
import {CategoriesActions} from "../../../category/slice";

export default function WorksSection(props: WorksTypePropsType) {
  const { categories, language } = props
  const dispatch = useDispatch()
  const { push } = useHistory()

  const {
    updateCategory,
  } = useEditCategoryLogic()

  const goToCategoryPage = (category: CategoryType) => {
    sessionStorage.setItem("language", category.language)

    dispatch(
      category.language === "spanish"
        ? LoginActions.changeLanguageToSpanish()
        : LoginActions.changeLanguageToEnglish()
    )
    push(`/editar/${category.id}`, { showGalleryOfWorks: true })
  }

  const setCategoryOrder = (newOrder: string, categoryId: string) => {
    dispatch(CategoriesActions.selectCategory(categoryId));
  };

  return (
    <div className={Styles.worksTypeContainer}>
      <Row className="mx-0 w-100 pb-4">
        <Col className="px-0 pb-4 d-flex justify-content-center align-items-center w-100 py-2">
          <span className={`${Styles.title} pl-4`}>
            Lista de Casos de Exitos
          </span>
        </Col>
      </Row>

      <Row className="mx-0 w-100 pb-4">
        <Col className="px-4 pb-4 d-flex justify-content-start align-items-center w-100 py-2">
          <p className={`${Styles.title} pl-4`}>
            Los casos de éxitos deben ser editados en las páginas de categorías
            correspondientes. A continuación, se presenta la lista de categorías
            de contenido exclusivo para clientes potenciales donde encontraremos
            la opción para editar los casos de exitos:
          </p>
        </Col>
      </Row>

      <Row className="mx-0 w-100 pb-4">
        <Col className="px-4 pb-4 d-flex justify-content-start align-items-center w-100 py-2">
          <p className={`${Styles.title} pl-4`}>
            Lenguaje: <strong>{language.toUpperCase()}</strong>
          </p>
        </Col>
      </Row>

      <Row className="mx-0 w-100 pb-4 px-4">
        {categories.map((category: CategoryType): any => (
          <Col
            className={`${Styles.category} px-0 d-flex flex-column w-100 pb-4`}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
          >
            <Row className="mx-0 w-100 pb-4">
              <Col className="px-0 d-flex justify-content-center w-100">
                <img
                  src={category.backgroundVideoImage}
                  alt="category-image"
                  onClick={() => goToCategoryPage(category)}
                />
              </Col>
            </Row>
            <Row className="mx-0 w-100 pb-4">
              <Col xs={10} className="px-0 d-flex justify-content-center w-100">
                <h2>{category.title}</h2>
              </Col>

              <Col xs={2}>
                <Input
                  value={category.order}
                  setValue={(newOrder: string) => true}
                  placeholder="Orden"
                  label={`Orden`}
                  type="text"
                />
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </div>
  )
}
