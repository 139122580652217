// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__m2lve {\n  height: 100%;\n  width: 250px;\n  display: inline-block;\n}\n\n.styles_container__m2lve img {\n  width: 240px;\n  height: 240px;\n  border-radius: 50%;\n}\n\n.styles_container__m2lve h2 {\n  font-size: 30px;\n  font-family: OgilvySerif_LightItalic;\n  margin: 0;\n}\n\n.styles_container__m2lve h3 {\n  font-size: 17px;\n  font-family: OgilvySerif_LightItalic;\n}\n\n.styles_container__m2lve span {\n  font-size: 10px;\n  font-family: OgilvySerif_LightItalic;\n}\n", "",{"version":3,"sources":["webpack://src/pages/category/components/gallery-people/person-item/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,oCAAoC;EACpC,SAAS;AACX;;AAEA;EACE,eAAe;EACf,oCAAoC;AACtC;;AAEA;EACE,eAAe;EACf,oCAAoC;AACtC","sourcesContent":[".container {\n  height: 100%;\n  width: 250px;\n  display: inline-block;\n}\n\n.container img {\n  width: 240px;\n  height: 240px;\n  border-radius: 50%;\n}\n\n.container h2 {\n  font-size: 30px;\n  font-family: OgilvySerif_LightItalic;\n  margin: 0;\n}\n\n.container h3 {\n  font-size: 17px;\n  font-family: OgilvySerif_LightItalic;\n}\n\n.container span {\n  font-size: 10px;\n  font-family: OgilvySerif_LightItalic;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__m2lve"
};
export default ___CSS_LOADER_EXPORT___;
