/* tslint:disable */
import { useState } from 'react';
import Player from 'react-player';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { GelleryItemType } from '../types';

import useEventListener from '../../../../../components/reactivity-to-general-behaviors/event-listener';
import Content from './content';
import HeaderIcons from './header-icons';
import Styles from './styles.module.css';

const defaultItem: GelleryItemType = {
  id: '',
  mainResponsiveImages: [],
  title: '',
  subtitle: '',
  paragraph: '',
  videoUrl: '',
  images: [],
  displayVideo: true,
  displayImages: false,
};

export default function useGalleryItemDetails() {
  const [itemSelected, selectNewItem] = useState<GelleryItemType>({...defaultItem});

  const [columnSizeForContent, setColumnSizeForContent] = useState<number>(0);
  const [columnSizeForVideo, setColumnSizeForVideo] = useState<number>(0);

  const selectItem = (item: GelleryItemType) => {
    selectNewItem((prevItem: GelleryItemType) =>
      prevItem.id !== item.id ? item : {...defaultItem},
    );
  };

  const determineSizeOfColumns = () => {
    if (window.innerWidth >= 1300) {
      setColumnSizeForContent(4);
      setColumnSizeForVideo(8);
    }
    if (window.innerWidth >= 1100 && window.innerWidth < 1300) {
      setColumnSizeForContent(5);
      setColumnSizeForVideo(7);
    }
    if (window.innerWidth < 1100) {
      setColumnSizeForContent(12);
      setColumnSizeForVideo(12);
    }
  };

  useEventListener({
    isWindow: true,
    eventName: 'resize',
    handler: determineSizeOfColumns,
    loadFirst: true,
  });

  const activeClassName: string = itemSelected.id !== '' ? Styles.active : '';

  const { title, subtitle, paragraph, videoUrl } = itemSelected;

  return {
    showDetails: () => (
      <Row className={`${Styles.container} ${activeClassName} mx-0`}>
        {
          itemSelected.id && (
            <Col className={`h-100 mx-4 px-4`}>

              <HeaderIcons close={() => selectNewItem({...defaultItem})} />

              <Row className={`${Styles.contentContainer} mx-4`}>
                <Col xs={columnSizeForContent} className="py-4 h-100">
                  <Content title={title} subtitle={subtitle} paragraph={paragraph} />
                </Col>

                <Col xs={columnSizeForVideo} className="w-100 h-100 py-4 d-flex justify-content-end">
                  <Player
                    url={videoUrl}
                    controls
                    width="90%"
                    height="90%"
                    playing={false}
                  />
                </Col>
              </Row>

            </Col>
          )
        }
      </Row>
    ),
    selectItem,
    close: () => selectNewItem({...defaultItem}),
  };
}
