import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Paragraph from '../../../../components/paragraph';

import { ContentPropsType } from './types';
import Styles from './styles.module.css';

export default function Content(props: ContentPropsType) {
  const { title, description } = props;

  return (
    <>

      <Row>
        <Col className={`${Styles.titleContainer} pb-2`}>
          <span className={Styles.title}>{title}</span>
        </Col>
      </Row>

      <Row>
        <Col className={Styles.descriptionContainer}>
          <Paragraph
            verticalAlignment="center"
            fontFamily="OgilvySerif-Light"
            fontSize={15}
            lineHeight={2}
            isBold
            content={description}
            fontColor="rgb(154, 153, 153)"
          />
        </Col>
      </Row>

    </>
  );
}
