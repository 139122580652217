import React, { useCallback, useEffect, useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { GetInTouchFormSectionPropsType } from "./types"

import Input from "../../../../components/input"
import TextArea from "../../../../components/textarea"

import Styles from "./styles.module.css"

export default function GetInTouchFormSection(props: GetInTouchFormSectionPropsType) {
  const { id, typeName, language, updateGetInTouchFormSection } = props

  const [title, setSectionTitle] = useState<string>(props.title);
  const [paragraph, setSectionParagraph] = useState<string>(props.paragraph);
  const [nameLabel, setNameLabel] = useState<string>(props.nameLabel);
  const [emailLabel, setEmailLabel] = useState<string>(props.emailLabel);
  const [serviceLabel, setServiceLabel] = useState<string>(props.serviceLabel);
  const [services, setServices] = useState<Array<string>>([ ...props.services ]);
  const [acceptEmailCommunicationLabel, setAcceptEmailCommunicationLabel] = useState<string>(props.acceptEmailCommunicationLabel);
  const [buttonLabel, setButtonLabel] = useState<string>(props.buttonLabel);

  const updateSection = useCallback((): void => {
    updateGetInTouchFormSection({
      title,
      paragraph,
      nameLabel,
      emailLabel,
      serviceLabel,
      services,
      buttonLabel,
      id,
      typeName,
      language,
      acceptEmailCommunicationLabel,
    })
  }, [id, typeName, language, title, paragraph, nameLabel, emailLabel, serviceLabel, services, buttonLabel, acceptEmailCommunicationLabel])

  useEffect(() => {
    updateSection()
  }, [title, paragraph, nameLabel, emailLabel, serviceLabel, services, buttonLabel, acceptEmailCommunicationLabel])

  return (
    <div className={Styles.worksTypeContainer}>
      <Row className="mx-0 w-100 pb-4">
        <Col className="px-0 pb-4 d-flex justify-content-center align-items-center w-100 py-2">
          <span className={`${Styles.title} pl-4`}>Formulario para contactarse con nosotros</span>
        </Col>
      </Row>

      <Row className="mx-0 w-100 p-4">
        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <Input
              value={title}
              setValue={(newTitle: string) => setSectionTitle(newTitle)}
              placeholder="Título"
              label={`Título de la sección`}
            />
          </div>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <TextArea
              value={paragraph}
              setValue={(newParagraph: string) =>
                setSectionParagraph(newParagraph)
              }
              placeholder="Párrafo"
              label={`Párrafo donde se describe el proósito de la Compañía`}
            />
          </div>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <Input
              value={nameLabel}
              setValue={(newNameLabel: string) => setNameLabel(newNameLabel)}
              placeholder="Etiqueta"
              label={`Etiqueta del Campo Nombre`}
            />
          </div>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <Input
              value={emailLabel}
              setValue={(newEmailLabel: string) => setEmailLabel(newEmailLabel)}
              placeholder="Etiqueta"
              label={`Etiqueta del Campo Correo`}
            />
          </div>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <Input
              value={serviceLabel}
              setValue={(newServiceLabel: string) => setServiceLabel(newServiceLabel)}
              placeholder="Etiqueta"
              label={`Etiqueta del Campo Servicios`}
            />
          </div>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <TextArea
              value={services.join('\n')}
              setValue={(newServices: string) =>
                setServices(newServices.split('\n'))
              }
              placeholder="Servicios"
              label={`Lista de Servicios`}
            />
          </div>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <Input
              value={acceptEmailCommunicationLabel}
              setValue={(newLabel: string) => setAcceptEmailCommunicationLabel(newLabel)}
              placeholder="Etiqueta"
              label={`Etiqueta del Campo 'Aceptas ser contactado(a) por correo'`}
            />
          </div>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <Input
              value={buttonLabel}
              setValue={(newButtonLabel: string) => setButtonLabel(newButtonLabel)}
              placeholder="Etiqueta"
              label={`Etiqueta del Botón`}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}
