import React, { useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import {
  CategoriesService,
  CategoryType
} from "../../services/categories-service"

import CategoryTitle from "./components/category-title"
import TopButtons from "../../components/top-buttons"
import ModalError from "../../components/modal-error"
import ModalQuestion from "../../components/modal-question"
import Spinner from "../../components/spinner"
import Helmet from "../../components/helmet"
import Footer from "../../components/footer"
import SubFooter from "../../components/sub-footer"
import SubcategoryForm from "./components/subcategory-form"
import CategoryForm from "./components/category-form"
import FormSelect from "./components/form-select"
import useEditCategoryLogic from "./use-edit-category-logic"
import { CategoriesActions } from "../category/slice"
import { RootState } from "../root-reducer"

import Styles from "./styles.module.css"

const FORMS: any = {
  CATEGORY: "category",
  SUBCATEGORY: "subcategory"
}

export default function EditCategoryPage() {
  const { id } = useParams<{ id: string }>()
  const [category, setCategory] = useState<CategoryType | undefined>(undefined)
  const [isModalDisplayed, displayModal] = useState<boolean>(false)
  const [isModalErrorDisplayed, displayModalError] = useState<boolean>(false)

  const [displayedForm, setFormToDisplay] = useState<string>(FORMS.CATEGORY)

  const { push, location, goBack } = useHistory()

  const dispatch = useDispatch()

  const {
    updateCategory,
    updateAllUpdatedSubcategoriesInState,
    isCategoryToEditValid,
    areSubcategoriesToEditValid
  } = useEditCategoryLogic()

  const {
    loadingCategories,
    editingCategory,
    editingSubcategory,
    categories,
    categoryToEdit,
    wasCategoryEdited,
    whichSubcategoriesHaveBeenEdited
  } = useSelector((state: RootState) => state.categories)

  const fetchCategories = async () => {
    try {
      dispatch(CategoriesActions.startFetching())

      const service: CategoriesService = CategoriesService.getInstance()
      const retrievedCategories: Array<CategoryType> =
        await service.getCategories("spanish")

      dispatch(CategoriesActions.setCategories(retrievedCategories))
      dispatch(CategoriesActions.finishFetching())
    } catch (error) {
      if (error.message === "Unauthorized") {
        push("/")
      }
    }
  }

  const fetchCategory = async () => {
    try {
      dispatch(CategoriesActions.startFetching())

      const service: CategoriesService = CategoriesService.getInstance()
      const retrievedCategory: CategoryType = await service.getCategory(id)

      setCategory(retrievedCategory)
      dispatch(CategoriesActions.setCategoryToEdit(retrievedCategory))

      if (retrievedCategory && retrievedCategory!.subcategories) {
        dispatch(
          CategoriesActions.setSubcategories(retrievedCategory!.subcategories)
        )

        if ((location as any)?.state?.showGalleryOfWorks) {
          const indexOfSubcategory: number =
            retrievedCategory!.subcategories!.findIndex(
              (subcategory) => subcategory.type === "works"
            )
          dispatch(
            CategoriesActions.selectSubcategory(
              retrievedCategory!.subcategories![
                indexOfSubcategory >= 0 ? indexOfSubcategory : 0
              ]
            )
          )
          dispatch(
            CategoriesActions.setIndexOfSubcategorySelected(
              indexOfSubcategory >= 0 ? indexOfSubcategory : 0
            )
          )
        } else {
          dispatch(
            CategoriesActions.selectSubcategory(
              retrievedCategory!.subcategories![0]
            )
          )
          dispatch(CategoriesActions.setIndexOfSubcategorySelected(0))
        }
      }

      dispatch(CategoriesActions.finishFetching())
    } catch (error) {
      if (error.message === "Unauthorized") {
        push("/")
      }
    }
  }

  useEffect(() => {
    if (categories.length === 0) {
      fetchCategories()
      fetchCategory()
    }
  }, [])

  useEffect(() => {
    fetchCategory()
  }, [id])

  useEffect(() => {
    if ((location as any)?.state?.showGalleryOfWorks as any) {
      setFormToDisplay(FORMS.SUBCATEGORY)
    }
  }, [location])

  const areThereSubcategoriesEdited = () => {
    const subcategoryEdited: boolean | undefined =
      whichSubcategoriesHaveBeenEdited.find((item: boolean): boolean => item)

    return subcategoryEdited !== undefined && subcategoryEdited
  }

  const handleGoingBackButton = () =>
    areThereSubcategoriesEdited() || wasCategoryEdited
      ? displayModal(true)
      : goBack()

  const handleSaveChangesButton = () => {
    if (areThereSubcategoriesEdited() && !areSubcategoriesToEditValid()) {
      displayModalError(true)
      return
    }

    if (areThereSubcategoriesEdited() && areSubcategoriesToEditValid()) {
      updateAllUpdatedSubcategoriesInState()
    }

    if (wasCategoryEdited && !isCategoryToEditValid()) {
      displayModalError(true)
      return
    }

    if (wasCategoryEdited && isCategoryToEditValid()) {
      updateCategory(fetchCategories)
    }
  }

  if (loadingCategories || !category || editingCategory || editingSubcategory) {
    return (
      <>
        <Helmet title="Ogilvy | Admin - Edición de Categoría" />
        <Spinner />
      </>
    )
  }

  return (
    <div className={Styles.container}>
      <Helmet title="Ogilvy | Admin - Edición de Categoría" />

      <ModalError
        title="No tan rápido"
        content="Se han encontrado datos incorrectos o campos vacíos"
        onHide={() => displayModalError(false)}
        show={isModalErrorDisplayed}
      />

      <ModalQuestion
        title="Espera un segundo..."
        question="Se han detectado cambios en la información de las categorías actuales. ¿Deseas guardar los cambios antes de regresar al menu?"
        onHide={() => {
          displayModal(false)
          push("/editar")
        }}
        show={isModalDisplayed}
        onYes={() => {
          if (areThereSubcategoriesEdited()) {
            updateAllUpdatedSubcategoriesInState()
          }

          if (wasCategoryEdited) {
            updateCategory(fetchCategories)
          }

          displayModal(false)
          push("/editar")
        }}
      />

      <TopButtons
        handleGoingBackButton={handleGoingBackButton}
        handleSaveChangesButton={handleSaveChangesButton}
        isSaveButtonDisabled={
          !areThereSubcategoriesEdited() && !wasCategoryEdited
        }
      />

      <CategoryTitle
        icon={categoryToEdit ? categoryToEdit!.icon : ""}
        title={categoryToEdit ? categoryToEdit!.title : ""}
      />

      <FormSelect
        formSelected={displayedForm}
        categoryFormName={FORMS.CATEGORY}
        subcategoryFormName={FORMS.SUBCATEGORY}
        selectCategoryForm={() => setFormToDisplay(FORMS.CATEGORY)}
        selectSubcategoryForm={() => setFormToDisplay(FORMS.SUBCATEGORY)}
      />

      {displayedForm === FORMS.SUBCATEGORY && <SubcategoryForm />}

      {displayedForm === FORMS.CATEGORY && categoryToEdit && <CategoryForm />}

      <SubFooter />

      <Footer />
    </div>
  )
}
