// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_input__2a1Ir > label {\n  padding-left: 1.5rem;\n  padding-top: 0.3rem;\n  font-family: OgilvySerif_LightItalic;\n  font-size: 25px;\n  /*color: rgb(174, 173, 173);*/\n}\n\n.styles_invalid__3J7Mu {\n  border: 1px solid red;\n}\n", "",{"version":3,"sources":["webpack://src/components/switch-control/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,oCAAoC;EACpC,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".input > label {\n  padding-left: 1.5rem;\n  padding-top: 0.3rem;\n  font-family: OgilvySerif_LightItalic;\n  font-size: 25px;\n  /*color: rgb(174, 173, 173);*/\n}\n\n.invalid {\n  border: 1px solid red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "styles_input__2a1Ir",
	"invalid": "styles_invalid__3J7Mu"
};
export default ___CSS_LOADER_EXPORT___;
