import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { ModalQuestionPropsType } from './types';

import Styles from './styles.module.css';

export default function ModalQuestion(props: ModalQuestionPropsType) {
  const {
    title, question, onHide, show, onYes, yesButtonLabel,
  } = props;

  return (
    <Modal
      size="lg"
      aria-labelledby="modal-question"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Body>
        <Row className="mx-0 py-3">
          <Col className="d-flex justify-content-start align-items-center">
            <span className={Styles.modalTitle}>{title}</span>
          </Col>

          <Col className="d-flex justify-content-end align-items-center">
            <img onClick={onHide} className={Styles.closeIcon} src="/icons/cancel-gray.png" alt="close-icon" />
          </Col>
        </Row>

        <Row className="mx-0 py-4">
          <Col className="d-flex justify-content-center">
            <span className={`${Styles.question} text-center`}>
              {question}
            </span>
          </Col>
        </Row>

        <Row className="mx-0 py-3">
          <Col className="d-flex justify-content-end align-items-center">
            <button
              className={`${Styles.yesButton} mr-3`}
              onClick={() => onYes()}
            >
              {yesButtonLabel || 'Sí, deseo guardarlos'}
            </button>

            <button
              className={Styles.noButton}
              onClick={() => onHide()}
            >
              No
            </button>
          </Col>
        </Row>
      </Modal.Body>

    </Modal>
  );
}
