import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { UseLoginPropsType } from './types';
import { AuthService } from '../../../../services/auth-service';

export default function useLogin(props: UseLoginPropsType) {
  const {
    emailValidationHandler,
    passwordValidationHandler,
  } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { push } = useHistory();

  const onEmailChange = (value: string) => {
    setEmail(value);
    emailValidationHandler(value);
  };

  const onEmailBlur = () => {
    emailValidationHandler(email);
  }

  const onPasswordChange = (value: string) => {
    setPassword(value);
    passwordValidationHandler(value);
  };

  const onPasswordBlur = () => {
    passwordValidationHandler(password);
  };

  const login = async (email: string, password: string) => {
    setErrorMessage('');

    try {
      await AuthService.getInstance().login(email, password);
      push('/menu');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return {
    email,
    password,
    errorMessage,
    onEmailChange,
    onEmailBlur,
    onPasswordChange,
    onPasswordBlur,
    login,
  };
}
