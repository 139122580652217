import React, { useCallback, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { NinethSectionPropsType } from './types';

import Input from '../../../../components/input';
import HtmlEditor from '../../../../components/html-editor';

import Styles from './styles.module.css';

export default function NinethSection(props: NinethSectionPropsType) {
  const { id, typeName, language, updateNinethSection } = props;

  const [title, setSectionTitle] = useState<string>(props.title);
  const [paragraph, setSectionParagraph] = useState<string>(props.paragraph);

  const updateSection = useCallback((): void => {
    updateNinethSection({
      title,
      paragraph,
      id,
      typeName,
      language,
    });
  }, [id, typeName, language, title, paragraph]);

  useEffect(() => {
    updateSection();
  }, [title, paragraph]);

  return (
    <div className={Styles.worksTypeContainer}>
      <Row className="mx-0 w-100 pb-4">
        <Col className="px-0 pb-4 d-flex justify-content-center align-items-center w-100 py-2">
          <span className={`${Styles.title} pl-4`}>Novena sección</span>
        </Col>
      </Row>

      <Row className="mx-0 w-100 p-4">

        <Col
          xl={6} lg={6} md={12} sm={12} xs={12}
          className="mb-4 pb-4 px-4"
        >
          <div className="mb-1">
            <Input
              value={title}
              setValue={(newTitle: string) => setSectionTitle(newTitle)}
              placeholder="Título"
              label={`Título de la sección`}
            />
          </div>
        </Col>

        <Col
          xl={6} lg={6} md={12} sm={12} xs={12}
          className="mb-4 pb-4 px-4"
        >
          <div className="mb-1">
            <HtmlEditor
              value={paragraph}
              setValue={(newParagraph: string) => setSectionParagraph(newParagraph)}
              placeholder="Párrafo"
              label={`Párrafo donde se describe el proósito de la Compañía`}
            />
          </div>
        </Col>

      </Row>
    </div>
  );
}
