import React, { useEffect } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { MenuOfPagesPropsType } from "./types"

import Header from "./header"
import MenuPage from "./menu-page"
import { constructPages } from "./pages"
import Helmet from "../../components/helmet"
import Styles from "./styles.module.css"
import { RootState } from "../root-reducer"
import { CategoriesActions } from "../category/slice"

import {
  CategoriesService,
  CategoryType
} from "../../services/categories-service"

export default function MenuOfPages(props: MenuOfPagesPropsType) {
  const { onClose } = props

  const dispatch = useDispatch()
  const { push } = useHistory()
  const { authenticatedUser, language } = useSelector(
    (state: RootState) => state.login
  )

  const { categories } = useSelector((state: RootState) => state.categories)

  const fetchCategories = async () => {
    if (categories.length !== 0) {
      return
    }

    try {
      dispatch(CategoriesActions.startFetching())

      const service: CategoriesService = CategoriesService.getInstance()
      const retrievedCategories: Array<CategoryType> =
        await service.getCategories(language)

      dispatch(CategoriesActions.setCategories(retrievedCategories))
      dispatch(CategoriesActions.finishFetching())
    } catch (error) {
      if (error.message === "Unauthorized") {
        push("/")
      }
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [language])

  return (
    <div className={`${Styles.container}`}>
      <Helmet title="Ogilvy - Menú expansivo" />

      <Row className={`${Styles.subContainer} mx-0 w-100 h-100 scrollbar-3`}>
        <Col className="px-0 w-100">
          <Header onClose={onClose} />

          {authenticatedUser &&
            constructPages(categories, language, authenticatedUser).map(
              (page) => (
                <MenuPage
                  key={`menu_page_${page.title}`}
                  {...page}
                  onClose={onClose}
                  userRole={
                    authenticatedUser ? authenticatedUser!.role : "user"
                  }
                />
              )
            )}
        </Col>
      </Row>
    </div>
  )
}
