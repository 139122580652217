import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _equals from 'shallow-equals';

import { CategoriesStateType } from './types';
import { CategoryType, SubcategoryType } from '../../services/types';

const initialState: CategoriesStateType = {
  loadingCategories: false,
  editingCategory: false,
  editingSubcategory: false,
  categories: [],
  categoryIdSelected: '',
  categoryToEdit: undefined,
  wasCategoryEdited: false,
  subcategories: [],
  subcategoriesToEdit: [],
  subcategorySelected: undefined,
  indexOfSubcategorySelected: 0,
  whichSubcategoriesHaveBeenEdited: [],
}

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    startFetching: (state) => ({ ...state, loadingCategories: true }),

    finishFetching: (state) => ({ ...state, loadingCategories: false }),

    startEditingCategory: (state) => ({ ...state, editingCategory: true }),

    finishEditingCategory: (state) => ({ ...state, editingCategory: false }),

    startEditingSubcategory: (state) => ({ ...state, editingSubcategory: true }),

    finishEditingSubcategory: (state) => ({ ...state, editingSubcategory: false }),

    setCategories: (state, action: PayloadAction<Array<CategoryType>>) => ({
      ...state,
      categories: action.payload,
    }),

    setSubcategories: (state, action: PayloadAction<Array<SubcategoryType>>) => ({
      ...state,
      subcategories: action.payload,
      subcategoriesToEdit: action.payload,
      whichSubcategoriesHaveBeenEdited: action.payload.map(() => false),
    }),

    selectCategory: (state, action: PayloadAction<string>) => ({
      ...state, categoryIdSelected: action.payload,
    }),

    setCategoryToEdit: (state, action: PayloadAction<CategoryType>) => ({
      ...state,
      categoryToEdit: action.payload,
      wasCategoryEdited: false,
    }),

    selectSubcategory: (state, action: PayloadAction<SubcategoryType>) => ({
      ...state, subcategorySelected: action.payload,
    }),

    setIndexOfSubcategorySelected: (state, action: PayloadAction<number>) => ({
      ...state, indexOfSubcategorySelected: action.payload,
    }),

    setWhichSubcategoriesHaveSelected: (state, action: PayloadAction<Array<boolean>>) => ({
      ...state, whichSubcategoriesHaveBeenEdited: action.payload,
    }),

    editSubcategory: (
      state,
      action: PayloadAction<SubcategoryType>
    ) => {
      const { payload: updatedSubcategory } = action;
      const newState: CategoriesStateType = {...state};

      const wasSubcategoryActuallyEdited: boolean = !_equals(newState.subcategoriesToEdit[newState.indexOfSubcategorySelected], updatedSubcategory)
        && !newState.whichSubcategoriesHaveBeenEdited[state.indexOfSubcategorySelected];

      if (wasSubcategoryActuallyEdited) {
        const newSubcategoriesEdited = [...newState.whichSubcategoriesHaveBeenEdited];
        newSubcategoriesEdited[newState.indexOfSubcategorySelected] = true;
        newState.whichSubcategoriesHaveBeenEdited = [...newSubcategoriesEdited];
      }

      const newSubcategories = [...newState.subcategoriesToEdit];
      newSubcategories[newState.indexOfSubcategorySelected] = {...updatedSubcategory};
      newState.subcategoriesToEdit = [...newSubcategories];
      newState.subcategorySelected = {...updatedSubcategory};

      return newState;
    },

    editCategory: (state, action: PayloadAction<CategoryType>) => ({
      ...state,
      categoryToEdit: action.payload,
      wasCategoryEdited: true,
    }),
  },
});

export const CategoriesActions = categoriesSlice.actions;
