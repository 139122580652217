// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_formContainer__1_z9z {\n  padding-left: 8em;\n  padding-right: 8em;\n  padding-top: 8em;\n}\n\n.styles_pageTitle__1vGWi {\n  font-size: 55px;\n  font-family: OgilvySerif_LightItalic;\n}\n\n.styles_contentLabel__33B87 {\n  font-size: 25px;\n  font-family: OgilvySerif_LightItalic;\n  color: rgb(174, 173, 173);\n}\n\n.styles_editorContainer__35eBk {\n}\n\n.styles_addUserContainer__20BoR {\n  cursor: pointer;\n}\n\n.styles_addUserText__21DFj {\n  font-size: 20px;\n  font-family: OgilvySans_Regular;\n}\n\n.styles_addUserIcon__fft29 {\n  width: 15px;\n  height: 15px;\n}\n\n.styles_trashIcon__1omyJ {\n  width: 25px;\n  height: 25px;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://src/pages/edit-blog/styles.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,oCAAoC;AACtC;;AAEA;EACE,eAAe;EACf,oCAAoC;EACpC,yBAAyB;AAC3B;;AAEA;AACA;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".formContainer {\n  padding-left: 8em;\n  padding-right: 8em;\n  padding-top: 8em;\n}\n\n.pageTitle {\n  font-size: 55px;\n  font-family: OgilvySerif_LightItalic;\n}\n\n.contentLabel {\n  font-size: 25px;\n  font-family: OgilvySerif_LightItalic;\n  color: rgb(174, 173, 173);\n}\n\n.editorContainer {\n}\n\n.addUserContainer {\n  cursor: pointer;\n}\n\n.addUserText {\n  font-size: 20px;\n  font-family: OgilvySans_Regular;\n}\n\n.addUserIcon {\n  width: 15px;\n  height: 15px;\n}\n\n.trashIcon {\n  width: 25px;\n  height: 25px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": "styles_formContainer__1_z9z",
	"pageTitle": "styles_pageTitle__1vGWi",
	"contentLabel": "styles_contentLabel__33B87",
	"editorContainer": "styles_editorContainer__35eBk",
	"addUserContainer": "styles_addUserContainer__20BoR",
	"addUserText": "styles_addUserText__21DFj",
	"addUserIcon": "styles_addUserIcon__fft29",
	"trashIcon": "styles_trashIcon__1omyJ"
};
export default ___CSS_LOADER_EXPORT___;
