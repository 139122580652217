import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { FormSelectPropsType } from './types';

import Styles from './styles.module.css';

export default function FormSelect(props: FormSelectPropsType) {
  const {
    formSelected, categoryFormName, subcategoryFormName,
    selectCategoryForm, selectSubcategoryForm,
  } = props;
  
  const [categoryFormClassName, setCategoryFormClassName] = useState<string>(formSelected === categoryFormName
    ? `${Styles.option} ${Styles.selected}`
    : `${Styles.option}`);
  
  const [subcategoryFormClassName, setSubcategoryFormClassName] = useState<string>(formSelected === subcategoryFormName
    ? `${Styles.option} ${Styles.selected}`
    : `${Styles.option}`);

    useEffect(
      () => {
        setCategoryFormClassName(formSelected === categoryFormName
          ? `${Styles.option} ${Styles.selected}`
          : `${Styles.option}`);

        setSubcategoryFormClassName(formSelected === subcategoryFormName
          ? `${Styles.option} ${Styles.selected}`
          : `${Styles.option}`);
      },
      [formSelected],
    );

  return (
    <Row className={`${Styles.subcategoryFormContainer} w-100 mx-0 mb-4 px-4`}>
      <Col className={`px-4 pb-4`}>
        
        <Row className="mx-0">
          <Col
            className={`${categoryFormClassName} px-2 py-4 mr-2 d-flex justify-content-center align-items-center`}
            onClick={selectCategoryForm}
          >
            <span className="text-center">Editar información general de la categoría</span>
          </Col>

          <Col
            className={`${subcategoryFormClassName} px-2 py-4 ml-2 d-flex justify-content-center align-items-center`}
            onClick={selectSubcategoryForm}
          >
            <span className="text-center">Editar las subcategorías</span>
          </Col>
        </Row>

      </Col>
    </Row>
  )
}
