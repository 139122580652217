import React from 'react';

import {
  ContentType,
  WorkType,
  PeopleType,
  SubcategoryType,
  GraphicSupportType,
} from '../../../../services/types';

import { UseSubcategoryFormLogicType } from './types';

import { SubcategoriesService } from '../../../../services/subcategories-service';

export default function useSubcategoryFormLogic(props: UseSubcategoryFormLogicType) {
  const { information, updateSubcategoryInState } = props;

  const updateTitle = (newTitle: string) => {
    updateSubcategoryInState({
      ...information,
      title: newTitle,
    });
  };

  const updateLabelFontColor = (color: string) => {
    updateSubcategoryInState({
      ...information,
      label: {
        ...information.label,
        fontColor: color,
      },
    });
  };

  const updateLabelBorderColor = (color: string) => {
    updateSubcategoryInState({
      ...information,
      label: {
        ...information.label,
        borderColor: color,
      },
    });
  };

  const updateLabelBackgroundColor = (color: string) => {
    updateSubcategoryInState({
      ...information,
      label: {
        ...information.label,
        backgroundColor: color,
      },
    });
  };

  const updateContents = (newContents: Array<ContentType>) => {
    updateSubcategoryInState({
      ...information,
      contents: [...newContents],
    });
  };

  const updateWorks = (newWorks: Array<WorkType>) => {
    updateSubcategoryInState({
      ...information,
      works: [...newWorks],
    });
  };

  const updatePeople = (newPeople: Array<PeopleType>) => {
    updateSubcategoryInState({
      ...information,
      people: [...newPeople],
    });
  };

  const updateGraphicSupport = (newGraphicSupport: GraphicSupportType) => {
    updateSubcategoryInState({
      ...information,
      graphicSupport: {...newGraphicSupport},
    });
  };

  const updateAllUpdatedSubcategoriesInState = async (
    subcategoriesToUpdate: Array<SubcategoryType>,
    updateSubcategoriesInState: (newSubcategories: Array<SubcategoryType>) => void,
  ) => {
    const service: SubcategoriesService = SubcategoriesService.getInstance();

    const promises: Array<Promise<SubcategoryType>> = subcategoriesToUpdate
      .map((subcategoryToUpdate: SubcategoryType): Promise<SubcategoryType> => service.updateSubcategory(subcategoryToUpdate));

    const updatedSubcategories = await Promise.all(promises);
    updateSubcategoriesInState(updatedSubcategories);
  };

  return {
    updateTitle,
    updateLabelFontColor,
    updateLabelBorderColor,
    updateLabelBackgroundColor,
    updateContents,
    updateWorks,
    updatePeople,
    updateGraphicSupport,
    updateAllUpdatedSubcategoriesInState,
  };
}
