import React from 'react';

import { UseCategoryFormLogicType } from './types';

export default function useSubcategoryFormLogic(props: UseCategoryFormLogicType) {
  const { information, updateCategoryInState } = props;

  const updateTitle = (newTitle: string) => {
    updateCategoryInState({
      ...information,
      title: newTitle,
    });
  };

  const updateDescription = (newDescription: string) => {
    updateCategoryInState({
      ...information,
      description: newDescription,
    });
  };

  const updateIcon = (newIcon: string) => {
    updateCategoryInState({
      ...information,
      icon: newIcon,
    });
  };

  const updateColor = (newColor: string) => {
    updateCategoryInState({
      ...information,
      color: newColor,
    });
  };

  const updateVideoUrl = (newVideoUrl: string) => {
    updateCategoryInState({
      ...information,
      video: newVideoUrl,
    });
  };

  const updateBackgroundVideoUrl = (newBackgroundVideoUrl: string) => {
    updateCategoryInState({
      ...information,
      backgroundVideoImage: newBackgroundVideoUrl,
    });
  };

  return {
    updateTitle,
    updateDescription,
    updateIcon,
    updateColor,
    updateVideoUrl,
    updateBackgroundVideoUrl,
  };
}
