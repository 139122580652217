import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import "bootstrap/dist/css/bootstrap.min.css"
import "./global.css"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import reportWebVitals from "./reportWebVitals"
import { createReduxStore } from "./store"

const store = createReduxStore()

const render = (Component: any) => {
  return ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Component />
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  )
}

render(App)

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default
    render(NextApp)
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
