import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { GraphicSupportPropsType } from './types';

import Styles from './styles.module.css';

export default function GraphicSupport(props: GraphicSupportPropsType) {
  const {
    firstImage, secondImage, thirdImage,
    firstText, secondText, thirdText,
  } = props;

  return (
    <Row className={`mx-0 w-100 h-100 px-4`}>
      <Col className={`${Styles.container} px-4 d-flex justify-content-center align-items-start`}>
        <img src={thirdImage} className={Styles.sphere3} alt="image3" />
        <img src={secondImage} className={Styles.sphere2} alt="image2" />
        <img src={firstImage} className={Styles.sphere1} alt="image1" />

        <h3 className={Styles.sphere1Title}>{firstText}</h3>
        <h3 className={Styles.sphere2Title}>{secondText}</h3>
        <h3 className={Styles.sphere3Title}>{thirdText}</h3>
      </Col>
    </Row>
  )
}
