// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_button__3pznW {\n  border-top-width: 1px;\n  border-left-width: 1px;\n  border-right-width: 1px;\n  border-bottom-width: 1px;\n  border-radius: 40px;\n  background-color: transparent;\n  padding: 5px 40px;\n  font-size: 25px;\n  color: rgb(59, 58, 58);\n  transition: background-color 0.5s ease;\n}\n\n.styles_button__3pznW:hover:enabled {\n  background-color: rgb(230, 230, 231);\n}\n\n.styles_button__3pznW:focus {\n  outline: 0;\n}\n\n.styles_button__3pznW:disabled {\n  color: rgb(174, 173, 173);\n}\n", "",{"version":3,"sources":["webpack://src/components/button/styles.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,uBAAuB;EACvB,wBAAwB;EACxB,mBAAmB;EACnB,6BAA6B;EAC7B,iBAAiB;EACjB,eAAe;EACf,sBAAsB;EACtB,sCAAsC;AACxC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".button {\n  border-top-width: 1px;\n  border-left-width: 1px;\n  border-right-width: 1px;\n  border-bottom-width: 1px;\n  border-radius: 40px;\n  background-color: transparent;\n  padding: 5px 40px;\n  font-size: 25px;\n  color: rgb(59, 58, 58);\n  transition: background-color 0.5s ease;\n}\n\n.button:hover:enabled {\n  background-color: rgb(230, 230, 231);\n}\n\n.button:focus {\n  outline: 0;\n}\n\n.button:disabled {\n  color: rgb(174, 173, 173);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "styles_button__3pznW"
};
export default ___CSS_LOADER_EXPORT___;
