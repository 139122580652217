import React from 'react';
import { SketchPicker } from 'react-color';

import { ColorPickerPropsType } from './types';

import Styles from './styles.module.css';

export default function ColorPicker(props: ColorPickerPropsType) {
  const { onChange, onClose, value } = props;

  return (
    <div className={Styles.popover}>
      <div className={Styles.cover} onClick={onClose}/>
      
      <SketchPicker color={value} onChange={onChange} />
    </div>
  );
}
