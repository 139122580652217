import { useEffect, useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { GalleryProps, GelleryItemType } from './types';

import useEventListener from '../../../../components/reactivity-to-general-behaviors/event-listener';
import FourColumnsThreeRows from './four-columns-three-rows';
import ThreeColumnsThreeRows from './three-columns-three-rows';
import TwoColumnsThreeRows from './two-columns-three-rows';
import OneColumnEightRows from './one-column-eight-rows';

import Styles from './styles.module.css';

export default function GalleryOfWorks(props: GalleryProps) {
  const { items } = props;

  const [gridType, setGridType] = useState('');

  const determineGridToDisplay = () => {
    if (window.innerWidth < 600) {
      setGridType('1x8');
    }

    if (window.innerWidth >= 600 && window.innerWidth < 880) {
      setGridType('3x2');
    }

    if (window.innerWidth >= 880 && window.innerWidth < 1200) {
      setGridType('3x3');
    }

    if (window.innerWidth >= 1200) {
      setGridType('3x4');
    }
  };

  useEventListener({
    isWindow: true,
    eventName: 'resize',
    handler: determineGridToDisplay,
    loadFirst: true,
  });

  switch (gridType) {
    case '1x8':
      return (
        <Row className={`${Styles.container} mx-0 py-4`}>
          <Col className="px-0 py-4 h-100">
            <OneColumnEightRows items={items} />
          </Col>
        </Row>
      );
    case '3x2':
      return (
        <Row className={`${Styles.container} mx-0 py-4`}>
          <Col className="px-0 py-4 h-100">
            <TwoColumnsThreeRows items={items} />
          </Col>
        </Row>
      );
    case '3x3':
      return (
        <Row className={`${Styles.container} mx-0 py-4`}>
          <Col className="px-0 py-4 h-100">
            <ThreeColumnsThreeRows items={items} />
          </Col>
        </Row>
      );

    case '3x4':
      return (
        <Row className={`${Styles.container} mx-0 py-4`}>
          <Col className="px-0 py-4 h-100">
            <FourColumnsThreeRows items={items} />
          </Col>
        </Row>
      );
  
    default:
      return null;
  }
}
