import {
  AuthenticatedUserType,
  AddUserObjectType,
  PersonPublicInformationType,
  UserPublicInformationType
} from "./types"

import { HttpService } from "./http-service"

export class UsersService {
  private httpService: HttpService
  private static instance: UsersService

  constructor() {
    this.httpService = HttpService.getInstance()
  }

  public async getUsers(
    page: number,
    pageSize: number,
    role: string = ""
  ): Promise<Array<AuthenticatedUserType>> {
    try {
      const roleSegment: string = role !== "" ? `&role=${role}` : ""

      const response: any = await this.httpService.get(
        `users?sortBy=firstName&page=${page}&limit=${pageSize}${roleSegment}`,
        null,
        true
      )
      return response.results.map(
        (person: any): AuthenticatedUserType => ({
          id: person.id as string,
          firstName: person.firstName as string,
          lastName: person.lastName as string,
          email: person.email as string,
          imageRoute: person.pictureUrl as string,
          role: person.role as string,
          jobTitle: person.jobTitle as string,
          isPublic: person.isPublic as boolean,
          phoneNumber: person.phoneNumber as string,
          publicInformation:
            person.publicInformation as Array<UserPublicInformationType>
        })
      )
    } catch {
      return []
    }
  }

  public async getTotalNumberOfUsers(): Promise<number> {
    const response: any = await this.httpService.get("users/total", null, true)
    return response.total as number
  }

  public async getUser(userId: string): Promise<AuthenticatedUserType | null> {
    try {
      const user: any = await this.httpService.get(
        `users/${userId}`,
        null,
        true
      )

      return {
        id: user.id as string,
        firstName: user.firstName as string,
        lastName: user.lastName as string,
        email: user.email as string,
        imageRoute: user.pictureUrl as string,
        role: user.role as string,
        jobTitle: user.jobTitle as string,
        isPublic: user.isPublic as boolean,
        phoneNumber: user.phoneNumber as string,
        publicInformation:
          user.publicInformation as Array<UserPublicInformationType>
      }
    } catch {
      return null
    }
  }

  public async getAuthors(): Promise<Array<AuthenticatedUserType> | null> {
    try {
      const authors: Array<AuthenticatedUserType> = await this.httpService.get(
        "users/authors",
        null,
        true
      )
      return authors
    } catch {
      return null
    }
  }

  public async updateUser(
    userId: string,
    firstName: string,
    lastName: string,
    publicInformation: Array<PersonPublicInformationType>,
    isPublic: boolean,
    phoneNumber: string,
    email: string,
    role?: string,
    pictureUrl?: string
  ): Promise<AuthenticatedUserType | null> {
    try {
      const user: AuthenticatedUserType = await this.httpService.put(
        `users/${userId}`,
        {
          firstName,
          lastName,
          publicInformation,
          role,
          pictureUrl,
          isPublic,
          phoneNumber,
          email
        },
        true
      )

      return user
    } catch {
      return null
    }
  }

  public async createUser(
    data: AddUserObjectType
  ): Promise<AuthenticatedUserType | null> {
    try {
      const user: AuthenticatedUserType = await this.httpService.post(
        "users",
        data,
        true
      )
      return user
    } catch {
      return null
    }
  }

  public async uploadProfilePicture(userId: string, file: File): Promise<any> {
    try {
      const response = await this.httpService.upload(
        `users/${userId}`,
        file,
        true
      )
      return response
    } catch {
      return null
    }
  }

  public async deleteUser(userId: string): Promise<void> {
    try {
      await this.httpService.delete(`users/${userId}`, true)
    } catch {}
  }

  public static getInstance(): UsersService {
    if (!UsersService.instance) {
      UsersService.instance = new UsersService()
    }

    return UsersService.instance
  }
}
