import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { LoginActions } from '../login/slice';

export default function LogoutPage() {
  const { push } = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');

    dispatch(LoginActions.removeAuthenticatedUser());

    push('/');
  }, []);

  return (
    <div>
    </div>
  )
}
