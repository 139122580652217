import React, { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { useDispatch } from "react-redux"

import { EditUserModalPropsType, RoleType } from "./types"
import { UserPublicInformationType } from "../../../../services/types"

import Input from "../../../../components/input"
import Select from "../../../../components/select"
import SwitchControl from "../../../../components/switch-control"
import { UsersService } from "../../../../services/users-service"
import { UserActions } from "../../slice"
import { Roles } from "../../roles"

import Styles from "./styles.module.css"

const usersService = UsersService.getInstance()

export default function EditUserModal(props: EditUserModalPropsType) {
  const {
    show,
    onHide,
    firstName,
    lastName,
    role,
    publicInformation,
    userId,
    callback,
    isPublic,
    phoneNumber,
    email,
    imageRoute
  } = props

  const publicInformationInSpanish: UserPublicInformationType | undefined =
    publicInformation.find(
      (item: UserPublicInformationType): boolean =>
        item.language.toLowerCase() === "spanish"
    )

  const publicInformationInEnglish: UserPublicInformationType | undefined =
    publicInformation.find(
      (item: UserPublicInformationType): boolean =>
        item.language.toLowerCase() === "english"
    )

  const [newFirstName, setNewFirstName] = useState<string>(firstName)
  const [newLastName, setNewLastName] = useState<string>(lastName)

  const [newJobTitleInSpanish, setNewJobTitleInSpanish] = useState<string>(
    (publicInformationInSpanish as UserPublicInformationType).jobTitle
  )

  const [newJobTitleInEnglish, setNewJobTitleInEnglish] = useState<string>(
    (publicInformationInEnglish as UserPublicInformationType).jobTitle
  )

  const [newRole, setNewRole] = useState<number>(
    Roles.findIndex((item: RoleType): boolean => item.name === role)
  )

  const [newIsPublic, setNewIsPublic] = useState<boolean>(isPublic)
  const [newPhoneNumber, setNewPhoneNumber] = useState<string>(phoneNumber)
  const [newEmail, setNewEmail] = useState<string>(email)
  const [newImageRoute, setNewImageRoute] = useState<string>(imageRoute)

  const dispatch = useDispatch()

  const updateUser = async () => {
    dispatch(UserActions.startFetchingUsers())

    const foundRole: string = Roles[newRole].name
    await usersService.updateUser(
      userId,
      newFirstName,
      newLastName,
      [
        {
          ...(publicInformationInEnglish as UserPublicInformationType),
          jobTitle: newJobTitleInEnglish
        },
        {
          ...(publicInformationInSpanish as UserPublicInformationType),
          jobTitle: newJobTitleInSpanish
        }
      ],
      newIsPublic,
      newPhoneNumber,
      newEmail,
      foundRole,
      newImageRoute
    )
    callback()

    dispatch(UserActions.finishFetchingUsers())
  }

  useEffect(() => {
    setNewFirstName(firstName)
    setNewLastName(lastName)
    setNewRole(Roles.findIndex((item: RoleType): boolean => item.name === role))
    setNewIsPublic(isPublic)
    setNewPhoneNumber(phoneNumber)
    setNewImageRoute(imageRoute)
    setNewEmail(email)
  }, [firstName, lastName, role, isPublic, phoneNumber, imageRoute, email])

  return (
    <Modal
      size="lg"
      aria-labelledby="modal-question"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Body>
        <Row className="mx-0 py-3">
          <Col className="d-flex justify-content-start align-items-center">
            <span className={Styles.modalTitle}>
              Editar información de {firstName}
            </span>
          </Col>

          <Col className="d-flex justify-content-end align-items-center">
            <img
              onClick={onHide}
              className={Styles.closeIcon}
              src="/icons/cancel-gray.png"
              alt="close-icon"
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa tú primer nombre"
              label="Primer Nombre"
              value={newFirstName}
              setValue={setNewFirstName}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa tú primer apellido"
              label="Primer Apellido"
              value={newLastName}
              setValue={setNewLastName}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa el número telefónico"
              label="Número Telefónico"
              value={newPhoneNumber}
              setValue={setNewPhoneNumber}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa el correo electrónico"
              label="Correo Electrónico"
              value={newEmail}
              setValue={setNewEmail}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa el url de la imagen de perfil"
              label="Imagen de Perfil"
              value={newImageRoute}
              setValue={setNewImageRoute}
            />
          </Col>
        </Row>

        <Row className="w-100 mb-4 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa tú puesto de trabajo"
              label="Puesto de Trabajo en Español"
              value={newJobTitleInSpanish}
              setValue={setNewJobTitleInSpanish}
            />
          </Col>
        </Row>

        <Row className="w-100 mb-4 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa tú puesto de trabajo"
              label="Puesto de Trabajo en Inglés"
              value={newJobTitleInEnglish}
              setValue={setNewJobTitleInEnglish}
            />
          </Col>
        </Row>

        <Row className="w-100 mb-4 mx-0">
          <Col className="px-3">
            <Select
              label="Rol"
              optionSelected={newRole}
              selectOption={setNewRole}
              optionsLabels={Roles.map((item: RoleType) => item.displayName)}
            />
          </Col>
        </Row>

        <Row className="w-100 mb-4 mx-0">
          <Col className="px-3">
            <SwitchControl
              id={`switch-control-public-${userId}`}
              value={newIsPublic}
              setValue={(value: boolean) => setNewIsPublic(value)}
              label="¿Se mostrará este usuario en el sitio público?"
              isValid
            />
          </Col>
        </Row>

        <Row className="mx-0 py-3">
          <Col className="d-flex justify-content-end align-items-center">
            <button
              className={`${Styles.yesButton} mr-3`}
              disabled={
                !newFirstName ||
                !newLastName ||
                !newJobTitleInSpanish ||
                !newJobTitleInEnglish
              }
              onClick={() => updateUser()}
            >
              Guardar
            </button>

            <button className={Styles.noButton} onClick={() => onHide()}>
              Cancelar
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
