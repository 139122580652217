import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { CategoryMenuPropsType, CategoryMenuType } from './types';

import Styles from './styles.module.css';

export default function CategoryMenu(props: CategoryMenuPropsType) {
  const {
    items, onItemSelected, selectedItem, title,
    isRightOptionAvailable, rightOptionInactiveIcon,
    rightOptionHandler,
  } = props;

  const getSelectedItemContainerAdditionalStyle = (index: number): Object => {
    return index === selectedItem
      ? {
        borderBottom: `1px ${items[index].label.borderColor} solid`,
      }
      : {};
  };
  
  const getItemContainerStyle = (index: number): Object => {
    return {
      backgroundColor: items[index].label.backgroundColor,
      border: `1px ${items[index].label.borderColor} solid`,
    };
  };

  const getOptionTitleStyle = (index: number): Object => {
    return index === selectedItem
      ? {
        color: items[index].label.fontColor,
        fontSize: 15,
        fontFamily: 'OgilvySans_RegularItalic',
        fontWeight: 'bolder',
      }
      : {
        color: items[index].label.fontColor,
        fontSize: 15,
        fontFamily: 'OgilvySans_Light',
      };
  };

  return (
    <>
      <Row className={`mx-0 w-100 px-4`}>
        <Col className={`px-4`}>
          <span className={`${Styles.title} px-2`}>{title}</span>
        </Col>
      </Row>

      <Row className={`mx-0 w-100`}>
        <Col
          className={`px-4`}
          xl={9} lg={9} md={9} sm={12} xs={12}
        >
          <Row className={`mx-0 w-100 ${Styles.container}`}>
            {
              items.map((item: CategoryMenuType, index: number): any => 
                (
                  <Col
                    className={`px-0 pb-1`}
                    xs="auto"
                    key={item.title}
                    style={getSelectedItemContainerAdditionalStyle(index)}
                    onClick={() => onItemSelected(index)}
                  >
                    
                    <Row className={`${Styles.option} mx-0`} style={getItemContainerStyle(index)}>
                      <Col className={``}>
                        <span style={getOptionTitleStyle(index)}>{item.title}</span>
                      </Col>
                    </Row>

                  </Col>
                )
              )
            }
          </Row>
        </Col>

        {
          isRightOptionAvailable && (
            <Col
              className={`px-4 d-flex justify-content-end`}
              xl={3} lg={3} md={3} sm={12} xs={12}
            >
              <div
                style={{
                  backgroundImage: `url("${rightOptionInactiveIcon}")`,
                }}
                className={`${Styles.rightOption} mr-4`}
                onClick={rightOptionHandler}
              />
            </Col>
          )
        }
      </Row>
    </>
  );
}
