import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { InputPropsType } from './types';

import ColorPicker from './color-picker';

import Styles from './styles.module.css';

export default function ColorInput(props: InputPropsType) {
  const {
    value, placeholder, setValue, label,
  } = props;

  const [displayColorBox, setDisplayColorBox] = useState(false);

  const onChange = (color: any) => {
    setValue(color.hex.toUpperCase());

  }

  const onClose = () => setDisplayColorBox(false);

  return (
    <>
      <Row className="mx-0 w-100">
        <Col className="px-0 d-flex justify-content-start align-items-center w-100 py-2">
          <span className={`${Styles.label}`}>{label}</span>
        </Col>
      </Row>

      <Row className="mx-0 w-100">
        <Col
          xs="auto"
          className={`${Styles.colorDisplayBox} mt-3`}
          style={{ backgroundColor: value }}
          onClick={() => setDisplayColorBox(true)}
        />
        
        <Col
          className="pr-0 pl-2 d-flex justify-content-center align-items-center w-100 py-2"
          onClick={() => setDisplayColorBox(true)}
        >
          <input
            className={`${Styles.input} w-100`}
            placeholder={placeholder}
            value={value}
            readOnly
          />
        </Col>
      </Row>

      {
        displayColorBox && (
          <ColorPicker onChange={onChange} onClose={onClose} value={value} />
        )
      }
    </>
  );
}
