import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  CategoriesService,
  CategoryType,
} from '../../services/categories-service';

import Helmet from '../../components/helmet';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import SubFooter from '../../components/sub-footer';
import Section4 from '../../components/section-4';
import { CategoriesActions } from '../category/slice';
import * as Data from './example.json';
import { RootState } from '../root-reducer';
import Spinner from '../../components/spinner';

import Styles from './styles.module.css';

export default function MainMenuPage() {
  const { categories, loadingCategories } = useSelector((state: RootState) => state.categories);
  const { language } = useSelector((state: RootState) => state.login);
  const dispatch = useDispatch();
  const { push } = useHistory();

  const fetchCategories = async () => {
    try {
      dispatch(CategoriesActions.startFetching());

      const service: CategoriesService = CategoriesService.getInstance();
      const retrievedCategories: Array<CategoryType> = await service.getCategories(language);

      dispatch(CategoriesActions.setCategories(retrievedCategories));
      dispatch(CategoriesActions.finishFetching());
    } catch (error) {
      if (error.message === 'Unauthorized') {
        push('/');
      }
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [language]);

  const selectecCategory = (categoryId: string) => {
    dispatch(CategoriesActions.selectCategory(categoryId));
  };

  if (loadingCategories) {
    return (<Spinner />);
  }

  const categoriesWithTheirRoutes = categories.map((category) => ({
    ...category,
    route: `/categoria/${category.id}`,
  }));

  return (
    <div className={Styles.container}>
      <Helmet title="Ogilvy - Menú Principal" />

      <Menu
        categories={categoriesWithTheirRoutes}
        selectecCategory={selectecCategory}
        isAtTheTopOfThePage
        location="show-category"
      />

      <Section4
        theme="light"
        key="light-message-section"
        title={Data.section4[language].title}
        paragraph={Data.section4[language].paragraph}
        imageRoute={Data.section4[language].imageRoute}
        identifier="main-menu-1"
      />

      <SubFooter />

      <Footer />
    </div>
  );
}
