import React, { useEffect, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';

import { GelleryItemPropsType } from './types';

import Styles from './styles.module.css';
import Slide from "../../../blogs/components/blog-item/slide";

export default function GalleryItem(props: GelleryItemPropsType) {
  const {
    item,
    selectItem,
    timer,
  } = props;

  const [containerClass, setContainerClass] = useState('');
  const containerClassRef = useRef(containerClass);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setContainerClass(() => {
        containerClassRef.current = Styles.endAnimation;
        return Styles.endAnimation;
      });
    }, timer);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Col className={`${Styles.startAnimation} ${containerClass} px-0 w-100 h-100`}>
      <Slide responsiveImages={item.mainResponsiveImages} index={1} />

      <div
        className={`${Styles.titlesContainer} d-flex flex-column pl-4 pb-4 justify-content-end`}
        onClick={selectItem}
      >
        <span className={Styles.title}>{item.title}</span>
        <span className={Styles.subtitle}>{item.subtitle}</span>
      </div>
    </Col>
  )
}
