import { useEffect } from 'react';

interface UseResizeHandlerPropsType {
  isWindow: boolean,
  elementId?: string,
  eventName: string,
  loadFirst: boolean,
  handler: () => void
}

export default function useEventListener(props: UseResizeHandlerPropsType) {
  const { isWindow, eventName, elementId, handler, loadFirst } = props;

  useEffect(() => {
    const element: any = isWindow ? window : document.getElementById(elementId || '');
    element?.addEventListener(eventName, handler);

    if (loadFirst) {
      handler();
    }
  
    return () => {
      element.removeEventListener(eventName, handler);
    };
  }, []);

  return {};
}
