import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { RootState } from '../../pages/root-reducer';

import Styles from './styles.module.css';

export default function MenuIcon() {
  const { authenticatedUser, language } = useSelector((state: RootState) => state.login);

  if (!authenticatedUser) return null;

  return language === 'english' ? (
    <Link to="/menu-pages">
      <img src="/icons/menu.png" className={Styles.menuIcon} onClick={() => true} alt="menu-pages-icon" />
    </Link>
  )
  : (
    <Link to="/menu-paginas">
      <img src="/icons/menu.png" className={Styles.menuIcon} onClick={() => true} alt="menu-paginas-icon" />
    </Link>
  )
}
