import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { WorksTypePropsType, UpdatedWorkType } from "./types"
import {ResponsiveImageType, WorkType} from "../../../../services/types"

import AddWorkModal from "../../../../components/add-work-modal"
import WorkEditingForm from "./work-editing-form"

import Styles from "./styles.module.css"

export default function WorksType(props: WorksTypePropsType) {
  const [isModalDisplayed, displayModal] = useState<boolean>(false)

  const deleteWork = (index: number) => {
    const newWorks = [...props.works]
    newWorks.splice(index, 1)
    props.updateWorks([...newWorks])
  }

  const updateWork = (index: number, updatedWork: UpdatedWorkType) => {
    const newWorks = [...props.works]
    newWorks[index] = { ...newWorks[index], ...updatedWork }
    props.updateWorks([...newWorks])
  }

  const createWork = (
    title: string,
    subtitle: string,
    paragraph: string,
    videoUrl: string,
    mainResponsiveImages: Array<ResponsiveImageType>,
    order: string
  ) => {
    const newWork: WorkType = {
      title,
      subtitle,
      paragraph,
      videoUrl,
      mainResponsiveImages,
      order,
      images: [],
      displayVideo: true,
      displayImages: false,
      isPublic: true,
      id: "____",
      category: props.categoryId
    }

    const newWorks = [...props.works]
    newWorks.push(newWork)

    props.updateWorks(newWorks)
  }

  return (
    <div className={Styles.worksTypeContainer}>
      <AddWorkModal
        show={isModalDisplayed}
        onHide={() => displayModal(false)}
        createWork={createWork}
      />

      <Row className="mx-0 w-100 pb-4">
        <Col className="px-0 pb-4 d-flex justify-content-center align-items-center w-100 py-2">
          <span className={`${Styles.title} pl-4`}>
            Lista de Casos de Exitos
          </span>
        </Col>
      </Row>

      <Row className="mx-0 w-100">
        <Col className="px-0 pb-4 d-flex justify-content-end align-items-center w-100 py-2">
          <div
            className={`${Styles.addUserContainer} d-flex align-items-center`}
            onClick={() => displayModal(true)}
          >
            <img
              className={`${Styles.addUserIcon} mr-2`}
              src="/icons/plus.png"
              alt="plus-icon"
            />
            <span className={Styles.addUserText}>Agregar</span>
          </div>
        </Col>
      </Row>

      <Row className="mx-0 w-100 py-4">
        {props.works.map((work: WorkType, index: number) => {
          return (
            <WorkEditingForm
              key={work.id}
              id={work.id}
              index={index}
              title={work.title}
              subtitle={work.subtitle}
              paragraph={work.paragraph}
              videoUrl={work.videoUrl}
              mainResponsiveImages={work.mainResponsiveImages}
              order={work.order}
              categoryId={props.categoryId}
              isPublic={work.isPublic}
              deleteWork={deleteWork}
              updateWork={updateWork}
            />
          )
        })}
      </Row>
    </div>
  )
}
