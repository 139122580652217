import { useEffect, useState } from 'react';
import Player from 'react-player';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { VideoPropsType } from './types';

import Styles from './styles.module.css';

import PlayIcon from './play-icon';
import VideoPlayer from './video-player';

export default function Video(props: VideoPropsType) {
  const { videoUrl } = props;

  const [isVideoDisplayed, displayVideo] = useState(false);

  if (!isVideoDisplayed) {
    return (
      <PlayIcon play={() => displayVideo(true)} />
    );
  }

  return (<VideoPlayer close={() => displayVideo(false)} videoUrl={videoUrl} />);
}
