import React from "react"

import CarouselSection from "./components/carousel-type"
import FirstSection from "./components/first-section-type"
import VideoSection from "./components/video-section-type"
import PurposeSection from "./components/purpose-section-type"
import OfficesSection from "./components/offices-section-type"
import FourthSection from "./components/fourth-section-type"
import WorksSection from "./components/works-section-type"
import FifthSection from "./components/fifth-section-type"
import SixthSection from "./components/sixth-section-type"
import PeopleSectionType from "./components/people-section-type"
import BackgroundImageSection from "./components/background-image-section-type"
import SeventhSection from "./components/seventh-section-type"
import NinethSection from "./components/nineth-section-type"
import BlogsSection from "./components/blogs-section-type"
import GetInTouchFormSection from "./components/get-in-touch-form-section-type"

export default function useDetermineSection() {
  return {
    getSection: (
      section: any,
      updateSectionInState: (section: any) => void
    ) => {
      switch (section.typeName) {
        case "CarouselSection":
          return (
            <CarouselSection
              key={section.id}
              interval={section.interval}
              images={section.images}
              id={section.id}
              typeName={section.typeName}
              language={section.language}
              responsiveImages={section.responsiveImages}
              updateCarousel={updateSectionInState}
            />
          )

        case "FirstSection":
          return (
            <FirstSection
              key={section.id}
              title={section.title}
              paragraph={section.paragraph}
              blogUrl={section.blogUrl}
              imageRoute={section.imageRoute}
              id={section.id}
              typeName={section.typeName}
              language={section.language}
              buttonText={section.buttonText}
              updateFirstSection={updateSectionInState}
            />
          )

        case "VideoSection":
          return (
            <VideoSection
              key={section.id}
              videoUrl={section.videoUrl}
              backgroundImageUrl={section.backgroundImageUrl}
              backgroundResponsiveImages={section.backgroundResponsiveImages}
              id={section.id}
              typeName={section.typeName}
              language={section.language}
              updateVideoSection={updateSectionInState}
            />
          )

        case "OfficesSection":
          return (
            <OfficesSection
              key={section.id}
              imagesRoutes={section.imagesRoutes}
              id={section.id}
              typeName={section.typeName}
              language={section.language}
              responsiveImages={section.responsiveImages}
              updateOfficesSection={updateSectionInState}
            />
          )

        case "PurposeSection":
          return (
            <PurposeSection
              key={section.id}
              title={section.title}
              paragraph={section.paragraph}
              imageRoute={section.imageRoute}
              id={section.id}
              typeName={section.typeName}
              language={section.language}
              updatePurposeSection={updateSectionInState}
            />
          )

        case "FourthSection":
          return (
            <FourthSection
              key={section.id}
              title={section.title}
              paragraph={section.paragraph}
              theme={section.theme}
              id={section.id}
              typeName={section.typeName}
              language={section.language}
              updateFourthSection={updateSectionInState}
            />
          )

        case "GalleryOfWorksSection":
          return (
            <WorksSection
              key={section.id}
              categories={section.categories}
              typeName={section.typeName}
              language={section.language}
            />
          )

        case "FifthSection":
          return (
            <FifthSection
              key={section.id}
              firstParagraph={section.firstParagraph}
              secondParagraph={section.secondParagraph}
              thirdParagraph={section.thirdParagraph}
              id={section.id}
              typeName={section.typeName}
              language={section.language}
              updateFifthSection={updateSectionInState}
            />
          )

        case "SixthSection":
          return (
            <SixthSection
              key={section.id}
              bigTitle={section.bigTitle}
              paragraph={section.paragraph}
              imageRoute={section.imageRoute}
              id={section.id}
              typeName={section.typeName}
              language={section.language}
              updateSixthSection={updateSectionInState}
            />
          )

        case "GalleryOfPeopleSection":
          return (
            <PeopleSectionType
              key={section.id}
              id={section.id}
              people={section.people}
              typeName={section.typeName}
              language={section.language}
              updatePeopleSection={updateSectionInState}
            />
          )

        case "BackgroundImageSection":
          return (
            <BackgroundImageSection
              key={section.id}
              id={section.id}
              imageRoute={section.imageRoute}
              typeName={section.typeName}
              language={section.language}
              responsiveImages={section.responsiveImages}
              updateBackgroundImageSection={updateSectionInState}
            />
          )

        case "SeventhSection":
          return (
            <SeventhSection
              key={section.id}
              firstTitle={section.firstTitle}
              secondTitle={section.secondTitle}
              paragraph={section.paragraph}
              id={section.id}
              typeName={section.typeName}
              language={section.language}
              updateSeventhSection={updateSectionInState}
            />
          )

        case "NinethSection":
          return (
            <NinethSection
              key={section.id}
              title={section.title}
              paragraph={section.paragraph}
              id={section.id}
              typeName={section.typeName}
              language={section.language}
              updateNinethSection={updateSectionInState}
            />
          )

        case "BlogsSection":
          return (
            <BlogsSection
              key={section.id}
              mainBlogs={section.mainBlogs}
              secondaryBlogs={section.secondaryBlogs}
              id={section.id}
              typeName={section.typeName}
              language={section.language}
              updateBlogsSection={updateSectionInState}
            />
          )

        case "GetInTouchFormSection":
          return (
            <GetInTouchFormSection
              key={section.id}
              title={section.title}
              paragraph={section.paragraph}
              nameLabel={section.nameLabel}
              emailLabel={section.emailLabel}
              serviceLabel={section.serviceLabel}
              services={section.services}
              buttonLabel={section.buttonLabel}
              acceptEmailCommunicationLabel={section.acceptEmailCommunicationLabel}
              id={section.id}
              typeName={section.typeName}
              language={section.language}
              updateGetInTouchFormSection={updateSectionInState}
            />
          )

        default:
          return null
      }
    }
  }
}
