import { BlogType } from './types';

import { HttpService } from './http-service';

export class BlogsService {
  private httpService: HttpService;
  private static instance: BlogsService;

  constructor() {
    this.httpService = HttpService.getInstance();
  }

  public async getActiveBlogs(page: number, pageSize: number): Promise<Array<BlogType>> {
    const blogs  = await this.httpService.get(`blogs/active?page=${page}&limit=${pageSize}`, null);
    return blogs as Array<BlogType>;
  }

  public async getTotalNumberOfActiveBlogs(): Promise<number> {
    const response: any  = await this.httpService.get('blogs/active/total', null);
    return response.total as number;
  }

  public async getArchivedBlogs(page: number, pageSize: number): Promise<Array<BlogType>> {
    const blogs  = await this.httpService.get(`blogs/archived?page=${page}&limit=${pageSize}`, null);
    return blogs as Array<BlogType>;
  }

  public async getBlog(blogId: string): Promise<BlogType> {
    const blog  = await this.httpService.get(`blogs/${blogId}`, null);
    return blog as BlogType;
  }

  public async updateBlog(blog: BlogType): Promise<BlogType> {
    const updatedBlog = await this.httpService.put(`blogs/${blog.id}`, blog, true);
    return updatedBlog as BlogType;
  }

  public async createBlog(blog: BlogType): Promise<BlogType> {
    const createdBlog = await this.httpService.post('blogs', blog, true);
    return createdBlog as BlogType;
  }

  public static getInstance(): BlogsService {
    if (!BlogsService.instance) {
        BlogsService.instance = new BlogsService();
    }

    return BlogsService.instance;
  }
}

export type {
  BlogType,
};
