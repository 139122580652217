// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__2-0Q5 {\n  min-height: 120vh;\n}\n", "",{"version":3,"sources":["webpack://src/pages/edit-menu-pages/components/pages-list/styles.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB","sourcesContent":[".container {\n  min-height: 120vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__2-0Q5"
};
export default ___CSS_LOADER_EXPORT___;
