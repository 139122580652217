import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { SelectPropsType } from "./types"

import Styles from "./styles.module.css"

export default function Select(props: SelectPropsType) {
  const { optionSelected, selectOption, label, optionsLabels } = props

  const onChange = (event: any) => {
    const { target } = event
    selectOption(Number(target.value))
  }

  return (
    <>
      <Row className="mx-0 w-100">
        <Col className="px-0 d-flex justify-content-start align-items-center w-100 py-2">
          <span className={`${Styles.label}`}>{label}</span>
        </Col>
      </Row>

      <Row className="mx-0 w-100">
        <Col className="px-0 d-flex justify-content-center align-items-center w-100 py-2">
          <select
            className={`${Styles.dropdown} w-100`}
            required
            value={optionSelected}
            onChange={onChange}
          >
            <option value={-1} key="non-selected">
              Seleccione una opción
            </option>

            {optionsLabels.map(
              (optionLabel: string, index: number): Object => (
                <option value={index} key={`position_[${index + 1}]`}>
                  {optionLabel}
                </option>
              )
            )}
          </select>
        </Col>
      </Row>
    </>
  )
}
