import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { AuthenticatedUserType } from "../../services/types"
import { RoleType } from "./types"

import { UsersService } from "../../services/users-service"
import { RootState } from "../root-reducer"
import { UserActions } from "./slice"
import { Roles } from "./roles"

import Spinner from "../../components/spinner"
import Helmet from "../../components/helmet"
import Footer from "../../components/footer"
import SubFooter from "../../components/sub-footer"
import UserInfo from "./components/user-info"
import EditUserModal from "./components/edit-user-modal"
import AddUserModal from "./components/add-user-modal"
import ModalQuestion from "../../components/modal-question"
import PaginationControls from "../../components/pagination-controls"
import Select from "../../components/select"

import Styles from "./styles.module.css"

const usersService = UsersService.getInstance()

export default function UsersPage() {
  const [isEditModalDisplayed, displayEditModal] = useState<boolean>(false)
  const [isAddModalDisplayed, displayAddModal] = useState<boolean>(false)
  const [filterRole, setRoleToFilter] = useState<number>(0)

  const [isQuestionModalDisplayed, displayQuestionModal] =
    useState<boolean>(false)

  const [selectedUser, selectUser] = useState<
    AuthenticatedUserType | undefined
  >(undefined)
  const dispatch = useDispatch()

  const { authenticatedUser } = useSelector((state: RootState) => state.login)

  const {
    fetchingUsers,
    users = [],
    totalNumberOfUsers,
    pageNumber,
    pageSize
  } = useSelector((state: RootState) => state.users)

  const fetchAllData = async () => {
    dispatch(UserActions.startFetchingUsers())

    const retrievedUsers: Array<AuthenticatedUserType> =
      await usersService.getUsers(pageNumber, pageSize, "")

    const filteredUsers: Array<AuthenticatedUserType> = retrievedUsers.filter(
      (user: AuthenticatedUserType) => user.id !== authenticatedUser!.id
    )

    dispatch(UserActions.setUsers(filteredUsers))

    const retrievedTotalNumberOfUsers: number =
      await usersService.getTotalNumberOfUsers()
    dispatch(UserActions.setTotalNumberOfUsers(retrievedTotalNumberOfUsers))

    dispatch(UserActions.finishFetchingUsers())
  }

  const startFetchingTheUsers = async () => {
    dispatch(UserActions.startFetchingUsers())

    const role: string = filterRole > 0 ? Roles[filterRole - 1].name : ""

    const retrievedUsers: Array<AuthenticatedUserType> =
      await usersService.getUsers(pageNumber, pageSize, role)

    const filteredUsers: Array<AuthenticatedUserType> = retrievedUsers.filter(
      (user: AuthenticatedUserType) => user.id !== authenticatedUser!.id
    )

    dispatch(UserActions.setUsers(filteredUsers))

    dispatch(UserActions.finishFetchingUsers())
  }

  useEffect(() => {
    fetchAllData()
  }, [])

  useEffect(() => {
    startFetchingTheUsers()
  }, [pageNumber, filterRole])

  const onDeleteUser = async () => {
    dispatch(UserActions.startFetchingUsers())

    await usersService.deleteUser(selectedUser!.id)
    await startFetchingTheUsers()

    dispatch(UserActions.finishFetchingUsers())
  }

  const previousButton = () => {
    if (pageNumber > 1) {
      dispatch(UserActions.setPageNumber(pageNumber - 1))
    }
  }

  const nextButton = () => {
    if (pageNumber * pageSize < totalNumberOfUsers) {
      dispatch(UserActions.setPageNumber(pageNumber + 1))
    }
  }

  if (fetchingUsers) {
    return (
      <>
        <Helmet title="Ogilvy | Admin - Usuarios" />
        <Spinner />
      </>
    )
  }

  return (
    <div className={`${Styles.container}`}>
      <ModalQuestion
        title={selectedUser ? `Eliminar ${selectedUser.firstName}` : ""}
        question="¿Estas seguro que deseas eliminar este usuario? Una vez realizada esta acción, no se podrá recuperar la información de este usuario"
        onHide={() => {
          displayQuestionModal(false)
          selectUser(undefined)
        }}
        show={isQuestionModalDisplayed}
        onYes={async () => {
          await onDeleteUser()
          displayQuestionModal(false)
          selectUser(undefined)
        }}
      />

      <AddUserModal
        show={isAddModalDisplayed}
        onHide={() => displayAddModal(false)}
        callback={() => {
          startFetchingTheUsers()
          displayAddModal(false)
        }}
      />

      {selectedUser && isEditModalDisplayed && (
        <EditUserModal
          firstName={selectedUser.firstName}
          lastName={selectedUser.lastName}
          publicInformation={[ ...selectedUser.publicInformation ]}
          userId={selectedUser.id}
          role={selectedUser.role}
          isPublic={selectedUser.isPublic}
          phoneNumber={selectedUser.phoneNumber}
          email={selectedUser.email}
          imageRoute={selectedUser.imageRoute}
          show={isEditModalDisplayed}
          onHide={() => displayEditModal(false)}
          callback={() => {
            startFetchingTheUsers()
            displayEditModal(false)
          }}
        />
      )}

      <Helmet title="Ogilvy | Admin - Usuarios" />

      <Row className="mx-0 px-4 my-4">
        <Col className="px-4 d-flex justify-content-start align-items-center">
          <Select
            optionsLabels={[
              "Todos",
              ...Roles.map((role: RoleType) => role.displayName)
            ]}
            optionSelected={filterRole}
            selectOption={setRoleToFilter}
            label="Filtrar por role:"
          />
        </Col>

        <Col className="px-4 d-flex justify-content-end align-items-center">
          <div
            className={`${Styles.addUserContainer} d-flex align-items-center`}
            onClick={() => displayAddModal(true)}
          >
            <img
              className={`${Styles.addUserIcon} mr-2`}
              src="/icons/plus.png"
              alt="plus-icon"
            />
            <span className={Styles.addUserText}>Agregar</span>
          </div>
        </Col>
      </Row>

      {users && users.length > 0 && (
        <PaginationControls
          previousButton={previousButton}
          disablePreviousButton={pageNumber === 1}
          nextButton={nextButton}
          disableNextButton={pageNumber * pageSize >= totalNumberOfUsers}
        />
      )}

      <Row className="mx-0 my-4 py-4">
        {users &&
          users.length > 0 &&
          users.map((user: AuthenticatedUserType) => (
            <Col
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              key={user.id}
              className={`mb-4`}
            >
              <UserInfo
                user={user}
                editUser={() => {
                  selectUser({
                    ...user,
                    publicInformation: [...user.publicInformation]
                  })
                  displayEditModal(true)
                }}
                deleteUser={() => {
                  selectUser(user)
                  displayQuestionModal(true)
                }}
              />
            </Col>
          ))}
      </Row>

      {users && users.length > 0 && (
        <PaginationControls
          previousButton={previousButton}
          disablePreviousButton={pageNumber === 1}
          nextButton={nextButton}
          disableNextButton={pageNumber * pageSize >= totalNumberOfUsers}
        />
      )}

      <SubFooter />

      <Footer />
    </div>
  )
}
