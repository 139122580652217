import { RoleType } from "./types"

export const Roles: Array<RoleType> = [
  {
    displayName: "Administrador",
    name: "ADMIN"
  },
  {
    displayName: "Visitante",
    name: "VISITOR"
  },
  {
    displayName: "Editor de Contenido Público",
    name: "EDITOR"
  },
  {
    displayName: "Recursos Humanos",
    name: "HR"
  },
  {
    displayName: "Autor",
    name: "AUTHOR"
  }
]
