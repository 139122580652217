// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_image__RnOXk {\n  width: 100%;\n}\n\n.styles_city__1KGfm {\n  font-size: 22px;\n  color: rgb(179, 176, 176);\n}\n\n.styles_career__CI95M {\n  font-size: 22px;\n  color: rgb(179, 176, 176);\n}\n\n.styles_address__3RDyP {\n  font-size: 17px;\n  color: rgb(179, 176, 176);\n}\n\n.styles_phoneNumber__2zhEd {\n  font-size: 14px;\n  color: rgb(179, 176, 176);\n}\n\n.styles_email__3p96P {\n  font-size: 14px;\n  color: rgb(179, 176, 176);\n}\n", "",{"version":3,"sources":["webpack://src/components/sub-footer/second/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".image {\n  width: 100%;\n}\n\n.city {\n  font-size: 22px;\n  color: rgb(179, 176, 176);\n}\n\n.career {\n  font-size: 22px;\n  color: rgb(179, 176, 176);\n}\n\n.address {\n  font-size: 17px;\n  color: rgb(179, 176, 176);\n}\n\n.phoneNumber {\n  font-size: 14px;\n  color: rgb(179, 176, 176);\n}\n\n.email {\n  font-size: 14px;\n  color: rgb(179, 176, 176);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "styles_image__RnOXk",
	"city": "styles_city__1KGfm",
	"career": "styles_career__CI95M",
	"address": "styles_address__3RDyP",
	"phoneNumber": "styles_phoneNumber__2zhEd",
	"email": "styles_email__3p96P"
};
export default ___CSS_LOADER_EXPORT___;
