import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { AddPersonModalPropsType } from './types';

import Input from '../input';

import Styles from './styles.module.css';

export default function AddPersonModal(props: AddPersonModalPropsType) {
  const {
    show, onHide, createPerson, branch,
  } = props;

  const [name, setName] = useState<string>('');
  const [isNameValid, setNameValidity] = useState<boolean>(true);

  const [jobTitle, setJobTitle] = useState<string>('');
  const [isJobTitleValid, setJobTitleValidity] = useState<boolean>(true);

  const [email, setEmail] = useState<string>('');
  const [isEmailValid, setEmailValidity] = useState<boolean>(true);

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isPhoneNumberValid, setPhoneNumberValidity] = useState<boolean>(true);

  const [description, setDescription] = useState<string>('');
  const [isDescriptionValid, setDescriptionValidity] = useState<boolean>(true);

  const [imageRoute, setImageRoute] = useState<string>('');
  const [isImageRouteValid, setImageRouteValidity] = useState<boolean>(true);

  const clearForm = () => {
    setName('');
    setJobTitle('');
    setEmail('');
    setPhoneNumber('');
    setDescription('');
    setImageRoute('');

    setNameValidity(true);
    setJobTitleValidity(true);
    setEmailValidity(true);
    setPhoneNumberValidity(true);
    setDescriptionValidity(true);
    setImageRouteValidity(true);
  };

  const determineIfNameIsValid = () => {
    setNameValidity(name.trim() !== '');
  };

  const determineIfJobTitleIsValid = () => {
    setJobTitleValidity(jobTitle.trim() !== '');
  };

  const determineIfEmailIsValid = () => {
    setEmailValidity(email.trim() !== '' && /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email));
  };

  const determineIfPhoneNumberIsValid = () => {
    setPhoneNumberValidity(phoneNumber.trim() !== '' && /^\(?[+](\d{3})\)? (\d{4})[- ](\d{4})$/.test(phoneNumber));
  };

  const determineIfDescriptionIsValid = () => {
    setDescriptionValidity(description.trim() !== '');
  };

  const determineIfImageRouteIsValid = () => {
    setImageRouteValidity(imageRoute.trim() !== '');
  };

  const isFormValid = () => {
    return isNameValid && isJobTitleValid && isEmailValid && isPhoneNumberValid
      && isDescriptionValid && isImageRouteValid && isFormValid;
  };

  const onSave = () => {
    determineIfNameIsValid();
    determineIfJobTitleIsValid();
    determineIfEmailIsValid();
    determineIfPhoneNumberIsValid();
    determineIfDescriptionIsValid();
    determineIfImageRouteIsValid();

    if (!isFormValid()) return;

    createPerson(name, jobTitle, email, phoneNumber, description, imageRoute);
    clearForm();
    onHide();
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="modal-question"
      centered
      show={show}
      onHide={() => {
        clearForm();
        onHide();
      }}
    >
      <Modal.Body>
        <Row className="mx-0 py-3">
          <Col className="d-flex justify-content-start align-items-center">
            <span className={Styles.modalTitle}>{'Agregar una nueva persona '}{branch.toUpperCase()}</span>
          </Col>

          <Col className="d-flex justify-content-end align-items-center">
            <img
              onClick={() => {
                clearForm();
                onHide();
              }}
              className={Styles.closeIcon}
              src="/icons/cancel-gray.png"
              alt="close-icon"
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">  
          <Col className="px-3">
            <Input
              placeholder="Ingresa el nombre"
              label="Nombre"
              value={name}
              setValue={setName}
              isValid={isNameValid}
              onBlur={() => determineIfNameIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mb-4 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa el puesto de trabajo"
              label="Puesto de Trabajo"
              value={jobTitle}
              setValue={setJobTitle}
              isValid={isJobTitleValid}
              onBlur={() => determineIfJobTitleIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa el correo electrónico"
              label="Correo electrónico"
              value={email}
              setValue={setEmail}
              isValid={isEmailValid}
              onBlur={() => determineIfEmailIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa el número telefónico"
              label="Número telefónico"
              value={phoneNumber}
              setValue={setPhoneNumber}
              isValid={isPhoneNumberValid}
              onBlur={() => determineIfPhoneNumberIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa el descripción"
              label="Descripción"
              value={description}
              setValue={setDescription}
              isValid={isDescriptionValid}
              onBlur={() => determineIfDescriptionIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-3">
            <Input
              placeholder="Ingresa la url de la imagen de perfil"
              label="Url de la imagen"
              value={imageRoute}
              setValue={setImageRoute}
              isValid={isImageRouteValid}
              onBlur={() => determineIfImageRouteIsValid()}
            />
          </Col>
        </Row>

        <Row className="mx-0 py-3">
          <Col className="d-flex justify-content-end align-items-center">
            <button
              className={`${Styles.yesButton} mr-3`}
              disabled={false}
              onClick={onSave}
            >
              Guardar
            </button>

            <button
              className={Styles.noButton}
              onClick={() => {
                clearForm();
                onHide();
              }}
            >
              Cancelar
            </button>
          </Col>
        </Row>
      </Modal.Body>

    </Modal>
  );
}
