// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__2GBra {\n  height: calc(100vh);\n  width: 100%;\n}\n\n.styles_image__2E6BS {\n  width: 150%;\n  height: 150%;\n  object-fit: cover;\n}\n\n.styles_defaultImage__ycF5A {\n  opacity: 0.2;\n  width: 25%;\n}\n\n.styles_initialOpacity__2aNCX {\n  opacity: 0;\n  transition: opacity 2s ease;\n}\n\n.styles_finalOpacity__2PGkO {\n  opacity: 1 !important;\n  transition: opacity 2s ease;\n}\n", "",{"version":3,"sources":["webpack://src/pages/category/components/default-subcategory/styles.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;EACrB,2BAA2B;AAC7B","sourcesContent":[".container {\n  height: calc(100vh);\n  width: 100%;\n}\n\n.image {\n  width: 150%;\n  height: 150%;\n  object-fit: cover;\n}\n\n.defaultImage {\n  opacity: 0.2;\n  width: 25%;\n}\n\n.initialOpacity {\n  opacity: 0;\n  transition: opacity 2s ease;\n}\n\n.finalOpacity {\n  opacity: 1 !important;\n  transition: opacity 2s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__2GBra",
	"image": "styles_image__2E6BS",
	"defaultImage": "styles_defaultImage__ycF5A",
	"initialOpacity": "styles_initialOpacity__2aNCX",
	"finalOpacity": "styles_finalOpacity__2PGkO"
};
export default ___CSS_LOADER_EXPORT___;
