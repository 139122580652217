// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_title__14qrT {\n  font-size: 35px;\n  font-family: OgilvySerif_LightItalic;\n  color: rgb(174, 173, 173);\n}\n\n.styles_worksTypeContainer__3_yuG {\n  padding-top: 4em;\n}\n\n.styles_indexBlock__112Jp {\n  width: 40px;\n  height: 40px;\n  background-color: black;\n  color: white;\n  font-family: OgilvySerif_LightItalic;\n}\n\n.styles_addUserContainer__6S-3O {\n  cursor: pointer;\n}\n\n.styles_addUserText__2ykDL {\n  font-size: 20px;\n  font-family: OgilvySans_Regular;\n}\n\n.styles_addUserIcon__3-8h_ {\n  width: 15px;\n  height: 15px;\n}\n\n.styles_trashIcon__-C5gF {\n  width: 25px;\n  height: 25px;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://src/pages/edit-page/components/offices-section-type/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,oCAAoC;EACpC,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,YAAY;EACZ,oCAAoC;AACtC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".title {\n  font-size: 35px;\n  font-family: OgilvySerif_LightItalic;\n  color: rgb(174, 173, 173);\n}\n\n.worksTypeContainer {\n  padding-top: 4em;\n}\n\n.indexBlock {\n  width: 40px;\n  height: 40px;\n  background-color: black;\n  color: white;\n  font-family: OgilvySerif_LightItalic;\n}\n\n.addUserContainer {\n  cursor: pointer;\n}\n\n.addUserText {\n  font-size: 20px;\n  font-family: OgilvySans_Regular;\n}\n\n.addUserIcon {\n  width: 15px;\n  height: 15px;\n}\n\n.trashIcon {\n  width: 25px;\n  height: 25px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "styles_title__14qrT",
	"worksTypeContainer": "styles_worksTypeContainer__3_yuG",
	"indexBlock": "styles_indexBlock__112Jp",
	"addUserContainer": "styles_addUserContainer__6S-3O",
	"addUserText": "styles_addUserText__2ykDL",
	"addUserIcon": "styles_addUserIcon__3-8h_",
	"trashIcon": "styles_trashIcon__-C5gF"
};
export default ___CSS_LOADER_EXPORT___;
