import React, { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { GraphicSupportTypePropsType } from './types';

import Input from '../../../../components/input';

import Styles from './styles.module.css';

export default function GraphicSupportTypeForm(props: GraphicSupportTypePropsType) {
  const [inactiveIconUrl, setNewInactiveIconUrl] = useState(props.graphicSupport.inactiveIconUrl);
  const [activeIconUrl, setNewActiveIconUrl] = useState(props.graphicSupport.activeIconUrl);
  const [firstImage, setNewFirstImage] = useState(props.graphicSupport.firstImage);
  const [secondImage, setNewSecondImage] = useState(props.graphicSupport.secondImage);
  const [thirdImage, setNewThirdImage] = useState(props.graphicSupport.thirdImage);
  const [firstText, setNewFirstText] = useState(props.graphicSupport.firstText);
  const [secondText, setNewSecondText] = useState(props.graphicSupport.secondText);
  const [thirdText, setNewThirdText] = useState(props.graphicSupport.thirdText);

  useDeepCompareEffect(() => {
    setNewInactiveIconUrl(props.graphicSupport.inactiveIconUrl);
    setNewActiveIconUrl(props.graphicSupport.activeIconUrl);
    setNewFirstImage(props.graphicSupport.firstImage);
    setNewSecondImage(props.graphicSupport.secondImage);
    setNewThirdImage(props.graphicSupport.thirdImage);
    setNewFirstText(props.graphicSupport.firstText);
    setNewSecondText(props.graphicSupport.secondText);
    setNewThirdText(props.graphicSupport.thirdText);
  }, [props.graphicSupport]);

  const setInactiveIconUrl = (newInactiveIconUrl: string) => {
    props.updateGraphicSupport({
      ...props.graphicSupport,
      inactiveIconUrl: newInactiveIconUrl,
    });
  };

  const setActiveIconUrl = (newActiveIconUrl: string) => {
    props.updateGraphicSupport({
      ...props.graphicSupport,
      activeIconUrl: newActiveIconUrl,
    });
  };

  const setFirstImage = (newFirstImage: string) => {
    props.updateGraphicSupport({
      ...props.graphicSupport,
      firstImage: newFirstImage,
    });
  };

  const setSecondImage = (newSecondImage: string) => {
    props.updateGraphicSupport({
      ...props.graphicSupport,
      secondImage: newSecondImage,
    });
  };

  const setThirdImage = (newThirdImage: string) => {
    props.updateGraphicSupport({
      ...props.graphicSupport,
      thirdImage: newThirdImage,
    });
  };

  const setFirstText = (newFirstText: string) => {
    props.updateGraphicSupport({
      ...props.graphicSupport,
      firstText: newFirstText,
    });
  };

  const setSecondText = (newSecondText: string) => {
    props.updateGraphicSupport({
      ...props.graphicSupport,
      secondText: newSecondText,
    });
  };

  const setThirdText = (newThirdText: string) => {
    props.updateGraphicSupport({
      ...props.graphicSupport,
      thirdText: newThirdText,
    });
  };

  return (
    <div className={Styles.contentsTypeContainer}>
      <Row className="mx-0 w-100 pb-4">
        <Col className="px-0 pb-4 d-flex justify-content-center align-items-center w-100 py-2">
          <span className={`${Styles.title} pl-4`}>Soporte Gráfico</span>
        </Col>
      </Row>

      <Row className="mx-0 w-100 p-4">

        <Col
          xl={6} lg={6} md={12} sm={12} xs={12}
          className="mb-4 pb-4 px-4"
        >
          <div className="mb-1">
            <Input
              value={inactiveIconUrl}
              setValue={(newInactiveIconUrl: string) => setInactiveIconUrl(newInactiveIconUrl)}
              placeholder="Url del ícono"
              label={`Icono en estado inactivo`}
            />
          </div>
        </Col>

        <Col
          xl={6} lg={6} md={12} sm={12} xs={12}
          className="mb-4 pb-4 px-4"
        >
          <div className="mb-1">
            <Input
              value={activeIconUrl}
              setValue={(newActiveIconUrl: string) => setActiveIconUrl(newActiveIconUrl)}
              placeholder="Url del ícono"
              label={`Icono en estado activo`}
            />
          </div>
        </Col>

        <Col
          xl={6} lg={6} md={12} sm={12} xs={12}
          className="mb-4 pb-4 px-4"
        >
          <div className="mb-1">
            <Input
              value={firstImage}
              setValue={(newFirstImage: string) => setFirstImage(newFirstImage)}
              placeholder="Url de la primera imagen"
              label={`Primera imagen`}
            />
          </div>
        </Col>

        <Col
          xl={6} lg={6} md={12} sm={12} xs={12}
          className="mb-4 pb-4 px-4"
        >
          <div className="mb-1">
            <Input
              value={firstText}
              setValue={(newFirstText: string) => setFirstText(newFirstText)}
              placeholder="Escribe el primer texto"
              label={`Primer texto`}
            />
          </div>
        </Col>

        <Col
          xl={6} lg={6} md={12} sm={12} xs={12}
          className="mb-4 pb-4 px-4"
        >
          <div className="mb-1">
            <Input
              value={secondImage}
              setValue={(newSecondImage: string) => setSecondImage(newSecondImage)}
              placeholder="Url de la segunda imagen"
              label={`Segunda imagen`}
            />
          </div>
        </Col>

        <Col
          xl={6} lg={6} md={12} sm={12} xs={12}
          className="mb-4 pb-4 px-4"
        >
          <div className="mb-1">
            <Input
              value={secondText}
              setValue={(newSecondText: string) => setSecondText(newSecondText)}
              placeholder="Escribe el segundo texto"
              label={`Segundo texto`}
            />
          </div>
        </Col>

        <Col
          xl={6} lg={6} md={12} sm={12} xs={12}
          className="mb-4 pb-4 px-4"
        >
          <div className="mb-1">
            <Input
              value={thirdImage}
              setValue={(newThirdImage: string) => setThirdImage(newThirdImage)}
              placeholder="Url de la tercera imagen"
              label={`Tercera imagen`}
            />
          </div>
        </Col>

        <Col
          xl={6} lg={6} md={12} sm={12} xs={12}
          className="mb-4 pb-4 px-4"
        >
          <div className="mb-1">
            <Input
              value={thirdText}
              setValue={(newThirdText: string) => setThirdText(newThirdText)}
              placeholder="Escribe el tercer texto"
              label={`Tercer texto`}
            />
          </div>
        </Col>

      </Row>
    </div>
  );
}
