import React, { useCallback, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { CarouselSectionPropsType } from './types';

import Input from '../../../../components/input';

import Styles from './styles.module.css';
import useResponsiveImagesEditor from "../responsive-images-editor";

export default function CarouselSection(props: CarouselSectionPropsType) {
  const { id, typeName, language, updateCarousel } = props;

  const [interval, setCarouselInterval] = useState<number>(props.interval);

  const {
    setResponsiveImageWidth,
    setResponsiveImageHeight,
    setResponsiveImagePath,
    setResponsiveImageOrder,
    addNewResponsiveImage,
    removeResponsiveImage,
    updatedResponsiveImages,
    timesResponsiveImagesAreUpdated,
  } = useResponsiveImagesEditor({ responsiveImages: props.responsiveImages  });

  const updateSection = useCallback((): void => {
    updateCarousel({
      interval,
      images: props.images,
      id,
      typeName,
      language,
      responsiveImages: updatedResponsiveImages,
    });
  }, [id, typeName, language, interval, updatedResponsiveImages]);

  useEffect(() => {
    updateSection();
  }, [interval, timesResponsiveImagesAreUpdated]);


  return (
    <div className={Styles.worksTypeContainer}>
      <Row className="mx-0 w-100 pb-4">
        <Col className="px-0 pb-4 d-flex justify-content-center align-items-center w-100 py-2">
          <span className={`${Styles.title} pl-4`}>Carousel</span>
        </Col>
      </Row>

      <Row className="mx-0 w-100 p-4">
        <Col
          xl={6} lg={6} md={12} sm={12} xs={12}
          className="mb-4 pb-4 px-4"
        >
          <div className="mb-1">
            <Input
              value={String(interval)}
              setValue={(newInterval: string) => setCarouselInterval(Number(newInterval))}
              placeholder="Intervalo"
              label={`Intervalo en milisegundos`}
              type="number"
            />
          </div>
        </Col>

        <Col
          xl={6} lg={6} md={12} sm={12} xs={12}
          className="mb-4 pb-4 px-4"
        >
          <div className="mb-1">
            {
              updatedResponsiveImages.map((responsiveImage, index) => (
                <div key={`responsiveImage_${index + 1}`}>
                  <Row>
                    <Col xl={2}>
                      <Input
                        value={responsiveImage.order.toString()}
                        setValue={(newOrder: string) => setResponsiveImageOrder(index, newOrder)}
                        placeholder="Orden"
                        label={`Orden`}
                        type="text"
                      />
                    </Col>

                    <Col xl={8}>
                      <Input
                        value={responsiveImage.image}
                        setValue={(newImagePath: string) => setResponsiveImagePath(index, newImagePath)}
                        placeholder="URL de imagen"
                        label={`URL de imagen`}
                        type="text"
                      />
                    </Col>

                    <Col xl={2} className={`d-flex justify-content-center align-items-center`}>
                      <img
                        className={`${Styles.trashIcon} mr-2`}
                        src="/icons/trash.png"
                        alt="trash-icon"
                        onClick={() => removeResponsiveImage(index)}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={2}></Col>

                    <Col xl={4}>
                      <Input
                        value={responsiveImage.width}
                        setValue={(newWidth: string) => setResponsiveImageWidth(index, newWidth)}
                        placeholder="Ancho"
                        label={`Ancho`}
                        type="text"
                      />
                    </Col>

                    <Col xl={4}>
                      <Input
                        value={responsiveImage.height}
                        setValue={(newHeight: string) => setResponsiveImageHeight(index, newHeight)}
                        placeholder="Largo"
                        label={`Largo`}
                        type="text"
                      />
                    </Col>
                    <Col xl={2}></Col>
                  </Row>
                </div>
              ))
            }

            <div
              className={`${Styles.addUserContainer} d-flex align-items-center mt-4`}
              onClick={() => addNewResponsiveImage()}
            >
              <img
                className={`${Styles.addUserIcon} mr-2`}
                src="/icons/plus.png"
                alt="plus-icon"
              />
              <span className={Styles.addUserText}>Agregar</span>
            </div >
          </div>
        </Col>
      </Row>
    </div>
  );
}
