// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_subcategoryFormContainer__WYI2q {\n}", "",{"version":3,"sources":["webpack://src/pages/edit-category/components/subcategory-form/styles.module.css"],"names":[],"mappings":"AAAA;AACA","sourcesContent":[".subcategoryFormContainer {\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subcategoryFormContainer": "styles_subcategoryFormContainer__WYI2q"
};
export default ___CSS_LOADER_EXPORT___;
