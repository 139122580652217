import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { MenuPropsType } from './types';
import { CategoryType } from '../../services/types';

import EditMenuItem from './edit-menu-item';
import CategoryMenuItem from './category-menu-item';

export default function Menu(props: MenuPropsType) {
  const { categories, selectecCategory, isAtTheTopOfThePage, location } = props;
  const [containerClass] = useState(isAtTheTopOfThePage ? 'smallContainer' : 'bigContainer');

  const getMenuItem = (category: CategoryType, index: number) => {
    switch (location) {
      case 'edit-category':
        return (
          <EditMenuItem
            id={category.id}
            title={category.title}
            description={category.description}
            icon={category.icon}
            color={category.color}
            timer={100 + (index * 100)}
            selectecCategory={selectecCategory}
            route={`${category.id}`}
          />
        );
      
      case 'show-category':
        return (
          <CategoryMenuItem
            id={category.id}
            title={category.title}
            description={category.description}
            icon={category.icon}
            color={category.color}
            timer={100 + (index * 100)}
            selectecCategory={selectecCategory}
            route={`${category.id}`}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Row className={`${containerClass} mx-4 px-4`}>
      {
        categories.map((category: CategoryType, index: number) => (
          <Col
            className={`h-50 p-1`}
            xl={4} lg={6} md={6} sm={12} xs={12}
            key={category.title}
          >
            {getMenuItem(category, index)}
          </Col>
        ))
      }
    </Row>
  );
}
