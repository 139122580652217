import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { GalleryItemDetails_ContentPropsType } from "../types"

import Styles from "./styles.module.css"

export default function Content(props: GalleryItemDetails_ContentPropsType) {
  const { title, subtitle, paragraph } = props

  return (
    <>
      <Row className={`pb-2 h-auto`}>
        <Col>
          <span>{title}</span>
        </Col>
      </Row>

      <Row className={`pb-4 h-auto`}>
        <Col>
          <h2>{subtitle}</h2>
        </Col>
      </Row>

      <Row className={`${Styles.paragraph} scrollbar-1 mt-3`}>
        <Col className="d-flex align-items-center">
          <div
            className={`${Styles.content}`}
            dangerouslySetInnerHTML={{
              __html: paragraph
                .replace(/&lt;/g, " <")
                .replace(/&gt;/g, ">")
                .replace(/&amp;/g, "&")
                .replace(/&nbsp;/g, "\n")
            }}
          />
        </Col>
      </Row>
    </>
  )
}
