// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__SQuDt {\n  /* position: absolute;\n  top: 150px;\n  left: 0;\n  right: 0; */\n}\n", "",{"version":3,"sources":["webpack://src/pages/edit-menu-pages/styles.module.css"],"names":[],"mappings":"AAAA;EACE;;;aAGW;AACb","sourcesContent":[".container {\n  /* position: absolute;\n  top: 150px;\n  left: 0;\n  right: 0; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__SQuDt"
};
export default ___CSS_LOADER_EXPORT___;
