// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__1dA2j {\n  width: 100%;\n  background-color: white;\n  z-index: 3;\n  height: 0;\n  transition: height 0.5s ease;\n  overflow: auto;\n}\n\n.styles_active__39cA7 {\n  height: 600px !important;\n  transition: height 0.5s ease;\n}\n\n.styles_headerContainer__2nYFs {\n  height: 60px;\n}\n\n.styles_contentContainer__JDcsd {\n  height: calc(100% - 60px);\n}\n\n.styles_icon__3pLes {\n  width: 25px;\n  height: 25px;\n  cursor: pointer;\n}\n\n.styles_icon__3pLes:hover {\n  opacity: 1.4;\n}\n\n.styles_paragraph__2X0hE {\n  height: 65%;\n  overflow-y: auto;\n}\n\n.styles_paragraph__2X0hE p {\n  font-size: 12px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/category/components/gallery-works/gallery-item-details/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,uBAAuB;EACvB,UAAU;EACV,SAAS;EACT,4BAA4B;EAC5B,cAAc;AAChB;;AAEA;EACE,wBAAwB;EACxB,4BAA4B;AAC9B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".container {\n  width: 100%;\n  background-color: white;\n  z-index: 3;\n  height: 0;\n  transition: height 0.5s ease;\n  overflow: auto;\n}\n\n.active {\n  height: 600px !important;\n  transition: height 0.5s ease;\n}\n\n.headerContainer {\n  height: 60px;\n}\n\n.contentContainer {\n  height: calc(100% - 60px);\n}\n\n.icon {\n  width: 25px;\n  height: 25px;\n  cursor: pointer;\n}\n\n.icon:hover {\n  opacity: 1.4;\n}\n\n.paragraph {\n  height: 65%;\n  overflow-y: auto;\n}\n\n.paragraph p {\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__1dA2j",
	"active": "styles_active__39cA7",
	"headerContainer": "styles_headerContainer__2nYFs",
	"contentContainer": "styles_contentContainer__JDcsd",
	"icon": "styles_icon__3pLes",
	"paragraph": "styles_paragraph__2X0hE"
};
export default ___CSS_LOADER_EXPORT___;
