import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Section4PropsType } from './types';

import useScrollClasses from '../reactivity-to-general-behaviors/use-scroll-classes';

import Styles from './styles.module.css';

export default function Section4(props: Section4PropsType) {
  const {
    theme, title, paragraph, identifier, imageRoute,
  } = props;

  const [titleClass, setTitleClass] = useState('');
  const [paragraphClass, setParagraphClass] = useState('');

  const callbackBelowViewport = () => {
    setTitleClass('');
    setParagraphClass('');
  };

  const callbackAboveViewport = () => {
    setTitleClass(Styles.displayTitle);
    setParagraphClass(Styles.displayContent);
  };

  useScrollClasses({
    containerId: `section-4-title-${identifier}`,
    callbackBelowViewport,
    callbackAboveViewport,
  });

  return (
    <Row className={`${Styles.container} ${theme === 'dark' ? Styles.dark : Styles.light} mx-0`}>

      <Col xs={0} sm={0} md={1} lg={1} xl={1} />

      <Col xs={12} sm={12} md={5} lg={5} xl={5} className="d-flex flex-column justify-content-center align-items-end pr-4">
        <Row className="mx-0">
          <Col className="px-0">
            <h1 id={`section-4-title-${identifier}`}
              className={`${Styles.title} ${theme === 'dark' ? 'text-white' : Styles.textDark} ${titleClass} text-right`}
            >
              "{title}"
            </h1>
          </Col>
        </Row>
      </Col>

      <Col xs={12} sm={12} md={5} lg={5} xl={5} className="d-flex flex-column justify-content-center align-items-start px-4">
        <Row className="mx-0">
          <Col className="px-0">
            <img className={`${Styles.image} ${paragraphClass}`} src={imageRoute} alt="section-4-symbols" />
          </Col>
        </Row>

        <Row className="mx-0">
          <Col className="px-0">
            <p className={`${Styles.content} ${theme === 'dark' ? 'text-white' : 'text-dark'} ${paragraphClass}`}>
              {paragraph}
            </p>
          </Col>
        </Row>
      </Col>

      <Col xs={0} sm={0} md={1} lg={1} xl={1} />
    </Row>
  )
}
