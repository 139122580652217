import { useEffect, useRef, useState } from 'react';

import Styles from './styles.module.css';

export default function useHeightChangerByScrolling() {
  const shrunkNavbarClassName: string = Styles.containerSmallHeight;
  const expandedNavbarClassName: string = Styles.containerLargeHeight;

  const [containerClassName, setContainerClassName] = useState(expandedNavbarClassName);

  const [isNavbarShrunk, setNavbarAsShrunk] = useState(false);
  const isNavbarShrunkRef = useRef(isNavbarShrunk);

  const [isNavbarExpanded, setNavbarAsExpanded] = useState(true);
  const isNavbarExpandedRef = useRef(isNavbarExpanded);

  const shrinkNavbar = () => {
    setContainerClassName(() => shrunkNavbarClassName);

    setNavbarAsShrunk(() => {
      isNavbarShrunkRef.current = true;
      return true;
    });

    setNavbarAsExpanded(() => {
      isNavbarExpandedRef.current = false;
      return false;
    });
  };

  const expandNavbar = () => {
    setContainerClassName(() => expandedNavbarClassName);

    setNavbarAsShrunk(() => {
      isNavbarShrunkRef.current = false;
      return false;
    });

    setNavbarAsExpanded(() => {
      isNavbarExpandedRef.current = true;
      return true;
    });
  };

  const onScroll = () => {
    if (window.innerHeight < 900 && !isNavbarShrunkRef.current) {
      shrinkNavbar();
      return;
    }

    if (window.innerHeight < 900 && isNavbarShrunkRef.current) {
      return;
    }

    if (window.scrollY > 70 && !isNavbarShrunkRef.current) {
      shrinkNavbar();
    }

    if (window.scrollY <= 70 && !isNavbarExpandedRef.current) {
      expandNavbar();
    }
  };
  
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    
    if (window.innerHeight < 900) {
      shrinkNavbar();
    }

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return {
    classNameForHeight: containerClassName,
    topForRestOfPages: containerClassName === shrunkNavbarClassName ? 90 : 150,
  };
}
