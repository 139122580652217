import { useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { Link } from "react-router-dom"

import useTimeout from "../../components/reactivity-to-general-behaviors/timeout"

import { MenuPageItemPropsType } from "./types"

import Styles from "./styles.module.css"

export default function MenuPage(props: MenuPageItemPropsType) {
  const { title, route } = props
  const [textAnimationClass, setTextAnimationClass] = useState("")

  useTimeout({
    handler: () => setTextAnimationClass(Styles.display),
    time: 200
  })

  return (
    <Row className="mx-0 w-100">
      <Col className="px-0 w-100 h-100 d-flex justify-content-center p-4">
        <Link to={route}>
          <h2 className={`${Styles.title} ${textAnimationClass}`}>{title}</h2>
        </Link>
      </Col>
    </Row>
  )
}
