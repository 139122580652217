/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { useHistory } from "react-router-dom"

import {
  BlogType,
  AuthenticatedUserType
} from "../../services/types"

import Spinner from "../../components/spinner"
import Helmet from "../../components/helmet"
import Footer from "../../components/footer"
import SubFooter from "../../components/sub-footer"
import TopButtons from "../../components/top-buttons"
import { RootState } from "../root-reducer"
import { BlogsService } from "../../services/blogs-service"
import { UsersService } from "../../services/users-service"
import { BlogsActions } from "../blogs/slice"

import Input from "../../components/input"
import TextArea from "../../components/textarea"
import HtmlEditor from "../../components/html-editor"
import Select from "../../components/select"

import Styles from "./styles.module.css"
import useResponsiveImagesEditor from "../edit-page/components/responsive-images-editor";

const blogsService: BlogsService = BlogsService.getInstance()
const usersService: UsersService = UsersService.getInstance()

export default function CreateBlogPage() {
  const { push } = useHistory()
  const dispatch = useDispatch()

  const [authors, setAuthors] = useState<Array<AuthenticatedUserType>>([])

  const {
    setResponsiveImageWidth: setMainResponsiveImageWidth,
    setResponsiveImageHeight: setMainResponsiveImageHeight,
    setResponsiveImagePath: setMainResponsiveImagePath,
    setResponsiveImageOrder: setMainResponsiveImageOrder,
    addNewResponsiveImage: addNewMainResponsiveImage,
    removeResponsiveImage: removeMainResponsiveImage,
    updatedResponsiveImages: updatedMainResponsiveImages,
    timesResponsiveImagesAreUpdated: timesMainResponsiveImagesAreUpdated,
  } = useResponsiveImagesEditor({ responsiveImages: []  });

  const {
    setResponsiveImageWidth: setSmallResponsiveImageWidth,
    setResponsiveImageHeight: setSmallResponsiveImageHeight,
    setResponsiveImagePath: setSmallResponsiveImagePath,
    setResponsiveImageOrder: setSmallResponsiveImageOrder,
    addNewResponsiveImage: addNewSmallResponsiveImage,
    removeResponsiveImage: removeSmallResponsiveImage,
    updatedResponsiveImages: updatedSmallResponsiveImages,
    timesResponsiveImagesAreUpdated: timesSmallResponsiveImagesAreUpdated,
  } = useResponsiveImagesEditor({ responsiveImages: []  });

  const [category, setCategory] = useState<string>("")
  const [isCategoryValid, setCategoryValidity] = useState<boolean>(true)

  const [title, setTitle] = useState<string>("")
  const [isTitleValid, setTitleValidity] = useState<boolean>(true)

  const [authorIndexSelected, selectAuthorIndex] = useState<number>(0)
  const [isAuthorValid, setAuthorValidity] = useState<boolean>(true)

  const [content, setContent] = useState<any>("")
  const [isContentValid, setContentValidity] = useState<boolean>(true)

  const [summary, setSummary] = useState<string>("")
  const [isSumaryValid, setSummaryValidity] = useState<boolean>(true)

  const [sectionTitle, setSectionTitle] = useState<string>('');
  const [isSectionTitleValid, setSectionTitleValidity] = useState<boolean>(true)

  const [sectionParagraph, setSectionParagraph] = useState<string>('');
  const [isSectionParagraphValid, setSectionParagraphValidity] = useState<boolean>(true);

  const { fetchingBlogs } = useSelector((state: RootState) => state.blogs)

  const fetchPeople = async () => {
    try {
      dispatch(BlogsActions.startFetchingBlogs())

      const retrievedPeople: Array<AuthenticatedUserType> | null =
        await usersService.getAuthors()
      setAuthors(retrievedPeople || [])

      dispatch(BlogsActions.finishFetchingBlogs())
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPeople()
  }, [])

  const clearForm = () => {
    setCategory("")
    setTitle("")
    selectAuthorIndex(0)
    setContent("")
    setSummary("")
    setSectionTitle('')
    setSectionParagraph('')

    setCategoryValidity(true)
    setTitleValidity(true)
    setAuthorValidity(true)
    setContentValidity(true)
    setSummaryValidity(true)
    setSectionTitleValidity(true)
    setSectionParagraphValidity(true)
  }

  const determineIfCategoryIsValid = () => {
    setCategoryValidity(category.trim() !== "")
  }

  const determineIfTitleIsValid = () => {
    setTitleValidity(title.trim() !== "")
  }

  const determineIfAuthorIsValid = () => {
    setAuthorValidity(authorIndexSelected >= 0)
  }

  const determineIfContentIsValid = () => {
    setContentValidity(content.trim() !== "")
  }

  const determineIfSummaryIsValid = () => {
    setSummaryValidity(summary.trim() !== "")
  }

  const determineIfSectionTitleIsValid = () => {
    setSectionTitleValidity(sectionTitle.trim() !== "")
  }

  const determineIfSectionParagraphIsValid = () => {
    setSectionParagraphValidity(sectionParagraph.trim() !== "")
  }

  const isFormValid = () =>
    updatedMainResponsiveImages.length > 0 &&
    updatedSmallResponsiveImages.length > 0 &&
    category.trim() !== "" &&
    title.trim() !== "" &&
    authorIndexSelected >= 0 &&
    content.trim() !== "" &&
    summary.trim() !== ""

  const createBlog = async () => {
    try {
      dispatch(BlogsActions.startFetchingBlogs())

      const blogToCreate: BlogType = {
        imageRoute: '',
        smallImageRoute: '',
        category,
        title,
        mainResponsiveImages: updatedMainResponsiveImages,
        smallResponsiveImages: updatedSmallResponsiveImages,
        author: [authors[authorIndexSelected].id as string],
        content,
        summary,
        ninethSectionTitle: sectionTitle,
        ninethSectionParagraph: sectionParagraph,
        id: "",
        isArchived: false,
        createdAt: new Date().toISOString()
      }

      await blogsService.createBlog(blogToCreate)
      clearForm()

      dispatch(BlogsActions.finishFetchingBlogs())
    } catch (error) {
      console.log(error)
    }
  }

  const handleSaveChangesButton = async () => {
    determineIfCategoryIsValid()
    determineIfTitleIsValid()
    determineIfAuthorIsValid()
    determineIfContentIsValid()
    determineIfSummaryIsValid()

    if (!isFormValid()) return

    await createBlog()
  }

  const handleGoingBackButton = (): void => {
    push("/blogs")
  }

  if (fetchingBlogs) {
    return (
      <>
        <Helmet title="Ogilvy | Admin - Crear Blog" />
        <Spinner />
      </>
    )
  }

  return (
    <div className={`${Styles.container}`}>
      <Helmet title="Ogilvy | Admin - Crear Blog" />

      <TopButtons
        handleGoingBackButton={handleGoingBackButton}
        handleSaveChangesButton={handleSaveChangesButton}
        isSaveButtonDisabled={!isFormValid()}
      />

      <div className={`${Styles.formContainer}`}>
        <Row className={`${Styles.titleContainer} mx-4 mb-4 px-4`}>
          <Col className="d-flex align-items-center justify-content-center mt-4">
            <span className={Styles.pageTitle}>Nuevo Blog</span>
          </Col>
        </Row>

        <Row className="w-100 mx-0 px-4 mb-4 pb-4">
          <Col className="px-3">
            <div className="mb-1">
              <span className={Styles.contentLabel}>Imagen Principal</span>
              {
                updatedMainResponsiveImages.map((responsiveImage, index) => (
                  <div key={`responsiveImage_${index + 1}`}>
                    <Row>
                      <Col xl={2}>
                        <Input
                          value={responsiveImage.order.toString()}
                          setValue={(newOrder: string) => setMainResponsiveImageOrder(index, newOrder)}
                          placeholder="Orden"
                          label={`Orden`}
                          type="text"
                        />
                      </Col>

                      <Col xl={8}>
                        <Input
                          value={responsiveImage.image}
                          setValue={(newImagePath: string) => setMainResponsiveImagePath(index, newImagePath)}
                          placeholder="URL de imagen"
                          label={`URL de imagen`}
                          type="text"
                        />
                      </Col>

                      <Col xl={2} className={`d-flex justify-content-center align-items-center`}>
                        <img
                          className={`${Styles.trashIcon} mr-2`}
                          src="/icons/trash.png"
                          alt="trash-icon"
                          onClick={() => removeMainResponsiveImage(index)}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xl={2}></Col>

                      <Col xl={4}>
                        <Input
                          value={responsiveImage.width}
                          setValue={(newWidth: string) => setMainResponsiveImageWidth(index, newWidth)}
                          placeholder="Ancho"
                          label={`Ancho`}
                          type="text"
                        />
                      </Col>

                      <Col xl={4}>
                        <Input
                          value={responsiveImage.height}
                          setValue={(newHeight: string) => setMainResponsiveImageHeight(index, newHeight)}
                          placeholder="Largo"
                          label={`Largo`}
                          type="text"
                        />
                      </Col>
                      <Col xl={2}></Col>
                    </Row>
                  </div>
                ))
              }

              <div
                className={`${Styles.addUserContainer} d-flex align-items-center mt-4`}
                onClick={() => addNewMainResponsiveImage()}
              >
                <img
                  className={`${Styles.addUserIcon} mr-2`}
                  src="/icons/plus.png"
                  alt="plus-icon"
                />
                <span className={Styles.addUserText}>Agregar</span>
              </div >
            </div>
          </Col>
        </Row>

        <Row className="w-100 mx-0 px-4 mb-4 pb-4">
          <Col className="px-3">
            <div className="mb-1">
              <span className={Styles.contentLabel}>Imagen Secundaria</span>
              {
                updatedSmallResponsiveImages.map((responsiveImage, index) => (
                  <div key={`responsiveImage_${index + 1}`}>
                    <Row>
                      <Col xl={2}>
                        <Input
                          value={responsiveImage.order.toString()}
                          setValue={(newOrder: string) => setSmallResponsiveImageOrder(index, newOrder)}
                          placeholder="Orden"
                          label={`Orden`}
                          type="text"
                        />
                      </Col>

                      <Col xl={8}>
                        <Input
                          value={responsiveImage.image}
                          setValue={(newImagePath: string) => setSmallResponsiveImagePath(index, newImagePath)}
                          placeholder="URL de imagen"
                          label={`URL de imagen`}
                          type="text"
                        />
                      </Col>

                      <Col xl={2} className={`d-flex justify-content-center align-items-center`}>
                        <img
                          className={`${Styles.trashIcon} mr-2`}
                          src="/icons/trash.png"
                          alt="trash-icon"
                          onClick={() => removeSmallResponsiveImage(index)}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xl={2}></Col>

                      <Col xl={4}>
                        <Input
                          value={responsiveImage.width}
                          setValue={(newWidth: string) => setSmallResponsiveImageWidth(index, newWidth)}
                          placeholder="Ancho"
                          label={`Ancho`}
                          type="text"
                        />
                      </Col>

                      <Col xl={4}>
                        <Input
                          value={responsiveImage.height}
                          setValue={(newHeight: string) => setSmallResponsiveImageHeight(index, newHeight)}
                          placeholder="Largo"
                          label={`Largo`}
                          type="text"
                        />
                      </Col>
                      <Col xl={2}></Col>
                    </Row>
                  </div>
                ))
              }

              <div
                className={`${Styles.addUserContainer} d-flex align-items-center mt-4`}
                onClick={() => addNewSmallResponsiveImage()}
              >
                <img
                  className={`${Styles.addUserIcon} mr-2`}
                  src="/icons/plus.png"
                  alt="plus-icon"
                />
                <span className={Styles.addUserText}>Agregar</span>
              </div >
            </div>
          </Col>
        </Row>

        <Row className="w-100 mx-0 px-4">
          <Col className="px-3">
            <Input
              placeholder="Ingresa la categoría del blog"
              label="Categoría a la que pertenece el blog"
              value={category}
              setValue={setCategory}
              isValid={isCategoryValid}
              onBlur={() => determineIfCategoryIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0 px-4">
          <Col className="px-3">
            <Input
              placeholder="Ingresa el título del blog"
              label="Título del blog"
              value={title}
              setValue={setTitle}
              isValid={isTitleValid}
              onBlur={() => determineIfTitleIsValid()}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0 px-4">
          <Col className="px-3">
            <Select
              label="Selecciona un autor"
              optionSelected={authorIndexSelected}
              selectOption={(newIndex: number) => selectAuthorIndex(newIndex)}
              optionsLabels={authors.map(
                (author: AuthenticatedUserType): string =>
                  `${author.firstName} ${author.lastName}`
              )}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0 px-4">
          <Col className="px-3">
            <TextArea
              placeholder="Ingresa el resumen corto del contenido del blog"
              label="Resumen corto del contenido del blog"
              value={summary}
              setValue={setSummary}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0 px-4">
          <Col className="px-3">
            <HtmlEditor
              placeholder="Ingresa el contenido en formato HTML"
              label="Contenido"
              value={content}
              setValue={setContent}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0 p-4">
          <Col className="pt-3 d-flex justify-content-start">
            <span className={Styles.contentLabel}>
              Visualizador de Contenido
            </span>
          </Col>
        </Row>

        <Row className="w-100 mx-0 px-4">
          <Col className="px-3">
            <div
              className={Styles.content}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0 px-4">
          <Col className="px-3">
            <Input
              value={sectionTitle}
              setValue={(newTitle: string) => setSectionTitle(newTitle)}
              placeholder="Título"
              label={`Título de la sección 9`}
            />
          </Col>
        </Row>

        <Row className="w-100 mx-0 px-4">
          <Col className="px-3">
            <HtmlEditor
              value={sectionParagraph}
              setValue={(newParagraph: string) => setSectionParagraph(newParagraph)}
              placeholder="Párrafo"
              label={`Párrafo de la sección 9`}
            />
          </Col>
        </Row>
      </div>

      <SubFooter />

      <Footer />
    </div>
  )
}
