import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { LoginActions } from '../../pages/login/slice';
import { RootState } from '../../pages/root-reducer';

import Styles from './styles.module.css';

export default function LanguageButton() {
  const dispatch = useDispatch();
  const { authenticatedUser, language } = useSelector((state: RootState) => state.login);
  const { push } = useHistory();

  if (!authenticatedUser) return null;

  return language === 'english'
    ? (
      <div
        className={`${Styles.languageContainer} mr-4 px-2`}
        onClick={() => {
          dispatch(LoginActions.changeLanguageToSpanish());
          sessionStorage.setItem('language', 'spanish');
          push('/menu');
        }}
      >
        <span>Español</span>
      </div>
    )
    : (
      <div
        className={`${Styles.languageContainer} mr-4 px-2`}
        onClick={() => {
          dispatch(LoginActions.changeLanguageToEnglish());
          sessionStorage.setItem('language', 'english');
          push('/menu');
        }}
      >
        <span>English</span>
      </div>
    );
}
