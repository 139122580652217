import { PageType, PageDetailsType } from './types';

import { HttpService } from './http-service';

export class PagesService {
  private httpService: HttpService;
  private static instance: PagesService;

  constructor() {
    this.httpService = HttpService.getInstance();
  }

  public async getPages(): Promise<Array<PageType>> {
    const pages  = await this.httpService.get('pages', null);
    return pages as Array<PageType>;
  }

  public async getPage(pageId: string): Promise<PageDetailsType> {
    const page  = await this.httpService.get(`pages/${pageId}`, null);
    return page as PageDetailsType;
  }

  public async updatePage(pageId: string, page: PageDetailsType): Promise<PageDetailsType> {
    try {
      const updatedPage  = await this.httpService.put(`pages/${pageId}`, page, true);
      return updatedPage as PageDetailsType;
    } catch (error) {
      throw new Error('No se pudo actualizar la página');
    }
  }

  public static getInstance(): PagesService {
    if (!PagesService.instance) {
        PagesService.instance = new PagesService();
    }

    return PagesService.instance;
  }
}
