// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_icon__dBy2Q {\n  width: 25px;\n  height: 25px;\n  cursor: pointer;\n}\n\n.styles_icon__dBy2Q:hover {\n  opacity: 1.4;\n}\n\n.styles_disabledIcon__1Mkgl {\n  width: 25px;\n  height: 25px;\n  cursor: default;\n  opacity: 0.5;\n}\n", "",{"version":3,"sources":["webpack://src/components/pagination-controls/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,YAAY;AACd","sourcesContent":[".icon {\n  width: 25px;\n  height: 25px;\n  cursor: pointer;\n}\n\n.icon:hover {\n  opacity: 1.4;\n}\n\n.disabledIcon {\n  width: 25px;\n  height: 25px;\n  cursor: default;\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "styles_icon__dBy2Q",
	"disabledIcon": "styles_disabledIcon__1Mkgl"
};
export default ___CSS_LOADER_EXPORT___;
