// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__3JtPA {\n  /* position: absolute;\n  top: 150px;\n  left: 0;\n  right: 0; */\n}\n\n.styles_addUserContainer__1wDOv {\n    cursor: pointer;\n}\n\n.styles_addUserText__1Z3SU {\n    font-size: 20px;\n    font-family: OgilvySans_Regular;\n}\n\n.styles_addUserIcon__2C7r5 {\n    width: 15px;\n    height: 15px;\n}\n\n.styles_trashIcon__1Msp9 {\n    width: 25px;\n    height: 25px;\n    cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://src/pages/edit-page/styles.module.css"],"names":[],"mappings":"AAAA;EACE;;;aAGW;AACb;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,+BAA+B;AACnC;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".container {\n  /* position: absolute;\n  top: 150px;\n  left: 0;\n  right: 0; */\n}\n\n.addUserContainer {\n    cursor: pointer;\n}\n\n.addUserText {\n    font-size: 20px;\n    font-family: OgilvySans_Regular;\n}\n\n.addUserIcon {\n    width: 15px;\n    height: 15px;\n}\n\n.trashIcon {\n    width: 25px;\n    height: 25px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__3JtPA",
	"addUserContainer": "styles_addUserContainer__1wDOv",
	"addUserText": "styles_addUserText__1Z3SU",
	"addUserIcon": "styles_addUserIcon__2C7r5",
	"trashIcon": "styles_trashIcon__1Msp9"
};
export default ___CSS_LOADER_EXPORT___;
