import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector, useDispatch } from 'react-redux';

import {
  CategoriesService,
  CategoryType,
  SubcategoryType,
} from '../../services/categories-service';

import Spinner from '../../components/spinner';
import VideoSection from '../../components/video-section';
import Helmet from '../../components/helmet';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import SubFooter from '../../components/sub-footer';
import { RootState } from '../root-reducer';
import { CategoriesActions } from './slice';

import {
  CategoryMenu,
  CategoryMenuType,
  DefaultSubcategory,
  GridContent,
  GalleryOfWorks,
  GalleryOfPeople,
  GraphicSupport,
} from './components';

import Styles from './styles.module.css';

export default function CategoryPage() {
  const { id } = useParams<{ id: string }>();
  const [category, setCategory] = useState<CategoryType | undefined>(undefined);
  const [selectedSubcategory, selectSubcategory] = useState(-1);
  const { push } = useHistory();

  const dispatch = useDispatch();

  const {
    loadingCategories,
    categories,
  } = useSelector((state: RootState) => state.categories);

  const selectecCategory = (categoryId: string) => {
    dispatch(CategoriesActions.selectCategory(categoryId));
  };

  const fetchCategories = async () => {
    if (categories.length !== 0) {
      return;
    }

    try {
      dispatch(CategoriesActions.startFetching());

      const service: CategoriesService = CategoriesService.getInstance();
      const retrievedCategories: Array<CategoryType> = await service.getCategories('spanish');

      dispatch(CategoriesActions.setCategories(retrievedCategories));
      dispatch(CategoriesActions.finishFetching());
    } catch (error) {
      if (error.message === 'Unauthorized') {
        push('/');
      }
    }
  };

  const fetchCategory = async () => {
    try {
      dispatch(CategoriesActions.startFetching());

      const service: CategoriesService = CategoriesService.getInstance();
      const retrievedCategory: CategoryType = await service.getCategory(id);

      setCategory(retrievedCategory);
      selectSubcategory(-1);
      dispatch(CategoriesActions.finishFetching());
    } catch (error) {
      if (error.message === 'Unauthorized') {
        push('/');
      }
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchCategory();
  }, []);

  useEffect(() => {
    fetchCategory();
  }, [id]);

  if (loadingCategories || !category) {
    return (
      <>
        <Helmet title="Ogilvy - Categoría" />
        <Spinner />
      </>
    );
  }

  const itemsForCategoryMenu: Array<CategoryMenuType> = category ?
    category!.subcategories!
      .filter((item: SubcategoryType) => item.type !== 'graphic-support')
      .map((item: SubcategoryType) => ({
        title: item.title,
        label: item.label,
      }))
    : [];

  const getRightOptionCategoryMenu = (): any => {
    const graphicSupportSubcategoryIndex: number | undefined = category!.subcategories!
      .findIndex((item: SubcategoryType) => item.type === 'graphic-support');

    if (graphicSupportSubcategoryIndex < 0)
      return { isRightOptionAvailable: false };

    return {
      isRightOptionAvailable: true,

      rightOptionActiveIcon: category!.subcategories![graphicSupportSubcategoryIndex]
        .graphicSupport!.activeIconUrl,

      rightOptionInactiveIcon: category!.subcategories![graphicSupportSubcategoryIndex]
        .graphicSupport!.inactiveIconUrl,

      rightOptionHandler: () => {
        selectSubcategory(graphicSupportSubcategoryIndex);
      },
    };
  };

  const getSelectedCategoryComponent = (): any => {
    if (!category) {
      return null;
    }

    const subcategoryInformation: SubcategoryType | undefined = category!.subcategories![selectedSubcategory];

    if (!subcategoryInformation) {
      return (<DefaultSubcategory isAtTheTopOfThePage={false} />);
    }

    const {
      type,
      contents,
      works,
      video,
      people,
      graphicSupport,
    } = subcategoryInformation!;

    switch (type) {
      case 'content-list':
        return (<GridContent contents={contents} />);

      case 'works':
        return (<GalleryOfWorks items={works} />);

      case 'video':
        return (
          <VideoSection
            videoUrl={video.url || ''}
            backgroundImageRoute={video.backgroundImage || ''}
            isAtTheTopOfThePage={false}
          />
        );

      case 'people':
        return (
          <GalleryOfPeople people={people} />
        );

      case 'graphic-support':
        return (
          <GraphicSupport
            firstImage={graphicSupport!.firstImage}
            secondImage={graphicSupport!.secondImage}
            thirdImage={graphicSupport!.thirdImage}
            firstText={graphicSupport!.firstText}
            secondText={graphicSupport!.secondText}
            thirdText={graphicSupport!.thirdText}
          />
        );

      default:
        return (<DefaultSubcategory isAtTheTopOfThePage={false} />);
    }
  };

  return (
    <div className={`${Styles.container}`}>

      <Helmet title={`Ogilvy - ${category?.title || ''}`} />

      {
        category?.video && (
          <VideoSection
            videoUrl={category?.video || ''}
            backgroundImageRoute={category?.backgroundVideoImage || ''}
            isAtTheTopOfThePage
          />
        )
      }

      <CategoryMenu
        items={itemsForCategoryMenu}
        onItemSelected={
          (indexSelented: number) => selectSubcategory(indexSelented)
        }
        selectedItem={selectedSubcategory}
        title={category!.title}
        {...getRightOptionCategoryMenu()}
      />

      <Row className={`mx-0`}>
        <Col className={`px-0`}>
          {getSelectedCategoryComponent()}
        </Col>
      </Row>


      <Menu
        categories={categories}
        selectecCategory={selectecCategory}
        isAtTheTopOfThePage
        location="show-category"
      />

      <SubFooter />

      <Footer />

    </div>
  )
}
