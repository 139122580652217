import React, {
  useEffect, useRef, useState,
} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';

import { MenuItemPropsType } from './types';

import Styles from './styles.module.css';

export default function PageMenuItem(props: MenuItemPropsType) {
  const {
    title, color, timer, id, selectPage,
  } = props;

  const [containerClass, setContainerClass] = useState('');
  const containerClassRef = useRef(containerClass);
  const { push } = useHistory();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setContainerClass(() => {
        containerClassRef.current = Styles.endAnimation;
        return Styles.endAnimation;
      });
    }, timer);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onClickHandler = () => {
    selectPage(id);
    push(`editar-contenido-publico/${id}`);
  };

  return (
    <Row className={`w-100 h-100 mx-0`}>
      <Col
        className={`${Styles.container} ${Styles.startAnimation} ${containerClass} w-100 h-100 p-4`}
        style={{
          backgroundColor: color,
        }}
        onClick={onClickHandler}
      >

        <Row className={`${Styles.iconContainer}`}>
          <Col className={`h-100 px-2 d-flex justify-content-end`}>
            <img className={Styles.icon} src="images/logo_white.png" alt="logo" />
          </Col>
        </Row>

        <Row className={`${Styles.titleContainer} w-100`}>
          <Col className={`h-100 px-4 d-flex align-items-end`}>
            <span className={Styles.title}>{title}</span>
          </Col>
        </Row>

      </Col>
    </Row>
  );
}
