import React from "react"
import { useSelector, useDispatch } from "react-redux"
import equals from "shallow-equals"
import omitDeep from "omit-deep"

import { PageDetailsType } from "../../services/types"

import { PagesService } from "../../services/pages-service"

import { PagesActions } from "./slice"
import { RootState } from "../root-reducer"

const IgnoredSectionAttributes: Array<string> = ["createdAt", "updatedAt"]

const areSectionsEqual = (section1: any, section2: any): boolean => {
  if (typeof section1 !== typeof section2) return false
  if (typeof section1 !== "object") {
    return section1 === section2
  }

  if (Array.isArray(section1) && Array.isArray(section2))
    return areArraysEqual(section1, section2)

  return areObjectsEqual(section1, section2)
}

const areArraysEqual = (array1: Array<any>, array2: Array<any>): boolean => {
  if (array1.length !== array2.length) return false

  const unequalElement: any | undefined = array1.find(
    (element: any, index: number) => {
      const isEq = areSectionsEqual(element, array2[index])
      return !isEq
    }
  )

  return unequalElement === undefined
}

const areObjectsEqual = (obj1: any, obj2: any): boolean => {
  const keys1: Array<string> = Object.keys(obj1).filter(
    (key: string) => !IgnoredSectionAttributes.includes(key)
  )
  const keys2: Array<string> = Object.keys(obj2).filter(
    (key: string) => !IgnoredSectionAttributes.includes(key)
  )

  if (keys1.length !== keys2.length) return false

  const unequalElement: any | undefined = keys1.find(
    (key: string, index: number) => {
      const isEq = areSectionsEqual(obj1[key], obj2[key])
      return !isEq
    }
  )

  return unequalElement === undefined
}

export default function useEditPageLogic() {
  const dispatch = useDispatch()

  const { pageToEdit } = useSelector((state: RootState) => state.pages)

  const updateSectionInState = (section: any) => {
    const index: number = pageToEdit!.sections!.findIndex(
      (item: any) => item.id === section.id
    )

    const hasSectionChanged: boolean =
      index >= 0 && !areSectionsEqual(pageToEdit!.sections[index], section)
    if (!hasSectionChanged) return

    const newSections: Array<any> = [...pageToEdit!.sections]
    newSections[index] = { ...section }

    const newPageToEdit: any = {
      ...pageToEdit,
      sections: newSections
    }

    dispatch(PagesActions.setPageToEdit(newPageToEdit))
    dispatch(PagesActions.raiseFlagThatPageHasBeenEdited())
  }

  const updatePageGeneralInformationInState = (page: PageDetailsType) => {
    dispatch(
      PagesActions.setPageToEdit({
        ...page,
        backgroundImageUrl: page.backgroundImageUrl
      })
    )
    dispatch(PagesActions.raiseFlagThatPageHasBeenEdited())
  }

  const updatePage = async () => {
    const service: PagesService = PagesService.getInstance()

    dispatch(PagesActions.startEditingPage())

    const updatedPage = await service.updatePage(
      pageToEdit!.id,
      pageToEdit as PageDetailsType
    )
    dispatch(PagesActions.setPageToEdit(updatedPage))

    dispatch(PagesActions.lowerFlagThatPageHasBeenEdited())

    dispatch(PagesActions.finishEditingPage())
  }
  
   const updatePageAndrefresh = async () => {
    const service: PagesService = PagesService.getInstance()

    dispatch(PagesActions.startEditingPage())

    const updatedPage = await service.updatePage(
      pageToEdit!.id,
      pageToEdit as PageDetailsType
    )
    dispatch(PagesActions.setPageToEdit(updatedPage))

    dispatch(PagesActions.lowerFlagThatPageHasBeenEdited())

   dispatch(refreshPage())
    
    
  }
  
  function refreshPage() {
     window.location.reload();
  }

  return {
    updateSectionInState,
    updatePageGeneralInformationInState,
    updatePage,
    updatePageAndrefresh
  }
}
