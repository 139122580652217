import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import FirstPart from './first';
import SecondPart from './second';
import ThirdPart from './third';

import Styles from './styles.module.css';

export default function SubFooter() {
  return (
    <Row className={`${Styles.container} mx-0 w-100 px-4 py-4`}>
      <Col
        xl={4} lg={4} md={6} sm={12} xs={12}
      >
        <FirstPart />
      </Col>

      <Col
        xl={4} lg={4} md={6} sm={12} xs={12}
      >
        <SecondPart />
      </Col>

      <Col
        xl={4} lg={4} md={6} sm={12} xs={12}
      >
        <ThirdPart />
      </Col>
    </Row>
  )
}
