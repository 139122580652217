"use strict";

import React from 'react';

import Styles from './styles.module.css';
import useResponsiveImage from "../../../../components/reactivity-to-general-behaviors/use-responsive-image";
import {ResponsiveImageType} from "../../../../services/types";

interface SlidePropsType {
  responsiveImages: Array<ResponsiveImageType>,
  index: number
}

export default function Slide(props: SlidePropsType) {
  const { responsiveImages, index } = props;

  const { imageRoute } = useResponsiveImage({responsiveImages});

  return (
    <img
      className={Styles.image}
      src={imageRoute}
      alt={`slide_image_${index + 1}`}
      id={`slide_image_${index + 1}`}
      onTouchStart={(): boolean => true}
      onTouchEnd={(): boolean => true}
    />
  );
}
