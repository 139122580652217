import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { GalleryProps, GelleryItemType } from './types';

import GalleryItem from './gallery-item';
import useGalleryItemDetails from './gallery-item-details/';

import Styles from './styles.module.css';

export default function TwoColumnsThreeRows(props: GalleryProps) {
  const { items} = props;

  const {
    showDetails: showDetailsForFirstSelectedItem,
    selectItem: selectFirstItem,
    close: closeFirstItem,
  } = useGalleryItemDetails();

  const {
    showDetails: showDetailsForSecondSelectedItem,
    selectItem: selectSecondItem,
    close: closeSecondItem,
  } = useGalleryItemDetails();

  const {
    showDetails: showDetailsForThirdSelectedItem,
    selectItem: selectThirdItem,
    close: closeThirdItem,
  } = useGalleryItemDetails();

  const firstItems: Array<GelleryItemType> = items.slice(0, 2);
  const secondItems: Array<GelleryItemType> = firstItems.length != items.length ? items.slice(2, 4) : [];
  const thirdItems: Array<GelleryItemType> = secondItems.length != items.length ? items.slice(4, 6) : [];

  const firstRowTimers = [100, 300];
  const secondRowTimers = [300, 500];
  const thirdRowTimers = [500, 700];

  return (
    <>
      {
        firstItems.length > 0 &&
          (
            <Row className={`${Styles.imagesRow} mx-0`}>
              {
                firstItems.map((item: GelleryItemType, index: number): Object => (
                  <GalleryItem
                    item={item}
                    selectItem={() => {
                      selectFirstItem(item);
                      closeSecondItem();
                      closeThirdItem();
                    }}
                    timer={firstRowTimers[index]}
                    key={`item_${index + 1}`}
                  />
                ))
              }
            </Row>
          )
      }
      {showDetailsForFirstSelectedItem()}
      {
        secondItems.length > 0 &&
          (
            <Row className={`${Styles.imagesRow} mx-0`}>
              {
                secondItems.map((item: GelleryItemType, index: number): Object => (
                  <GalleryItem
                    item={item}
                    selectItem={() => {
                      selectSecondItem(item);
                      closeFirstItem();
                      closeThirdItem();
                    }}
                    timer={secondRowTimers[index]}
                    key={`item_${index + 1}`}
                  />
                ))
              }
            </Row>
          )
      }
      {showDetailsForSecondSelectedItem()}
      {/* {
        thirdItems.length > 0 &&
          (
            <Row className={`${Styles.imagesRow} mx-0`}>
              {
                thirdItems.map((item: GelleryItemType, index: number): Object => (
                  <GalleryItem
                    item={item}
                    selectItem={() => {
                      selectThirdItem(item);
                      closeFirstItem();
                      closeSecondItem();
                    }}
                    timer={thirdRowTimers[index]}
                    key={`item_${index + 1}`}
                  />
                ))
              }
            </Row>
          )
      }
      {showDetailsForThirdSelectedItem()} */}

    </>
  )
}
