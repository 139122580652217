// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__2yB83 {\n  width: 100%;\n  background-color: white;\n}\n", "",{"version":3,"sources":["webpack://src/components/video-section/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,uBAAuB;AACzB","sourcesContent":[".container {\n  width: 100%;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__2yB83"
};
export default ___CSS_LOADER_EXPORT___;
