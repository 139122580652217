import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import { SwitchControlPropsType } from './types';

import Styles from './styles.module.css';

export default function SwitchControl(props: SwitchControlPropsType) {
  const {
    id, value, setValue, label,
    isValid = true,
  } = props;

  const [invalidFieldClassName, setInvalidFieldClassName] = useState<string>('');

  const onChange = (event: any) => {
    const { target } = event;
    setValue(target.checked);
  }

  useEffect(() => {
    setInvalidFieldClassName(() => isValid ? '' : Styles.invalid);
  }, [isValid]);

  return (
    <>
      <Row className="mx-0 w-100">
        <Col className="px-0 d-flex justify-content-start align-items-center w-100 py-2">
          <Form.Check
            id={id}
            type="switch"
            label={label}
            checked={value}
            onChange={onChange}
            className={`${invalidFieldClassName} ${Styles.input}`}
          />
        </Col>
      </Row>
    </>
  );
}
