import React, { useEffect, useState } from "react"
import Col from "react-bootstrap/Col"

import { WorkEditingFormPropsType } from "./types"

import Input from "../../../../components/input"
import SwitchControl from "../../../../components/switch-control"
import HtmlEditor from "../../../../components/html-editor"

import Styles from "./styles.module.css"
import TextArea from "../../../../components/textarea";
import useResponsiveImagesEditor from "../../../edit-page/components/responsive-images-editor";
import Row from "react-bootstrap/Row";

export default function WorkEditingForm(props: WorkEditingFormPropsType) {
  const [title, setTitle] = useState<string>(props.title)
  const [subtitle, setSubtitle] = useState<string>(props.subtitle)
  const [paragraph, setParagraph] = useState<string>(props.paragraph)
  const [videoUrl, setVideoUrl] = useState<string>(props.videoUrl)
  const [order, setOrder] = useState<string>(props.order)
  const [isPublic, setIsPublic] = useState<boolean>(props.isPublic)

  const {
    setResponsiveImageWidth,
    setResponsiveImageHeight,
    setResponsiveImagePath,
    setResponsiveImageOrder,
    addNewResponsiveImage,
    removeResponsiveImage,
    updatedResponsiveImages,
    timesResponsiveImagesAreUpdated,
  } = useResponsiveImagesEditor({ responsiveImages: props.mainResponsiveImages  });

  useEffect(() => {
    props.updateWork(props.index, {
      mainResponsiveImages: updatedResponsiveImages,
      title,
      subtitle,
      paragraph,
      videoUrl,
      isPublic,
      order,
      category: props.categoryId
    })
  }, [title, subtitle, paragraph, videoUrl, timesResponsiveImagesAreUpdated, isPublic, order])

  return (
    <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4">
      <div
        className={`${Styles.indexBlock} d-flex align-items-center justify-content-center`}
      >
        {props.index + 1}
      </div>

      <div className="mb-1">
        <Input
          value={title}
          setValue={(newTitle: string) => setTitle(newTitle)}
          placeholder="Título"
          label={`Título del caso`}
        />
      </div>

      <div className="mb-1">
        <Input
          value={subtitle}
          setValue={(newSubtitle: string) => setSubtitle(newSubtitle)}
          placeholder="Subtítulo"
          label={`Subtítulo del caso`}
        />
      </div>

      <div className="mb-1">
        <HtmlEditor
          value={paragraph}
          setValue={(newParagraph: string) => setParagraph(newParagraph)}
          placeholder="Descripción"
          label={`Explicación del logro realizado`}
        />
      </div>


      <div className="my-4">
        <div className={`${Styles.contentLabel} mt-4 py-4`}>Imagen Principal</div>
        {
          updatedResponsiveImages.map((responsiveImage, index) => (
            <div key={`responsiveImage_${index + 1}`}>
              <Row>
                <Col xl={2}>
                  <Input
                    value={responsiveImage.order.toString()}
                    setValue={(newOrder: string) => setResponsiveImageOrder(index, newOrder)}
                    placeholder="Orden"
                    label={`Orden`}
                    type="text"
                  />
                </Col>

                <Col xl={8}>
                  <Input
                    value={responsiveImage.image}
                    setValue={(newImagePath: string) => setResponsiveImagePath(index, newImagePath)}
                    placeholder="URL de imagen"
                    label={`URL de imagen`}
                    type="text"
                  />
                </Col>

                <Col xl={2} className={`d-flex justify-content-center align-items-center`}>
                  <img
                    className={`${Styles.trashIcon} mr-2`}
                    src="/icons/trash.png"
                    alt="trash-icon"
                    onClick={() => removeResponsiveImage(index)}
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={2}></Col>

                <Col xl={4}>
                  <Input
                    value={responsiveImage.width}
                    setValue={(newWidth: string) => setResponsiveImageWidth(index, newWidth)}
                    placeholder="Ancho"
                    label={`Ancho`}
                    type="text"
                  />
                </Col>

                <Col xl={4}>
                  <Input
                    value={responsiveImage.height}
                    setValue={(newHeight: string) => setResponsiveImageHeight(index, newHeight)}
                    placeholder="Largo"
                    label={`Largo`}
                    type="text"
                  />
                </Col>
                <Col xl={2}></Col>
              </Row>
            </div>
          ))
        }

        <div
          className={`${Styles.addUserContainer} d-flex align-items-center mt-4`}
          onClick={() => addNewResponsiveImage()}
        >
          <img
            className={`${Styles.addUserIcon} mr-2`}
            src="/icons/plus.png"
            alt="plus-icon"
          />
          <span className={Styles.addUserText}>Agregar nueva imagen</span>
        </div >
      </div>

      <div className="mb-1">
        <TextArea
          value={videoUrl}
          setValue={(newVideoUrl: string) => setVideoUrl(newVideoUrl)}
          placeholder="Rutas"
          label={`Rutas Multimedia`}
        />
      </div>

      <div className="mb-1">
        <Input
          value={order}
          setValue={(newOrder: string) => setOrder(newOrder)}
          placeholder="Orden"
          label={`¿En qué orden se mostrará el trabajo?`}
        />
      </div>

      <div className="mt-3 mb-1">
        <SwitchControl
          id={props.id}
          value={isPublic}
          setValue={(isPublic: boolean) => setIsPublic(isPublic)}
          label="¿Es público?"
          isValid
        />
      </div>

      <div className="mb-4 d-flex justify-content-end">
        <button
          className={Styles.delete}
          onClick={() => props.deleteWork(props.index)}
        >
          Eliminar
        </button>
      </div>
    </Col>
  )
}
