/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { useSelector } from "react-redux"

import { RootState } from "../root-reducer"

import Spinner from "../../components/spinner"
import Helmet from "../../components/helmet"
import Footer from "../../components/footer"
import SubFooter from "../../components/sub-footer"
import useTimeout from "../../components/reactivity-to-general-behaviors/timeout"
import InformationSection from "./components/information-section"

import Styles from "./styles.module.css"

export default function MyProfilePage() {
  const { authenticatedUser, editingAuthenticatedUser, language } = useSelector(
    (state: RootState) => state.login
  )

  const [imageClassName, setImageClassName] = useState("")

  const [informationClassName, setInformationClassName] = useState("")

  useTimeout({
    handler: () => {
      setImageClassName(Styles.displayImageContainer)
      setInformationClassName(Styles.displayInformationContainer)
    },
    time: 100
  })

  const pageTitle: string =
    language === "english" ? "Ogilvy - My Profile" : "Ogilvy - Mi Perfil"

  if (!authenticatedUser || editingAuthenticatedUser) {
    return (
      <>
        <Helmet title={pageTitle} />
        <Spinner />
      </>
    )
  }

  return (
    <div className={`${Styles.container}`}>
      <Helmet title={pageTitle} />

      <Row className="mx-0 my-4 p-4">
        <Col
          className={`${Styles.imageContainer} ${imageClassName} d-flex justify-content-center align-items-center`}
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
        >
          <img
            className={`${Styles.profilePicure} rounded-circle`}
            src={authenticatedUser.imageRoute}
            alt="profile-picture"
          />
        </Col>

        <Col
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          className={`${Styles.informationContainer} ${informationClassName} d-flex flex-column justify-content-center align-items-start`}
        >
          <InformationSection
            userId={authenticatedUser.id}
            firstName={authenticatedUser.firstName}
            lastName={authenticatedUser.lastName}
            email={authenticatedUser.email}
            publicInformation={[...authenticatedUser.publicInformation]}
            isPublic={authenticatedUser.isPublic}
            phoneNumber={authenticatedUser.phoneNumber}
            imageRoute={authenticatedUser.imageRoute}
            role={authenticatedUser.role}
            currentLanguage={language}
          />
        </Col>
      </Row>

      <SubFooter />

      <Footer />
    </div>
  )
}
