import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { GalleryProps, GelleryItemType } from './types';

import GalleryItem from './gallery-item';
import useGalleryItemDetails from './gallery-item-details/';

import Styles from './styles.module.css';

export default function OneColumnEightRows(props: GalleryProps) {
  const { items} = props;

  const {
    showDetails: showDetailsForFirstSelectedItem,
    selectItem: selectFirstItem,
    close: closeFirstItem,
  } = useGalleryItemDetails();

  const {
    showDetails: showDetailsForSecondSelectedItem,
    selectItem: selectSecondItem,
    close: closeSecondItem,
  } = useGalleryItemDetails();

  const {
    showDetails: showDetailsForThirdSelectedItem,
    selectItem: selectThirdItem,
    close: closeThirdItem,
  } = useGalleryItemDetails();

  const {
    showDetails: showDetailsForFourthSelectedItem,
    selectItem: selectFourthItem,
    close: closeFourthItem,
  } = useGalleryItemDetails();

  const {
    showDetails: showDetailsForFifthSelectedItem,
    selectItem: selectFifthItem,
    close: closeFifthItem,
  } = useGalleryItemDetails();

  const {
    showDetails: showDetailsForSixthSelectedItem,
    selectItem: selectSixthItem,
    close: closeSixthItem,
  } = useGalleryItemDetails();

  const {
    showDetails: showDetailsForSeventhSelectedItem,
    selectItem: selectSeventhItem,
    close: closeSeventhItem,
  } = useGalleryItemDetails();

  const {
    showDetails: showDetailsForEightSelectedItem,
    selectItem: selectEightItem,
    close: closeEightItem,
  } = useGalleryItemDetails();

  const fixedItems: Array<GelleryItemType> = items.slice(0, 8);
  const timerBase = 100;
  const timerMultiplier = 2;

  return (
    <>
      {
        fixedItems.length > 0 &&
          (
            <>
              <Row className={`${Styles.imagesRow} mx-0`}>
                <GalleryItem
                  item={fixedItems[0]}
                  selectItem={() => {
                    selectFirstItem(fixedItems[0]);
                  }}
                  timer={timerBase * (timerMultiplier * 0)}
                />
              </Row>

              {showDetailsForFirstSelectedItem()}
            </>
          )
      }
      {
        fixedItems.length > 1 &&
          (
            <>
              <Row className={`${Styles.imagesRow} mx-0`}>
                <GalleryItem
                  item={fixedItems[1]}
                  selectItem={() => {
                    selectSecondItem(fixedItems[1]);
                  }}
                  timer={timerBase * (timerMultiplier * 1)}
                />
              </Row>

              {showDetailsForSecondSelectedItem()}
            </>
          )
      }
      {
        fixedItems.length > 2 &&
          (
            <>
              <Row className={`${Styles.imagesRow} mx-0`}>
                <GalleryItem
                  item={fixedItems[2]}
                  selectItem={() => {
                    selectThirdItem(fixedItems[2]);
                  }}
                  timer={timerBase * (timerMultiplier * 2)}
                />
              </Row>

              {showDetailsForThirdSelectedItem()}
            </>
          )
      }
      {
        fixedItems.length > 3 &&
          (
            <>
              <Row className={`${Styles.imagesRow} mx-0`}>
                <GalleryItem
                  item={fixedItems[3]}
                  selectItem={() => {
                    selectFourthItem(fixedItems[3]);
                  }}
                  timer={timerBase * (timerMultiplier * 3)}
                />
              </Row>

              {showDetailsForFourthSelectedItem()}
            </>
          )
      }
      {
        fixedItems.length > 4 &&
          (
            <>
              <Row className={`${Styles.imagesRow} mx-0`}>
                <GalleryItem
                  item={fixedItems[4]}
                  selectItem={() => {
                    selectFifthItem(fixedItems[4]);
                  }}
                  timer={timerBase * (timerMultiplier * 4)}
                />
              </Row>

              {showDetailsForFifthSelectedItem()}
            </>
          )
      }
      {
        fixedItems.length > 5 &&
          (
            <>
              <Row className={`${Styles.imagesRow} mx-0`}>
                <GalleryItem
                  item={fixedItems[5]}
                  selectItem={() => {
                    selectSixthItem(fixedItems[5]);
                  }}
                  timer={timerBase * (timerMultiplier * 5)}
                />
              </Row>

              {showDetailsForSixthSelectedItem()}
            </>
          )
      }
      {
        fixedItems.length > 6 &&
          (
            <>
              <Row className={`${Styles.imagesRow} mx-0`}>
                <GalleryItem
                  item={fixedItems[6]}
                  selectItem={() => {
                    selectSeventhItem(fixedItems[6]);
                  }}
                  timer={timerBase * (timerMultiplier * 6)}
                />
              </Row>

              {showDetailsForSeventhSelectedItem()}
            </>
          )
      }
      {
        fixedItems.length > 7 &&
          (
            <>
              <Row className={`${Styles.imagesRow} mx-0`}>
                <GalleryItem
                  item={fixedItems[7]}
                  selectItem={() => {
                    selectEightItem(fixedItems[7]);
                  }}
                  timer={timerBase * (timerMultiplier * 7)}
                />
              </Row>

              {showDetailsForEightSelectedItem()}
            </>
          )
      }
    </>
  )
}
