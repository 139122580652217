import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { CategoryTitlePropsType } from './types';

import Styles from './styles.module.css';

export default function PageTitle(props: CategoryTitlePropsType) {
  const { title } = props;

  return (
    <Row className={`${Styles.titleContainer} mx-4 mb-4 px-4`}>
      <Col xl={2} lg={2} md={1} />

      <Col xl={8} lg={8} md={10} className="d-xl-flex d-lg-flex d-md-flex d-sm-none d-none align-items-center justify-content-center mt-4">
        <span className={Styles.categoryTitle}>{title}</span>
      </Col>

      <Col xl={8} lg={8} md={10} className="d-xl-none d-lg-none d-md-none d-sm-flex d-flex flex-column align-items-center justify-content-center mt-4">
        <span className={Styles.categoryTitle}>{title}</span>
      </Col>

      <Col xl={2} lg={2} md={1} />
    </Row>
  );
}
