import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { ParagraphPropsType } from './types';
import { constructStyle } from './helper';

import Styles from './styles.module.css';

export default function Paragraph(props: ParagraphPropsType) {
  const {
    content,
    horizontalAlignment,
    verticalAlignment,
    textAlignment,
  } = props;

  const style: Object = constructStyle(props);

  return (
    <Row className={`${Styles.container} mx-0`} >
      <Col
        className={`d-flex justify-content-${horizontalAlignment} align-items-${verticalAlignment} px-1`}
      >
        <p className={`scrollbar-2 ${textAlignment}`} style={style}>{content}</p>
      </Col>
    </Row>
  )
};
