/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { isEmailValid } from './email-validator';
import useLogin from './use-login';

import { LoginFormPropsType } from './types';

import Styles from './styles.module.css';

export default function LoginForm(props: LoginFormPropsType) {
  const { backgroundImageRoute, isAtTheTopOfThePage } = props;
  const [containerClass] = useState(isAtTheTopOfThePage ? 'smallContainer' : 'bigContainer');
  const [finalOpacity, setFinalOpacity] = useState('');
  const [emailInputClassName, setEmailInputClassName] = useState('');
  const [passwordInputClassName, setPasswordInputClassName] = useState('');
  const [isSignInButtonDisabled, setSignInButtonDisability] = useState(true);

  const emailValidationHandler = (value: string) => {
    setEmailInputClassName(!isEmailValid(value) ? Styles.invalidEmailInput : '');
    determineIfFormIsValid();
  };

  const passwordValidationHandler = (value: string) => {
    setPasswordInputClassName(value.trim() === '' ? Styles.invalidPasswordInput : '');
    determineIfFormIsValid();
  };

  const {
    email,
    password,
    errorMessage,
    onEmailChange,
    onEmailBlur,
    onPasswordChange,
    onPasswordBlur,
    login,
  } = useLogin({
    emailValidationHandler,
    passwordValidationHandler,
  });

  useEffect(
    () => {
      const timeout = setTimeout(() => {
        setFinalOpacity(Styles.finalOpacity);
      }, 200);

      return () => clearTimeout(timeout);
    },
    [],
  );

  const determineIfFormIsValid = () => {
    setSignInButtonDisability(!isEmailValid(email) || password.trim() === '')
  };

  const onLogin = (event: any) => {
    event.preventDefault();
    login(email, password);
  };

  return (
    <>
      <Row className={`${containerClass} ${Styles.initialOpacity} ${finalOpacity} ${Styles.container} mx-0`}>
        <Col
          className="px-0 d-flex justify-content-center align-items-center"
          xl={7} lg={7} md={5} sm={4} xs={2}
        >
          <img className={Styles.image} src={backgroundImageRoute} alt="login-form-background-image" />
        </Col>

        <Col className={`px-0 d-flex justify-content-center align-items-center`}>

          <Row className={`${Styles.formContainer} ${containerClass} mx-0`}>
            <Col className="d-flex align-items-center w-100 h-100 px-4">

              <form className="w-100 px-3">

                <Row className="mx-0 mb-4">
                  <Col className="d-flex align-items-center w-100 h-100">
                    <input
                      type="email"
                      placeholder="Tú correo electrónico"
                      value={email}
                      onChange={(event) => onEmailChange(event.target.value)}
                      className={emailInputClassName}
                      onBlur={onEmailBlur}
                    />
                  </Col>
                </Row>

                <Row className="mx-0 mb-4">
                  <Col className="d-flex align-items-center w-100 h-100">
                    <input
                      type="password"
                      placeholder="Tú contraseña"
                      value={password}
                      onChange={(event) => onPasswordChange(event.target.value)}
                      className={passwordInputClassName}
                      onBlur={onPasswordBlur}
                    />
                  </Col>
                </Row>

                <Row className="mx-0">
                  <Col className="d-flex align-items-center justify-content-end w-100 h-100">
                    <button
                      className={Styles.button}
                      onClick={(event) => onLogin(event)}
                      disabled={isSignInButtonDisabled}
                    >
                      Ingresar
                    </button>
                  </Col>
                </Row>

                <Row className="mx-0 pt-4">
                  <Col className="d-flex align-items-center justify-content-center w-100 h-100">
                    <span className={Styles.errorMessage}>{errorMessage}</span>
                  </Col>
                </Row>

              </form>

            </Col>
          </Row>

        </Col>
      </Row>

    </>
  );
}
