type EditInformationFormLabelsType = {
  FirstNamePlaceholder: string
  FirstNameLabel: string
  LastNamePlaceholder: string
  LastNameLabel: string
  EmailPlaceholder: string
  EmailLabel: string
  ImageRoutePlaceholder: string
  ImageRouteLabel: string
  PhoneNumberPlaceholder: string
  PhoneNumberLabel: string
  JobTitleInSpanishPlaceholder: string
  JobTitleInSpanishLabel: string
  JobTitleInEnglishPlaceholder: string
  JobTitleInEnglishLabel: string
}

type EditPasswordFormLabelsType = {
  CurrentPasswordPlaceholder: string
  CurrentPasswordLabel: string
  NewPasswordPlaceholder: string
  NewPasswordLabel: string
  NewPasswordAgainPlaceholder: string
  NewPasswordAgainLabel: string
}

type InformationSectionLabelsType = {
  EditInformationForm: {
    spanish: EditInformationFormLabelsType
    english: EditInformationFormLabelsType
  }
  EditPasswordForm: {
    spanish: EditPasswordFormLabelsType
    english: EditPasswordFormLabelsType
  }
  SavePasswordButton: {
    SavePassword: {
      spanish: string
      english: string
    }
    ChangePassword: {
      spanish: string
      english: string
    }
  }
  SaveInfoButton: {
    Save: {
      spanish: string
      english: string
    }
    Edit: {
      spanish: string
      english: string
    }
  }
  CancelButton: {
    spanish: string
    english: string
  }
}

export const InformationSectionLabels: InformationSectionLabelsType = {
  EditInformationForm: {
    spanish: {
      FirstNamePlaceholder: "Ingresa tú primer nombre",
      FirstNameLabel: "Primer Nombre",
      LastNamePlaceholder: "Ingresa tú primer apellido",
      LastNameLabel: "Primer Apellido",
      EmailPlaceholder: "Ingresa tú correo electrónico",
      EmailLabel: "Correo Electrónico",
      PhoneNumberPlaceholder: "Ingresa tú número telefónico",
      PhoneNumberLabel: "Número Telefónico",
      ImageRoutePlaceholder: "Ingresa el URL de la imagen de perfil",
      ImageRouteLabel: "URL de la imagen de perfil",
      JobTitleInSpanishPlaceholder: "Ingresa tú puesto de trabajo",
      JobTitleInSpanishLabel: "Puesto de Trabajo en Español",
      JobTitleInEnglishPlaceholder: "Ingresa tú puesto de trabajo",
      JobTitleInEnglishLabel: "Puesto de Trabajo en Inglés"
    },
    english: {
      FirstNamePlaceholder: "Write your first name",
      FirstNameLabel: "First Name",
      LastNamePlaceholder: "Write your last name",
      LastNameLabel: "Last Name",
      EmailPlaceholder: "Write your email",
      EmailLabel: "Email",
      PhoneNumberPlaceholder: "Write your phone number",
      PhoneNumberLabel: "Phone number",
      ImageRoutePlaceholder: "Write the profile picture URL",
      ImageRouteLabel: "Profile picture URL",
      JobTitleInSpanishPlaceholder: "Write your job title",
      JobTitleInSpanishLabel: "Job Title in Spanish",
      JobTitleInEnglishPlaceholder: "Write your job title",
      JobTitleInEnglishLabel: "Job Title in English"
    }
  },
  EditPasswordForm: {
    spanish: {
      CurrentPasswordPlaceholder: "Actual contraseña",
      CurrentPasswordLabel: "Ingresa tú Actual Contraseña",
      NewPasswordPlaceholder: "Nueva constraseña",
      NewPasswordLabel: "Ingresa la Nueva Constraseña",
      NewPasswordAgainPlaceholder: "Nueva contraseña",
      NewPasswordAgainLabel: "Ingresa Nuevamente la Nueva Contraseña"
    },
    english: {
      CurrentPasswordPlaceholder: "Current password",
      CurrentPasswordLabel: "Write your Current Password",
      NewPasswordPlaceholder: "New password",
      NewPasswordLabel: "Write your New Password",
      NewPasswordAgainPlaceholder: "New Password",
      NewPasswordAgainLabel: "Write your New Password Again"
    }
  },
  SavePasswordButton: {
    SavePassword: {
      spanish: "Guardar nueva contraseña",
      english: "Save new password"
    },
    ChangePassword: {
      spanish: "Cambiar mi contraseña",
      english: "Change my password"
    }
  },
  SaveInfoButton: {
    Save: {
      spanish: "Guardar",
      english: "Save"
    },
    Edit: {
      spanish: "Editar mi información",
      english: "Edit my information"
    }
  },
  CancelButton: {
    spanish: "Cancelar",
    english: "Cancel"
  }
}
