import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { LoginStateType } from "./types"
import { AuthenticatedUserType } from "../../services/types"

const initialState: LoginStateType = {
  loginInProcess: false,
  editingAuthenticatedUser: false,
  authenticatedUser: null,
  language: "spanish"
}

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    startLogin: (state) => ({ ...state, loginInProcess: true }),

    finishLogin: (state) => ({ ...state, loginInProcess: false }),

    startEditingAuthenticatedUser: (state) => ({
      ...state,
      editingAuthenticatedUser: true
    }),

    finishEditingAuthenticatedUser: (state) => ({
      ...state,
      editingAuthenticatedUser: false
    }),

    setAuthenticatedUser: (
      state,
      action: PayloadAction<AuthenticatedUserType>
    ) => ({
      ...state,
      authenticatedUser: action.payload
    }),

    removeAuthenticatedUser: (state) => ({ ...state, authenticatedUser: null }),

    changeLanguageToEnglish: (state) => ({ ...state, language: "english" }),

    changeLanguageToSpanish: (state) => ({ ...state, language: "spanish" })
  }
})

export const LoginActions = loginSlice.actions
