import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

import { HelmetPropsType } from './types';

export default function Helmet(props: HelmetPropsType) {
  return (
    <ReactHelmet>
      <meta charSet="utf-8" />
      <title>{props.title}</title>
    </ReactHelmet>
  )
}
