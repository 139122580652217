/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { DefaultSubcategoryPropsType } from './types';

import Styles from './styles.module.css';

export default function DefaultSubcategory(props: DefaultSubcategoryPropsType) {
  const { imageRoute, isAtTheTopOfThePage } = props;
  const [containerClass] = useState(isAtTheTopOfThePage ? 'smallContainer' : 'bigContainer');
  const [finalOpacity, setFinalOpacity] = useState('');

  useEffect(
    () => {
      const timeout = setTimeout(() => {
        setFinalOpacity(Styles.finalOpacity);
      }, 200);

      return () => clearTimeout(timeout);
    },
    [],
  );


  return (
    <Row className={`${containerClass} ${Styles.initialOpacity} ${finalOpacity} mx-0`}>
      <Col className="px-0 d-flex justify-content-center align-items-center">
        <img
          className={imageRoute ? Styles.image : Styles.defaultImage}
          src={imageRoute || '/images/logo.png'}
          alt="background-image-general"
        />
      </Col>
    </Row>
  );
}
