/* eslint-disable jsx-a11y/img-redundant-alt */
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { BackgroundPropsType } from './types';

import Styles from './styles.module.css';

export default function Background(props: BackgroundPropsType) {
  const { imageRoute } = props;

  return (
    <Row className={`${Styles.container} mx-0`}>
      <Col className="h-100">
        <img className={Styles.image} src={imageRoute} alt="video-section-background-image" />
      </Col>
    </Row>
  );
}
