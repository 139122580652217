// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer_container__a7Ksf {\n  height: 200px;\n  width: 100%;\n}\n\n.footer_copyright__328Gf {\n  font-family: OgilvySans_Regular;\n  color: rgb(162, 160, 160);\n}\n\n.footer_contact__pTC3w {\n  font-family: OgilvySans_Regular;\n  color: rgb(83, 82, 82);\n}\n\n.footer_socialMedia__1h51I a {\n  font-family: OgilvySans_Regular;\n  color: rgb(162, 160, 160);\n  cursor: pointer;\n}\n\n.footer_socialMedia__1h51I a:hover {\n  color: rgb(83, 82, 82);\n  text-decoration: none;\n}\n\n@media screen and (max-width: 1170px) {\n  .footer_copyright__328Gf {\n    font-size: 12px;\n  }\n\n  .footer_contact__pTC3w {\n    font-size: 12px;\n  }\n\n  .footer_socialMedia__1h51I a {\n    font-size: 12px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/footer/footer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,+BAA+B;EAC/B,yBAAyB;AAC3B;;AAEA;EACE,+BAA+B;EAC/B,sBAAsB;AACxB;;AAEA;EACE,+BAA+B;EAC/B,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".container {\n  height: 200px;\n  width: 100%;\n}\n\n.copyright {\n  font-family: OgilvySans_Regular;\n  color: rgb(162, 160, 160);\n}\n\n.contact {\n  font-family: OgilvySans_Regular;\n  color: rgb(83, 82, 82);\n}\n\n.socialMedia a {\n  font-family: OgilvySans_Regular;\n  color: rgb(162, 160, 160);\n  cursor: pointer;\n}\n\n.socialMedia a:hover {\n  color: rgb(83, 82, 82);\n  text-decoration: none;\n}\n\n@media screen and (max-width: 1170px) {\n  .copyright {\n    font-size: 12px;\n  }\n\n  .contact {\n    font-size: 12px;\n  }\n\n  .socialMedia a {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "footer_container__a7Ksf",
	"copyright": "footer_copyright__328Gf",
	"contact": "footer_contact__pTC3w",
	"socialMedia": "footer_socialMedia__1h51I"
};
export default ___CSS_LOADER_EXPORT___;
