export const SECTION_NAMES = {
  CarouselSection: 'Carousel',
  FirstSection: 'Primera Sección',
  VideoSection: 'Sección de video',
  OfficesSection: 'Imágenes de oficinas',
  PurposeSection: 'Sección de propósito',
  FourthSection: 'Cuarta sección',
  GalleryOfWorksSection: 'Lista de Casos de Exitos',
  FifthSection: 'Quinta sección',
  SixthSection: 'Sexta sección',
  GalleryOfPeopleSection: 'Talento Humano',
  BackgroundImageSection: 'Sección de imagen',
  SeventhSection: 'Séptima sección',
  NinethSection: 'Novena sección',
  BlogsSection: 'Sección de Blogs',
  GetInTouchFormSection: 'Formulario para contactarse con nosotros',
};

export const LANGUAGES = {
  spanish: 'Español',
  english: 'Inglés',
};
