import { useEffect } from 'react';

interface UseTimeoutPropsType {
  handler: () => void,
  time: number
}

export default function useTimeout(props: UseTimeoutPropsType) {
  const { handler, time } = props;

  useEffect(() => {
    const timeout = setTimeout(
      () => handler(),
      time,
    );

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return {};
}
