import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { PARAGRAPH } from './test.data';
import Paragraph from '../../paragraph';

import Styles from './styles.module.css';

export default function ThirdPart() {
  return (
    <Row className="mx-0 px-4 h-100">
      <Col className="w-100 d-flex flex-column justify-content-center px-0">

        <Row className="mx-0 px-4">
          <Col className={`${Styles.paragraph} px-0`}>
            <Paragraph
              verticalAlignment="center"
              fontFamily="OgilvySerif-Light"
              fontSize={15}
              lineHeight={2}
              isBold
              content={PARAGRAPH}
              fontColor="rgb(154, 153, 153)"
            />
          </Col>
        </Row>

        <Row className={`${Styles.subscriptionContainer} mx-0 w-100 px-4`}>
          <Col className={`${Styles.subscriptionInput} pl-0`}>
            <input type="email" placeholder="Tú correo electrónico" className="px-3" />
          </Col>

          <Col className={`${Styles.subscribeButton} px-0`}>
            <button>Subscribirse</button>
          </Col>
        </Row>

      </Col>
    </Row>
  )
}
