// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_image__3wYQN {\n  width: 250px;\n  object-fit: cover;\n}\n", "",{"version":3,"sources":["webpack://src/components/sub-footer/first/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".image {\n  width: 250px;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "styles_image__3wYQN"
};
export default ___CSS_LOADER_EXPORT___;
