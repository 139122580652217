// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__j-KzI {\n}\n", "",{"version":3,"sources":["webpack://src/components/sub-footer/styles.module.css"],"names":[],"mappings":"AAAA;AACA","sourcesContent":[".container {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__j-KzI"
};
export default ___CSS_LOADER_EXPORT___;
