// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__dHa_4 {\n  height: 100%;\n  width: 100%;\n}\n\n.styles_image__i3zIM {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.styles_initialOpacity__1tF7B {\n  opacity: 0;\n  transition: opacity 2s ease;\n}\n\n.styles_finalOpacity__3wYqU {\n  opacity: 1 !important;\n  transition: opacity 2s ease;\n}\n\n.styles_textContainer__2YoD9 {\n  position: absolute;\n  z-index: 1;\n  top: 100px;\n}\n\n.styles_text__13Hvk {\n  text-align: right;\n  padding-right: 2em;\n  font-family: OgilvySerif_BoldItalic;\n  font-size: 60px;\n}\n", "",{"version":3,"sources":["webpack://src/components/background-image/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mCAAmC;EACnC,eAAe;AACjB","sourcesContent":[".container {\n  height: 100%;\n  width: 100%;\n}\n\n.image {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.initialOpacity {\n  opacity: 0;\n  transition: opacity 2s ease;\n}\n\n.finalOpacity {\n  opacity: 1 !important;\n  transition: opacity 2s ease;\n}\n\n.textContainer {\n  position: absolute;\n  z-index: 1;\n  top: 100px;\n}\n\n.text {\n  text-align: right;\n  padding-right: 2em;\n  font-family: OgilvySerif_BoldItalic;\n  font-size: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__dHa_4",
	"image": "styles_image__i3zIM",
	"initialOpacity": "styles_initialOpacity__1tF7B",
	"finalOpacity": "styles_finalOpacity__3wYqU",
	"textContainer": "styles_textContainer__2YoD9",
	"text": "styles_text__13Hvk"
};
export default ___CSS_LOADER_EXPORT___;
