import React from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { useSelector } from "react-redux"

import { PeopleType } from "../types"
import { RootState } from "../../../../root-reducer"

import Styles from "./styles.module.css"

export default function PersonItem(props: PeopleType) {
  const { imageRoute, name, publicInformation, email, phoneNumber } = props
  const { language } = useSelector((state: RootState) => state.login)

  const getJobTitle = () => {
    console.log(publicInformation, language)
    return (
      publicInformation.find((item) => item.language.toLowerCase() === language)
        ?.jobTitle || ""
    )
  }

  return (
    <Col xs="auto" className={`${Styles.container} mx-4 px-0`}>
      <Row className="mx-0">
        <Col className="d-flex justify-content-end align-items-center px-0 pt-4">
          <img src={imageRoute} />
        </Col>
      </Row>

      <Row className="mx-0">
        <Col className="d-flex justify-content-end align-items-center px-0 pt-3">
          <h2>{name}</h2>
        </Col>
      </Row>

      <Row className="mx-0">
        <Col className="d-flex justify-content-end align-items-center px-0">
          <h3>{getJobTitle()}</h3>
        </Col>
      </Row>

      <Row className="mx-0">
        <Col className="d-flex justify-content-end align-items-center px-0 pt-2">
          <span>
            {email}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {phoneNumber}
          </span>
        </Col>
      </Row>
    </Col>
  )
}
