// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__18dOR {\n  /* position: absolute;\n  top: 150px;\n  left: 0;\n  right: 0; */\n}\n\n.styles_addUserContainer__38VoN {\n  cursor: pointer;\n}\n\n.styles_addUserText__2cWQD {\n  font-size: 20px;\n  font-family: OgilvySans_Regular;\n}\n\n.styles_addUserIcon__3gwgJ {\n  width: 15px;\n  height: 15px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/users/styles.module.css"],"names":[],"mappings":"AAAA;EACE;;;aAGW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".container {\n  /* position: absolute;\n  top: 150px;\n  left: 0;\n  right: 0; */\n}\n\n.addUserContainer {\n  cursor: pointer;\n}\n\n.addUserText {\n  font-size: 20px;\n  font-family: OgilvySans_Regular;\n}\n\n.addUserIcon {\n  width: 15px;\n  height: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__18dOR",
	"addUserContainer": "styles_addUserContainer__38VoN",
	"addUserText": "styles_addUserText__2cWQD",
	"addUserIcon": "styles_addUserIcon__3gwgJ"
};
export default ___CSS_LOADER_EXPORT___;
