import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import {
  CategoriesService,
  CategoryType
} from "../../services/categories-service"

import Helmet from "../../components/helmet"
import Menu from "../../components/menu"
import Footer from "../../components/footer"
import { CategoriesActions } from "../category/slice"
import { RootState } from "../root-reducer"
import Spinner from "../../components/spinner"

import Styles from "./styles.module.css"

export default function EditMenuPage() {
  const { categories, loadingCategories } = useSelector(
    (state: RootState) => state.categories
  )
  const dispatch = useDispatch()
  const { push } = useHistory()

  const fetchCategories = async () => {
    if (categories.length !== 0) {
      return
    }

    try {
      dispatch(CategoriesActions.startFetching())

      const service: CategoriesService = CategoriesService.getInstance()
      const retrievedCategories: Array<CategoryType> =
        await service.getCategories("spanish")

      dispatch(CategoriesActions.setCategories(retrievedCategories))
      dispatch(CategoriesActions.finishFetching())
    } catch (error) {
      if (error.message === "Unauthorized") {
        push("/")
      }
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  const selectecCategory = (categoryId: string) => {
    dispatch(CategoriesActions.selectCategory(categoryId))
  }

  if (loadingCategories) {
    return <Spinner />
  }

  const categoriesWithTheirRoutes = categories.map((category) => ({
    ...category,
    route: `/editar/${category.id}`
  }))

  return (
    <div className={Styles.container}>
      <Helmet title="Ogilvy | Admin - Menu de Edición" />

      <Menu
        categories={categoriesWithTheirRoutes}
        selectecCategory={selectecCategory}
        isAtTheTopOfThePage
        location="edit-category"
      />

      <Footer />
    </div>
  )
}
