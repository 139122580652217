import React, { useCallback, useEffect, useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { FifthSectionPropsType } from "./types"

import HtmlEditor from "../../../../components/html-editor"

import Styles from "./styles.module.css"

export default function FifthSection(props: FifthSectionPropsType) {
  const { id, typeName, language, updateFifthSection } = props

  const [firstParagraph, setSectionFirstParagraph] = useState<string>(
    props.firstParagraph
  )
  const [secondParagraph, setSectionSecondParagraph] = useState<string>(
    props.secondParagraph
  )
  const [thirdParagraph, setSectionThirdParagraph] = useState<string>(
    props.thirdParagraph
  )

  const updateSection = useCallback((): void => {
    updateFifthSection({
      firstParagraph,
      secondParagraph,
      thirdParagraph,
      id,
      typeName,
      language
    })
  }, [id, typeName, language, firstParagraph, secondParagraph, thirdParagraph])

  useEffect(() => {
    updateSection()
  }, [firstParagraph, secondParagraph, thirdParagraph])

  return (
    <div className={Styles.worksTypeContainer}>
      <Row className="mx-0 w-100 pb-4">
        <Col className="px-0 pb-4 d-flex justify-content-center align-items-center w-100 py-2">
          <span className={`${Styles.title} pl-4`}>Quinta sección</span>
        </Col>
      </Row>

      <Row className="mx-0 w-100 p-4">
        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <HtmlEditor
              value={firstParagraph}
              setValue={(newFirstParagraph: string) =>
                setSectionFirstParagraph(newFirstParagraph)
              }
              placeholder="Párrafo grande"
              label={`Párrafo grande`}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}
