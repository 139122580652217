import React, { useCallback, useEffect, useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { FirstSectionPropsType } from "./types"

import Input from "../../../../components/input"
import HtmlEditor from "../../../../components/html-editor"

import Styles from "./styles.module.css"

export default function FirstSection(props: FirstSectionPropsType) {
  const { id, typeName, language, updateFirstSection } = props

  const [title, setSectionTitle] = useState<string>(props.title)
  const [paragraph, setSectionParagraph] = useState<string>(props.paragraph)
  const [blogUrl, setSectionBlogUrl] = useState<string>(props.blogUrl)
  const [imageRoute, setSectionImageRoute] = useState<string>(props.imageRoute)
  const [buttonText, setSectionButtonText] = useState<string>(props.buttonText)

  const updateSection = useCallback((): void => {
    updateFirstSection({
      title,
      paragraph,
      blogUrl,
      imageRoute,
      id,
      typeName,
      buttonText,
      language
    })
  }, [
    id,
    typeName,
    language,
    title,
    paragraph,
    blogUrl,
    imageRoute,
    buttonText,
    updateFirstSection
  ])

  useEffect(() => {
    updateSection()
  }, [title, paragraph, blogUrl, imageRoute, buttonText])

  return (
    <div className={Styles.worksTypeContainer}>
      <Row className="mx-0 w-100 pb-4">
        <Col className="px-0 pb-4 d-flex justify-content-center align-items-center w-100 py-2">
          <span className={`${Styles.title} pl-4`}>Primera Sección</span>
        </Col>
      </Row>

      <Row className="mx-0 w-100 p-4">
        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <Input
              value={title}
              setValue={(newTitle: string) => setSectionTitle(newTitle)}
              placeholder="Título"
              label={`Título de la sección`}
            />
          </div>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <Input
              value={blogUrl}
              setValue={(newBlogUrl: string) => setSectionBlogUrl(newBlogUrl)}
              placeholder="Url"
              label={`Url de la página de blog`}
            />
          </div>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <Input
              value={buttonText}
              setValue={(newButtonText: string) =>
                setSectionButtonText(newButtonText)
              }
              placeholder="Texto de botón"
              label={`Texto del botón del blog en la sección`}
            />
          </div>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <Input
              value={imageRoute}
              setValue={(newImageRoute: string) =>
                setSectionImageRoute(newImageRoute)
              }
              placeholder="Url"
              label={`Url de la imagen en la sección`}
            />
          </div>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <HtmlEditor
              key="first-section-paragraph"
              value={paragraph}
              setValue={(newParagraph: string) =>
                setSectionParagraph(newParagraph)
              }
              placeholder="Párrafo"
              label={`Párrafo de la sección`}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}
