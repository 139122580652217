import CategoryMenu from './category-menu';
import { CategoryMenuType } from './category-menu/types';
import GridContent from './grid-content';
import DefaultSubcategory from './default-subcategory';
import GalleryOfWorks from './gallery-works';
import GalleryOfPeople from './gallery-people';
import GraphicSupport from './graphic-support';

export type {
  CategoryMenuType,
};

export {
  CategoryMenu,
  GridContent,
  DefaultSubcategory,
  GalleryOfWorks,
  GalleryOfPeople,
  GraphicSupport,
};
