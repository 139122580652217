import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Styles from './styles.module.css';

interface PlayIconPropsType {
  play: () => void
}

export default function PlayIcon(props: PlayIconPropsType) {
  const { play } = props;

  return (
    <Row className={`${Styles.playIconContainer} mx-0`}>
      <Col className="d-flex justify-content-center align-items-center">
        <img
          className={Styles.playIcon}
          src="/images/play-icon.png"
          onClick={play}
          alt="play-icon"
        />
      </Col>
    </Row>
  );
}
