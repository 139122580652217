import { useState } from 'react';

import Styles from './styles.module.css';

export default function Spinner() {
  const [pipe1Class] = useState(Styles.pipe1_step2);
  const [pipe2Class] = useState(Styles.pipe2_step2);

  return (
    <div className={`${Styles.container}`}>
      <img
        id="pipe1"
        className={`${Styles.spinnerImage} ${Styles.pipe1} ${pipe1Class}`}
        src="/images/spinner/spinner-1.png"
        alt="spinner-1"
      />

      <img
        id="pipe2"
        className={`${Styles.spinnerImage} ${Styles.pipe2} ${pipe2Class}`}
        src="/images/spinner/spinner-2.png"
        alt="spinner-2"
      />
    </div>
  )
}
