import React, { useCallback, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { BlogsSectionPropsType } from './types';

import TextArea from '../../../../components/textarea';

import { HttpService } from '../../../../services/http-service';

import Styles from './styles.module.css';

const httpService: HttpService = HttpService.getInstance();

export default function BlogsSection(props: BlogsSectionPropsType) {
  const { id, typeName, language, updateBlogsSection } = props;

  const constructBlogsUrls = (blogsIds): string => {
    const blogsUrls: Array<string> = blogsIds
      .map((id: string): string => `${httpService.getBaseUrl()}blog/${id}`);

    return blogsUrls.join('\n');
  };

  const [mainBlogs, setSectionMainBlogs] = useState<string>(constructBlogsUrls(props.mainBlogs));
  const [secondaryBlogs, setSectionSecondaryBlogs] = useState<string>(constructBlogsUrls(props.secondaryBlogs));

  const updateSection = useCallback((): void => {
    updateBlogsSection({
      mainBlogs: constructBlogsIds(mainBlogs),
      secondaryBlogs: constructBlogsIds(secondaryBlogs),
      id,
      typeName,
      language,
    });
  }, [id, typeName, language, mainBlogs, secondaryBlogs]);

  useEffect(() => {
    updateSection();
  }, [mainBlogs, secondaryBlogs]);

  const constructBlogsIds = (blogsUrls: string): Array<string> => {
    let blogsUrlsAsArray: Array<string> = blogsUrls.split('\n');
    blogsUrlsAsArray = blogsUrlsAsArray.filter( (blogUrl: string) => blogUrl !== '');
    if (blogsUrlsAsArray.length === 0) return [];

    return blogsUrlsAsArray.map((blogUrl: string) => {
      const urlAsArray = blogUrl.split('/');
      return urlAsArray[urlAsArray.length - 1];
    });
  };

  return (
    <div className={Styles.worksTypeContainer}>
      <Row className="mx-0 w-100 pb-4">
        <Col className="px-0 pb-4 d-flex justify-content-center align-items-center w-100 py-2">
          <span className={`${Styles.title} pl-4`}>Sección de Blogs</span>
        </Col>
      </Row>

      <Row className="mx-0 w-100 p-4">

        <Col
          xl={6} lg={6} md={12} sm={12} xs={12}
          className="mb-4 pb-4 px-4"
        >
          <div className="mb-1">
            <TextArea
              value={mainBlogs}
              setValue={(newMainBlogs: string) => setSectionMainBlogs(newMainBlogs)}
              placeholder="Url de los blogs"
              label={`Urls de los blogs principales (carousel)`}
            />
          </div>
        </Col>

        <Col
          xl={6} lg={6} md={12} sm={12} xs={12}
          className="mb-4 pb-4 px-4"
        >
          <div className="mb-1">
            <TextArea
              value={secondaryBlogs}
              setValue={(newSecondaryBlogs: string) => setSectionSecondaryBlogs(newSecondaryBlogs)}
              placeholder="Urls de los blogs"
              label={`Urls de los blogs secundarios`}
            />
          </div>
        </Col>

      </Row>
    </div>
  );
}
