// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__2Et9P {\n}\n\n.styles_scrollableContainer__2SO2B {\n  overflow: auto;\n}\n\n.styles_readMore__3v3ET img {\n  width: 7px;\n  height: 14px;\n  cursor: pointer;\n}\n\n.styles_readMoreText__1UuCj {\n  font-family: OgilvySans_Regular;\n  font-size: 15px;\n  color: rgb(173, 173, 173);\n  cursor: pointer;\n}\n\n.styles_readMoreText__1UuCj:hover {\n  text-decoration: underline;\n}\n\n.styles_image__2vZV5 {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.styles_deleteIcon__3qx8B {\n  width: 20px;\n  cursor: pointer;\n}\n\n.styles_editIcon__NHDbL {\n  width: 20px;\n  cursor: pointer;\n}\n\n.styles_shareIcon__2U90n {\n  width: 20px;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://src/pages/blogs/components/blog-item/styles.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,+BAA+B;EAC/B,eAAe;EACf,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB","sourcesContent":[".container {\n}\n\n.scrollableContainer {\n  overflow: auto;\n}\n\n.readMore img {\n  width: 7px;\n  height: 14px;\n  cursor: pointer;\n}\n\n.readMoreText {\n  font-family: OgilvySans_Regular;\n  font-size: 15px;\n  color: rgb(173, 173, 173);\n  cursor: pointer;\n}\n\n.readMoreText:hover {\n  text-decoration: underline;\n}\n\n.image {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.deleteIcon {\n  width: 20px;\n  cursor: pointer;\n}\n\n.editIcon {\n  width: 20px;\n  cursor: pointer;\n}\n\n.shareIcon {\n  width: 20px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__2Et9P",
	"scrollableContainer": "styles_scrollableContainer__2SO2B",
	"readMore": "styles_readMore__3v3ET",
	"readMoreText": "styles_readMoreText__1UuCj",
	"image": "styles_image__2vZV5",
	"deleteIcon": "styles_deleteIcon__3qx8B",
	"editIcon": "styles_editIcon__NHDbL",
	"shareIcon": "styles_shareIcon__2U90n"
};
export default ___CSS_LOADER_EXPORT___;
