import React, { useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { PageDetailsType } from "../../services/types"

import { PagesService } from "../../services/pages-service"

import PageTitle from "./components/page-title"
import TopButtons from "../../components/top-buttons"
import ModalQuestion from "../../components/modal-question"
import Spinner from "../../components/spinner"
import Helmet from "../../components/helmet"
import Footer from "../../components/footer"
import SubFooter from "../../components/sub-footer"
import Input from "../../components/input"
import Select from "../../components/select"
import useEditPageLogic from "./use-edit-page-logic"
import useDetermineSection from "./use-determine-section"
import { PagesActions } from "./slice"
import { RootState } from "../root-reducer"
import { SECTION_NAMES, LANGUAGES } from "./helpers"

import Styles from "./styles.module.css"
import useResponsiveImagesEditor from "./components/responsive-images-editor";

export default function EditPublicPage() {
  const { id } = useParams<{ id: string }>()
  const [isModalDisplayed, displayModal] = useState<boolean>(false)
  const [sectionIndexSelected, selectSectionIndex] = useState<number>(0)

  const { push } = useHistory()
  const dispatch = useDispatch()

  const {
    updateSectionInState,
    updatePageGeneralInformationInState,
    updatePage,
    updatePageAndrefresh
  } = useEditPageLogic()

  const { getSection } = useDetermineSection()

  const { loadingPages, editingPage, pageToEdit, hasPageBeenEdited } =
    useSelector((state: RootState) => state.pages);

  var {
    setResponsiveImageWidth,
    setResponsiveImageHeight,
    setResponsiveImagePath,
    setResponsiveImageOrder,
    addNewResponsiveImage,
    removeResponsiveImage,
    updatedResponsiveImages,
    timesResponsiveImagesAreUpdated,
  } = useResponsiveImagesEditor({ responsiveImages: pageToEdit?.backgroundResponsiveImages || []  });

  const fetchPage = async () => {
    try {
      dispatch(PagesActions.startFetching())

      const service: PagesService = PagesService.getInstance()
      const retrievedPage: PageDetailsType = await service.getPage(id)

      dispatch(PagesActions.setPageToEdit(retrievedPage))
      dispatch(PagesActions.lowerFlagThatPageHasBeenEdited())
      dispatch(PagesActions.finishFetching())
    } catch (error) {
      if (error.message === "Unauthorized") {
        push("/")
      }
    }
  }

  useEffect(() => {
    fetchPage()
  }, [])

  const setBackgroundImageUrl = (newBackgroundImageUrl: string) => {
    updatePageGeneralInformationInState({
      ...pageToEdit,
      backgroundImageUrl: newBackgroundImageUrl
    } as PageDetailsType)
  }

  useEffect(() => {
    updatePageGeneralInformationInState({
      ...pageToEdit,
      backgroundResponsiveImages: [...updatedResponsiveImages],
    } as PageDetailsType)
  }, [timesResponsiveImagesAreUpdated]);

  const handleGoingBackButton = () => {
    if (hasPageBeenEdited) {
      displayModal(true)
    } else {
      push("/editar-contenido-publico")
    }
  }

  const handleSaveChangesButton = () => {
    if (hasPageBeenEdited) {
      updatePageAndrefresh();
    }
  }

  if (loadingPages || editingPage) {
    return (
      <>
        <Helmet title="Ogilvy | Admin - Edición de Contenido Público" />
        <Spinner />
      </>
    )
  }
  
  console.log(updatedResponsiveImages);

  return (
    <div className={Styles.container}>
      <Helmet title="Ogilvy | Admin - Edición de Contenido Público" />

      <ModalQuestion
        title="Espera un segundo..."
        question="Se han detectado cambios en la información de las categorías actuales. ¿Deseas guardar los cambios antes de regresar al menu?"
        onHide={() => {
          displayModal(false)
          push("/editar-contenido-publico")
        }}
        show={isModalDisplayed}
        onYes={() => {
          updatePage()
          displayModal(false)
          push("/editar-contenido-publico")
        }}
      />

      <TopButtons
        handleGoingBackButton={handleGoingBackButton}
        handleSaveChangesButton={handleSaveChangesButton}
        isSaveButtonDisabled={!hasPageBeenEdited}
      />

      <PageTitle title={pageToEdit ? pageToEdit!.name : ""} />

      {pageToEdit && (
        <Row className={`${Styles.titleContainer} mx-4 mb-4 px-4`}>
          <Col xl={2} lg={2} md={1} />

          <Col
            xl={8}
            lg={8}
            md={10}
            className="d-flex align-items-center mt-4"
          >
            <div className="mb-1 w-100">
              {
                updatedResponsiveImages.map((responsiveImage, index) => (
                  <div key={`responsiveImage_${index + 1}`}>
                    <Row>
                      <Col xl={2}>
                        <Input
                          value={(responsiveImage.order.toString() )}
                          setValue={(newOrder: string) => setResponsiveImageOrder(index, newOrder)}
                          placeholder="Orden"
                          label={`Orden`}
                          type="text"
                        />
                      </Col>

                      <Col xl={8}>
                        <Input
                          value={responsiveImage.image}
                          setValue={(newImagePath: string) => setResponsiveImagePath(index, newImagePath)}
                          placeholder="URL de imagen"
                          label={`URL de imagen`}
                          type="text"
                        />
                      </Col>

                      <Col xl={2} className={`d-flex justify-content-center align-items-center`}>
                        <img
                          className={`${Styles.trashIcon} mr-2`}
                          src="/icons/trash.png"
                          alt="trash-icon"
                          onClick={() => removeResponsiveImage(index)}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xl={2}></Col>

                      <Col xl={4}>
                        <Input
                          value={responsiveImage.width}
                          setValue={(newWidth: string) => setResponsiveImageWidth(index, newWidth)}
                          placeholder="Ancho"
                          label={`Ancho`}
                          type="text"
                        />
                      </Col>

                      <Col xl={4}>
                        <Input
                          value={responsiveImage.height}
                          setValue={(newHeight: string) => setResponsiveImageHeight(index, newHeight)}
                          placeholder="Largo"
                          label={`Largo`}
                          type="text"
                        />
                      </Col>
                      <Col xl={2}></Col>
                    </Row>
                  </div>
                ))
              }

              <div
                className={`${Styles.addUserContainer} d-flex align-items-center mt-4`}
                onClick={() => addNewResponsiveImage()}
              >
                <img
                  className={`${Styles.addUserIcon} mr-2`}
                  src="/icons/plus.png"
                  alt="plus-icon"
                />
                <span className={Styles.addUserText}>Agregar</span>
              </div >
            </div>
          </Col>

          <Col xl={2} lg={2} md={1} />
        </Row>
      )}

      {pageToEdit && (
        <Row className={`${Styles.titleContainer} mx-4 mb-4 px-4`}>
          <Col xl={2} lg={2} md={1} />

          <Col
            xl={8}
            lg={8}
            md={10}
            className="d-flex flex-column align-items-center justify-content-center mt-4"
          >
            <Select
              optionSelected={sectionIndexSelected}
              selectOption={(newIndex: number) => selectSectionIndex(newIndex)}
              label="Seleccione una sección"
              optionsLabels={pageToEdit!.sections?.map(
                (section: any): string =>
                  `${SECTION_NAMES[section.typeName]} (${
                    LANGUAGES[section.language]
                  })`
              )}
            />
          </Col>

          <Col xl={2} lg={2} md={1} />
        </Row>
      )}

      {pageToEdit &&
        getSection(
          pageToEdit!.sections[sectionIndexSelected],
          updateSectionInState
        )}

      <SubFooter />

      <Footer />
    </div>
  )
}
