import { SubcategoryType, PeopleType } from './types';

import { HttpService } from './http-service';

export class SubcategoriesService {
  private httpService: HttpService;
  private static instance: SubcategoriesService;

  constructor() {
    this.httpService = HttpService.getInstance();
  }

  public async updateSubcategory(subcategory: SubcategoryType): Promise<SubcategoryType> {
    const updatedSubcategory = await this.httpService.put(`subcategories/${subcategory.id}`, subcategory, true);
    return updatedSubcategory as SubcategoryType;
  }

  public async addPersonToSubcategory(subcategoryId: string, person: PeopleType): Promise<PeopleType> {
    const updatedPerson = await this.httpService
      .post(`subcategories/${subcategoryId}/add-person`, person, true);

    return updatedPerson as PeopleType;
  }

  public static getInstance(): SubcategoriesService {
    if (!SubcategoriesService.instance) {
        SubcategoriesService.instance = new SubcategoriesService();
    }

    return SubcategoriesService.instance;
  }
}
