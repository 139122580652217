import React, {
  useEffect, useRef, useState,
} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';

import { MenuItemPropsType } from './types';

import Styles from './styles.module.css';

export default function EditMenuItem(props: MenuItemPropsType) {
  const {
    title, description, color,
    icon, timer, id,
    selectecCategory, route,
  } = props;

  const [containerClass, setContainerClass] = useState('');
  const containerClassRef = useRef(containerClass);
  const { push, replace } = useHistory();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setContainerClass(() => {
        containerClassRef.current = Styles.endAnimation;
        return Styles.endAnimation;
      });
    }, timer);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const extraAttributes: Object = {
    style: {
      WebkitMaskImage: `url(${icon})`,
    },
  };

  const onClickHandler = () => {
    selectecCategory(id);

    if (window.location.pathname.includes('editar/')) {
      push(id);
    } else {
      push(`editar/${id}`);
    }
  };

  return (
    <Row className={`w-100 h-100 mx-0`}>
      <Col
        className={`${Styles.container} ${Styles.startAnimation} ${containerClass} w-100 h-100 p-4`}
        style={{
          backgroundColor: color,
        }}
        onClick={onClickHandler}
      >

        <Row className={`${Styles.iconContainer}`}>
          <Col className={`h-100 px-2`}>
            <div className={`${Styles.icon}`} {...extraAttributes} />
          </Col>
        </Row>

        <Row className={`${Styles.titleContainer} w-100`}>
          <Col className={`h-100 px-4 d-flex align-items-end`}>
            <span className={Styles.title}>{title}</span>
          </Col>
        </Row>

        <Row className={`${Styles.descriptionContainer} w-100`}>
          <Col className={`h-100 px-4`}>
            <span className={Styles.description}>{description}</span>
          </Col>
        </Row>

      </Col>
    </Row>
  );
}
