
import { AuthenticatedUserResponseType } from './types';
import { HttpService } from './http-service';

export class AuthService {
  private httpService: HttpService;
  private static instance: AuthService;

  constructor() {
    this.httpService = HttpService.getInstance();
  }

  public async login(email: string, password: string): Promise<void> {
    try {
      const authenticatedUser: any = await this.httpService.post('auth/login', { email, password }, false);

      if (authenticatedUser.code === 401) {
        throw new Error('Correo electrónico o contraseña incorrecta');
      }

      window.localStorage.setItem('userId', authenticatedUser.user.id);
      window.localStorage.setItem('token', authenticatedUser.tokens.access.token);
    } catch (err) {
      console.log(err);
      if (err.message && err.message === 'Incorrect email or password') {
        throw new Error('Correo electrónico o contraseña incorrecta');
      }

      throw new Error('No ha sido posible verificar su identidad');
    }
  }

  public async resetPassword(newPassword: string) {
    try {
      const response: any = await this.httpService.post(
        'auth/reset-password',
        { password: newPassword },
        false);

      if (response.code !== 200 && response.code !== 201) {
        throw new Error('No pudo realizarse actualización de contraseña exitosamente');
      }

      console.log(response);
      return true;
    } catch (err) {
      throw new Error(err.message);
    }
  }

  public static getInstance(): AuthService {
    if (!AuthService.instance) {
        AuthService.instance = new AuthService();
    }

    return AuthService.instance;
  }
}
