import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import { Switch, Route, useHistory } from "react-router-dom"

import LoginPage from "./pages/login"
import LogoutPage from "./pages/logout"
import MainPage from "./pages/main-menu"
import CategoryPage from "./pages/category"
import EditMenuPage from "./pages/edit-menu"
import EditMenuPagesListPage from "./pages/edit-menu-pages"
import EditCategoryPage from "./pages/edit-category"
import EditPublicPage from "./pages/edit-page"
import MenuOfPages from "./pages/menu-of-pages"
import MyProfilePage from "./pages/my-profile"
import UsersPage from "./pages/users"
import BlogsPage from "./pages/blogs"
import CreateBlogPage from "./pages/create-blog"
import EditBlogPage from "./pages/edit-blog"
import NotFoundPage from "./pages/not-found"
import Navbar from "./components/navbar"
import useHeightChangerByScrolling from "./components/navbar/height-changer-by-scrolling"
import AuthenticationRestriction from "./Auth"

import { LoginActions } from "./pages/login/slice"

function App() {
  const { goBack } = useHistory()
  const { topForRestOfPages } = useHeightChangerByScrolling()
  const dispatch = useDispatch()

  useEffect(() => {
    const languageFromSessionStorage: string | null =
      sessionStorage.getItem("language")

    if (languageFromSessionStorage && languageFromSessionStorage === "spanish")
      dispatch(LoginActions.changeLanguageToSpanish())

    if (languageFromSessionStorage && languageFromSessionStorage === "english")
      dispatch(LoginActions.changeLanguageToEnglish())
  }, [])

  return (
    <div>
      <Navbar />

      <Switch>
        <Route exact path="/">
          <div className="router-container" style={{ top: topForRestOfPages }}>
            <LoginPage />
          </div>
        </Route>

        <Route exact path="/cerrar-sesion">
          <LogoutPage />
        </Route>

        <Route exact path="/menu">
          <AuthenticationRestriction
            rolesAllowed={["ADMIN", "VISITOR", "EDITOR", "HR", "AUTHOR"]}
          >
            <div
              className="router-container"
              style={{ top: topForRestOfPages }}
            >
              <MainPage />
            </div>
          </AuthenticationRestriction>
        </Route>

        <Route exact path="/menu-paginas">
          <AuthenticationRestriction
            rolesAllowed={["ADMIN", "VISITOR", "EDITOR", "HR", "AUTHOR"]}
          >
            <MenuOfPages onClose={() => goBack()} />
          </AuthenticationRestriction>
        </Route>

        <Route exact path="/menu-pages">
          <AuthenticationRestriction
            rolesAllowed={["ADMIN", "VISITOR", "EDITOR", "HR", "AUTHOR"]}
          >
            <MenuOfPages onClose={() => goBack()} />
          </AuthenticationRestriction>
        </Route>

        <Route exact path="/editar">
          <AuthenticationRestriction rolesAllowed={["ADMIN", "EDITOR"]}>
            <div
              className="router-container"
              style={{ top: topForRestOfPages }}
            >
              <EditMenuPage />
            </div>
          </AuthenticationRestriction>
        </Route>

        <Route exact path="/editar-contenido-publico">
          <AuthenticationRestriction rolesAllowed={["ADMIN", "EDITOR"]}>
            <div
              className="router-container"
              style={{ top: topForRestOfPages }}
            >
              <EditMenuPagesListPage />
            </div>
          </AuthenticationRestriction>
        </Route>

        <Route exact path="/editar-contenido-publico/:id">
          <AuthenticationRestriction rolesAllowed={["ADMIN", "EDITOR"]}>
            <div
              className="router-container"
              style={{ top: topForRestOfPages }}
            >
              <EditPublicPage />
            </div>
          </AuthenticationRestriction>
        </Route>

        <Route path="/editar/:id">
          <AuthenticationRestriction rolesAllowed={["ADMIN", "EDITOR"]}>
            <div
              className="router-container"
              style={{ top: topForRestOfPages }}
            >
              <EditCategoryPage />
            </div>
          </AuthenticationRestriction>
        </Route>

        <Route path="/categoria/:id">
          <AuthenticationRestriction
            rolesAllowed={["ADMIN", "VISITOR", "EDITOR"]}
          >
            <div
              className="router-container"
              style={{ top: topForRestOfPages }}
            >
              <CategoryPage />
            </div>
          </AuthenticationRestriction>
        </Route>

        <Route path="/perfil">
          <AuthenticationRestriction
            rolesAllowed={["ADMIN", "VISITOR", "EDITOR", "HR", "AUTHOR"]}
          >
            <div
              className="router-container"
              style={{ top: topForRestOfPages }}
            >
              <MyProfilePage />
            </div>
          </AuthenticationRestriction>
        </Route>

        <Route path="/profile">
          <AuthenticationRestriction
            rolesAllowed={["ADMIN", "VISITOR", "EDITOR", "HR", "AUTHOR"]}
          >
            <div
              className="router-container"
              style={{ top: topForRestOfPages }}
            >
              <MyProfilePage />
            </div>
          </AuthenticationRestriction>
        </Route>

        <Route path="/usuarios">
          <AuthenticationRestriction rolesAllowed={["ADMIN"]}>
            <div
              className="router-container"
              style={{ top: topForRestOfPages }}
            >
              <UsersPage />
            </div>
          </AuthenticationRestriction>
        </Route>

        <Route path="/blogs">
          <AuthenticationRestriction
            rolesAllowed={["ADMIN", "EDITOR", "AUTHOR"]}
          >
            <div
              className="router-container"
              style={{ top: topForRestOfPages }}
            >
              <BlogsPage />
            </div>
          </AuthenticationRestriction>
        </Route>

        <Route exact path="/crear-blog">
          <AuthenticationRestriction
            rolesAllowed={["ADMIN", "EDITOR", "AUTHOR"]}
          >
            <div
              className="router-container"
              style={{ top: topForRestOfPages }}
            >
              <CreateBlogPage />
            </div>
          </AuthenticationRestriction>
        </Route>

        <Route exact path="/editar-blog">
          <AuthenticationRestriction
            rolesAllowed={["ADMIN", "EDITOR", "AUTHOR"]}
          >
            <div
              className="router-container"
              style={{ top: topForRestOfPages }}
            >
              <EditBlogPage />
            </div>
          </AuthenticationRestriction>
        </Route>

        <Route component={NotFoundPage} />
      </Switch>
    </div>
  )
}

export default App
