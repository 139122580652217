import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UsersStateType } from './types';
import { AuthenticatedUserType } from '../../services/types';

const initialState: UsersStateType = {
  fetchingUsers: false,
  users: [],
  totalNumberOfUsers: 0,
  pageNumber: 1,
  pageSize: 10,
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    startFetchingUsers: (state) => ({ ...state, fetchingUsers: true }),

    finishFetchingUsers: (state) => ({ ...state, fetchingUsers: false }),

    setUsers: (state, action: PayloadAction<Array<AuthenticatedUserType>>) => ({
      ...state, users: action.payload,
    }),

    setTotalNumberOfUsers: (state, action: PayloadAction<number>) => ({
      ...state, totalNumberOfUsers: action.payload,
    }),

    setPageNumber: (state, action: PayloadAction<number>) => ({
      ...state, pageNumber: action.payload,
    }),
  },
});

export const UserActions = usersSlice.actions;
