import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { useHistory } from "react-router-dom"

import { BlogType } from "../../services/types"

import Spinner from "../../components/spinner"
import Helmet from "../../components/helmet"
import BlogItem from "./components/blog-item"
import Footer from "../../components/footer"
import SubFooter from "../../components/sub-footer"
import ModalQuestion from "../../components/modal-question"
import PaginationControls from "../../components/pagination-controls"
import { RootState } from "../root-reducer"
import { BlogsActions } from "./slice"
import { BlogsService } from "../../services/blogs-service"
import { HttpService } from "../../services/http-service"

import Styles from "./styles.module.css"

const blogsService: BlogsService = BlogsService.getInstance()
const httpService: HttpService = HttpService.getInstance()

export default function BlogsPage() {
  const { push } = useHistory()
  const dispatch = useDispatch()

  const [isModalDisplayed, displayModal] = useState<boolean>(false)
  const [blogToArchive, setBlogToArchive] = useState<BlogType | undefined>(
    undefined
  )

  const { fetchingBlogs, blogs, pageSize, pageNumber, totalNumberOfBlogs } =
    useSelector((state: RootState) => state.blogs)

  const fetchAllData = async () => {
    dispatch(BlogsActions.startFetchingBlogs())

    const retrievedBlogs: Array<BlogType> = await blogsService.getActiveBlogs(
      pageNumber,
      pageSize
    )
    dispatch(BlogsActions.setBlogs(retrievedBlogs))

    const retrievedTotalNumberOfBlogs: number =
      await blogsService.getTotalNumberOfActiveBlogs()
    dispatch(BlogsActions.setTotalNumberOfBlogs(retrievedTotalNumberOfBlogs))

    dispatch(BlogsActions.finishFetchingBlogs())
  }

  const fetchBlogs = async () => {
    dispatch(BlogsActions.startFetchingBlogs())

    const retrievedBlogs: Array<BlogType> = await blogsService.getActiveBlogs(
      pageNumber,
      pageSize
    )
    dispatch(BlogsActions.setBlogs(retrievedBlogs))
    dispatch(BlogsActions.finishFetchingBlogs())
  }

  const editBlog = (blog: BlogType) => {
    dispatch(BlogsActions.setBlogToEdit(blog))
    push("/editar-blog")
  }

  const archiveBlog = async (blog: BlogType) => {
    dispatch(BlogsActions.startFetchingBlogs())

    await blogsService.updateBlog({ ...blog, isArchived: true })
    setBlogToArchive(undefined)
    await fetchBlogs()
  }

  const previousButton = () => {
    if (pageNumber > 1) {
      dispatch(BlogsActions.setPageNumber(pageNumber - 1))
    }
  }

  const nextButton = () => {
    if (pageNumber * pageSize < totalNumberOfBlogs) {
      dispatch(BlogsActions.setPageNumber(pageNumber + 1))
    }
  }

  useEffect(() => {
    fetchAllData()
  }, [])

  useEffect(() => {
    fetchBlogs()
  }, [pageNumber])

  if (fetchingBlogs) {
    return (
      <>
        <Helmet title="Ogilvy | Admin - Blogs" />
        <Spinner />
      </>
    )
  }

  return (
    <div className={`${Styles.container}`}>
      <Helmet title="Ogilvy | Admin - Blogs" />

      <ModalQuestion
        title="Espera un segundo..."
        question="Estas a punto de eliminar un blog. Su contenido no va a ser borrado por completo pero no volverá a ser público. ¿Estas seguro que deseas continuar con el eliminado?"
        onHide={() => {
          setBlogToArchive(undefined)
          displayModal(false)
        }}
        yesButtonLabel="Sí, deseo borrarlo"
        show={isModalDisplayed}
        onYes={() => {
          archiveBlog(blogToArchive as BlogType)
          displayModal(false)
        }}
      />

      <Row className="mx-0 px-4 my-4">
        <Col className="px-4 d-flex justify-content-end align-items-center">
          <div
            className={`${Styles.addUserContainer} d-flex align-items-center`}
            onClick={() => push("/crear-blog")}
          >
            <img
              className={`${Styles.addUserIcon} mr-2`}
              src="/icons/plus.png"
              alt="plus-icon"
            />
            <span className={Styles.addUserText}>Agregar</span>
          </div>
        </Col>
      </Row>

      {blogs.length > 0 && (
        <PaginationControls
          previousButton={previousButton}
          disablePreviousButton={pageNumber === 1}
          nextButton={nextButton}
          disableNextButton={pageNumber * pageSize >= totalNumberOfBlogs}
        />
      )}

      {blogs.length > 0 && (
        <Row className="mx-0 px-4 my-4">
          {blogs.map((blog: BlogType) => (
            <Col
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className="px-0 d-flex justify-content-end align-items-start"
              key={blog.id}
            >
              <BlogItem
                imageRoute={blog.imageRoute}
                mainResponsiveImages={blog.mainResponsiveImages}
                smallResponsiveImages={blog.smallResponsiveImages}
                category={blog.category}
                title={blog.title}
                summary={blog.summary}
                blogUrl={`${httpService.getBaseUrl()}blog/${blog.id}`}
                editBlog={() => editBlog(blog)}
                archiveBlog={() => {
                  setBlogToArchive(blog)
                  displayModal(true)
                }}
              />
            </Col>
          ))}
        </Row>
      )}

      {blogs.length > 0 && (
        <PaginationControls
          previousButton={previousButton}
          disablePreviousButton={pageNumber === 1}
          nextButton={nextButton}
          disableNextButton={pageNumber * pageSize >= totalNumberOfBlogs}
        />
      )}

      <SubFooter />

      <Footer />
    </div>
  )
}
