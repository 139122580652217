import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { CopyToClipboard } from "react-copy-to-clipboard"

import { BlogItemPropsType } from "./types"

import Paragraph from "../../../../components/paragraph"

import Styles from "./styles.module.css"
import Slide from "./slide";

export default function BlogItem(props: BlogItemPropsType) {
  const {
    category,
    title,
    summary,
    editBlog,
    archiveBlog,
    blogUrl,
    smallResponsiveImages
  } = props

  const [shareIcon, setShareIcon] = useState("/icons/share.png")

  const copiedLink = () => {
    setShareIcon("/icons/check-mark.png")

    const timeout = setTimeout(() => {
      setShareIcon("/icons/share.png")
      clearTimeout(timeout)
    }, 3000)
  }

  return (
    <Row className={`${Styles.container} mx-0`}>
      <Col className="h-100 px-0">
        <Row className={`${Styles.scrollableContainer} scrollbar-2 mx-0`}>
          <Col className="d-flex flex-column justify-content-center align-items-center">
            <Row className="w-100 mx-0 py-3">
              <Col className="d-flex justify-content-end align-items-center">
                <img
                  className={`${Styles.editIcon} mr-3`}
                  src="/icons/pencil.png"
                  alt="edit-icon"
                  onClick={editBlog}
                />

                <img
                  className={`${Styles.deleteIcon} mr-3`}
                  src="/icons/trash.png"
                  alt="trash-icon"
                  onClick={archiveBlog}
                />

                <CopyToClipboard text={blogUrl} onCopy={copiedLink}>
                  <img
                    className={Styles.shareIcon}
                    src={shareIcon}
                    alt="share-icon"
                  />
                </CopyToClipboard>
              </Col>
            </Row>

            <Row className="w-100 mx-0">
              <Col className="d-flex justify-content-start align-items-center">
                <Paragraph
                  fontSize={10}
                  fontFamily="OgilvySans_Regular"
                  verticalAlignment="end"
                  lineHeight={1.6}
                  content={category}
                  fontColor="rgb(173, 173, 173)"
                  isUnderlined
                />
              </Col>
            </Row>

            <Row className="w-100 mx-0">
              <Col className="d-flex justify-content-start align-items-center py-3">
                <Slide responsiveImages={smallResponsiveImages} index={1} />

                {/*<img*/}
                {/*  className={Styles.image}*/}
                {/*  src={imageRoute}*/}
                {/*  alt="image_route"*/}
                {/*/>*/}
              </Col>
            </Row>

            <Row className="w-100 mx-0">
              <Col className="d-flex justify-content-start align-items-center">
                <Paragraph
                  fontSize={30}
                  fontFamily="OgilvySerif_LightItalic"
                  verticalAlignment="end"
                  lineHeight={1.2}
                  content={`"${title}"`}
                />
              </Col>
            </Row>

            <Row className="w-100 mx-0">
              <Col className="d-flex justify-content-start align-items-center">
                <Paragraph
                  fontSize={15}
                  fontFamily="OgilvySans_Regular"
                  verticalAlignment="end"
                  lineHeight={2}
                  content={summary}
                  fontColor="rgb(173, 173, 173)"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
