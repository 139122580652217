// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_label__l9jnd {\n  font-size: 25px;\n  font-family: OgilvySerif_LightItalic;\n  color: rgb(174, 173, 173);\n}\n\n.styles_input__2ut-V {\n  border-top-width: 0;\n  border-left-width: 0;\n  border-right-width: 0;\n  border-bottom-width: 1px;\n  background-color: transparent;\n  font-size: 25px;\n  padding-bottom: 15px;\n}\n\n.styles_input__2ut-V:focus {\n  outline: 0;\n}\n\n.styles_input__2ut-V::placeholder {\n  color: rgb(214, 214, 214);\n}\n\n.styles_input__2ut-V::-ms-input-placeholder {\n  color: rgb(214, 214, 214);\n}\n\n.styles_input__2ut-V:-ms-input-placeholder {\n  color: rgb(214, 214, 214);\n}\n\n.styles_invalid__1v-cY {\n  border: 1px solid red;\n}\n", "",{"version":3,"sources":["webpack://src/components/html-editor/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,oCAAoC;EACpC,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,qBAAqB;EACrB,wBAAwB;EACxB,6BAA6B;EAC7B,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".label {\n  font-size: 25px;\n  font-family: OgilvySerif_LightItalic;\n  color: rgb(174, 173, 173);\n}\n\n.input {\n  border-top-width: 0;\n  border-left-width: 0;\n  border-right-width: 0;\n  border-bottom-width: 1px;\n  background-color: transparent;\n  font-size: 25px;\n  padding-bottom: 15px;\n}\n\n.input:focus {\n  outline: 0;\n}\n\n.input::placeholder {\n  color: rgb(214, 214, 214);\n}\n\n.input::-ms-input-placeholder {\n  color: rgb(214, 214, 214);\n}\n\n.input:-ms-input-placeholder {\n  color: rgb(214, 214, 214);\n}\n\n.invalid {\n  border: 1px solid red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "styles_label__l9jnd",
	"input": "styles_input__2ut-V",
	"invalid": "styles_invalid__1v-cY"
};
export default ___CSS_LOADER_EXPORT___;
