import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ContentForLandscape from './content-for-landscape';
import ContentForPortrait from './content-for-portrait';

import FooterStyles from './footer.module.css';

export interface ContentPropsType {
  copyright: string,
  tguPhoneNumber: string,
  spsPhoneNumber: string,
  email: string,
  youtubeUrl: string,
  facebookUrl: string,
  twitterUrl: string
}

export default function Footer() {
  const footerContent: ContentPropsType = {
    copyright: '@Ogilvy Honduras 2021',
    tguPhoneNumber: 'TGU: +504 2236 6780',
    spsPhoneNumber: 'SPS: +504 2558 9156',
    email: 'infohn@ogilvy.com',
    youtubeUrl: 'https://www.youtube.com/channel/UCyGcEuM-oOZWn-aLwC_jl5w',
    facebookUrl: 'https://www.facebook.com/OgilvyHonduras/',
    twitterUrl: 'https://twitter.com/ogilvyhonduras?lang=es',
  };

  return (
    <Row className={`${FooterStyles.container} m-0`}>
      <Col className="d-flex align-items-center">
        <div className="d-none d-sm-none d-md-none d-lg-block d-xl-block w-100">
          <ContentForLandscape {...footerContent} />
        </div>

        <div className="d-block d-sm-block d-md-block d-lg-none d-xl-none w-100">
          <ContentForPortrait {...footerContent} />
        </div>
      </Col>
    </Row>
  );
}
