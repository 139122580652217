// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__g7De2 {\n  padding-right: 37px;\n  padding-left: 37px;\n}\n\n.styles_title__3SAFg {\n  font-size: 28px;\n  font-family: OgilvySerif_LightItalic;\n}\n\n.styles_option__vl1eY {\n  cursor: pointer;\n}\n\n.styles_optionTitle__1khWL {\n  font-size: 15px;\n  font-family: OgilvySans_Light;\n}\n\n.styles_optionTitleSelected__2qleb {\n  font-weight: bolder;\n  font-family: OgilvySans_RegularItalic !important;\n}\n\n.styles_rightOption__1dZVN {\n  width: 35px;\n  height: 35px;\n  background-size: 35px;\n  opacity: 1;\n  transition: opacity 0.3s ease;\n  cursor: pointer;\n}\n\n.styles_rightOption__1dZVN:hover {\n  opacity: 0.4;\n  transition: opacity 0.3s ease;\n}\n", "",{"version":3,"sources":["webpack://src/pages/category/components/category-menu/styles.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,oCAAoC;AACtC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;EACnB,gDAAgD;AAClD;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,UAAU;EACV,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,6BAA6B;AAC/B","sourcesContent":[".container {\n  padding-right: 37px;\n  padding-left: 37px;\n}\n\n.title {\n  font-size: 28px;\n  font-family: OgilvySerif_LightItalic;\n}\n\n.option {\n  cursor: pointer;\n}\n\n.optionTitle {\n  font-size: 15px;\n  font-family: OgilvySans_Light;\n}\n\n.optionTitleSelected {\n  font-weight: bolder;\n  font-family: OgilvySans_RegularItalic !important;\n}\n\n.rightOption {\n  width: 35px;\n  height: 35px;\n  background-size: 35px;\n  opacity: 1;\n  transition: opacity 0.3s ease;\n  cursor: pointer;\n}\n\n.rightOption:hover {\n  opacity: 0.4;\n  transition: opacity 0.3s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__g7De2",
	"title": "styles_title__3SAFg",
	"option": "styles_option__vl1eY",
	"optionTitle": "styles_optionTitle__1khWL",
	"optionTitleSelected": "styles_optionTitleSelected__2qleb",
	"rightOption": "styles_rightOption__1dZVN"
};
export default ___CSS_LOADER_EXPORT___;
