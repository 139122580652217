import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PagesStateType } from './types';
import { PageType, PageDetailsType } from '../../services/types';

const initialState: PagesStateType = {
  loadingPages: false,
  editingPage: false,
  pages: [],
  pageIdSelected: '',
  pageToEdit: undefined,
  hasPageBeenEdited: false,
}

export const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    startFetching: (state) => ({ ...state, loadingPages: true }),

    finishFetching: (state) => ({ ...state, loadingPages: false }),

    startEditingPage: (state) => ({ ...state, editingPage: true }),

    finishEditingPage: (state) => ({ ...state, editingPage: false }),

    setPages: (state, action: PayloadAction<Array<PageType>>) => ({
      ...state,
      pages: action.payload,
    }),

    selectPage: (state, action: PayloadAction<string>) => ({
      ...state, pageIdSelected: action.payload,
    }),

    setPageToEdit: (state, action: PayloadAction<PageDetailsType>) => ({
      ...state, pageToEdit: action.payload,
    }),

    lowerFlagThatPageHasBeenEdited: (state) => ({ ...state, hasPageBeenEdited: false }),

    raiseFlagThatPageHasBeenEdited: (state) => ({ ...state, hasPageBeenEdited: true }),
  },
});

export const PagesActions = pagesSlice.actions;
