import React from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { TopButtonsPropsType } from "./types"

import Styles from "./styles.module.css"

export default function TopButtons(props: TopButtonsPropsType) {
  const {
    handleGoingBackButton,
    handleSaveChangesButton,
    isSaveButtonDisabled
  } = props

  return (
    <Row
      className={`${Styles.topButtonsContainer} mx-0 p-4 w-100 position-fixed`}
    >
      <Col className={`px-4 d-flex justify-content-between`}>
        <button
          className={Styles.goBack}
          disabled={false}
          onClick={handleGoingBackButton}
        >
          Regresar
        </button>

        <span className="mx-1" />

        <button
          className={Styles.save}
          disabled={isSaveButtonDisabled}
          onClick={handleSaveChangesButton}
        >
          Guardar cambios
        </button>
      </Col>
    </Row>
  )
}
