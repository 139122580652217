import React, { useEffect } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { GalleryOfPeoplePropsType, PeopleType } from "./types"

import PersonItem from "./person-item"

import Styles from "./styles.module.css"

export default function GalleryOfPeople(props: GalleryOfPeoplePropsType) {
  const { people } = props

  useEffect(() => {}, [])

  const scrollToTheLeft = () => {
    // tslint:disable-next-line
    document!.getElementById("people-container")!.scrollLeft -= 400
  }

  const scrollToTheRight = () => {
    document!.getElementById("people-container")!.scrollLeft += 400
  }

  return (
    <Row className={`${Styles.container} mx-0`}>
      <Col className="d-flex justify-content-start align-items-center px-0 w-100">
        <Row className={`${Styles.controlContainer} mx-0`}>
          <Col
            xs="auto"
            className={`${Styles.control} d-flex justify-content-center align-items-center px-0`}
          >
            <img src="/icons/arrow-left-gray.png" onClick={scrollToTheLeft} />
          </Col>

          <Col
            className={`${Styles.center} d-flex justify-content-center align-items-center px-0`}
          >
            <Row
              id="people-container"
              className={`${Styles.peopleContainer} mx-0`}
            >
              {people.map(
                (person: PeopleType, index: number): Object => (
                  <PersonItem {...person} key={`person_${index + 1}`} />
                )
              )}
            </Row>
          </Col>

          <Col
            xs="auto"
            className={`${Styles.control} d-flex justify-content-center align-items-center px-0`}
          >
            <img src="/icons/arrow-right-gray.png" onClick={scrollToTheRight} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
