// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_label__19ZHc {\n  font-size: 25px;\n  font-family: OgilvySerif_LightItalic;\n  color: rgb(174, 173, 173);\n}\n\n.styles_dropdown__20Q6u {\n  border-top-width: 0;\n  border-left-width: 0;\n  border-right-width: 0;\n  border-bottom-width: 1px;\n  height: 60px;\n  background-color: transparent;\n  font-size: 25px;\n  font-family: OgilvySans_Regular;\n  padding-bottom: 15px;\n}\n\n.styles_dropdown__20Q6u:focus {\n  outline: 0;\n}\n\n.styles_dropdown__20Q6u:invalid {\n  color: rgb(214, 214, 214);\n}\n", "",{"version":3,"sources":["webpack://src/components/select/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,oCAAoC;EACpC,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,qBAAqB;EACrB,wBAAwB;EACxB,YAAY;EACZ,6BAA6B;EAC7B,eAAe;EACf,+BAA+B;EAC/B,oBAAoB;AACtB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".label {\n  font-size: 25px;\n  font-family: OgilvySerif_LightItalic;\n  color: rgb(174, 173, 173);\n}\n\n.dropdown {\n  border-top-width: 0;\n  border-left-width: 0;\n  border-right-width: 0;\n  border-bottom-width: 1px;\n  height: 60px;\n  background-color: transparent;\n  font-size: 25px;\n  font-family: OgilvySans_Regular;\n  padding-bottom: 15px;\n}\n\n.dropdown:focus {\n  outline: 0;\n}\n\n.dropdown:invalid {\n  color: rgb(214, 214, 214);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "styles_label__19ZHc",
	"dropdown": "styles_dropdown__20Q6u"
};
export default ___CSS_LOADER_EXPORT___;
