// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__19Krb {\n  /* position: absolute;\n  top: 150px;\n  left: 0;\n  right: 0; */\n}\n\n.styles_goBack__teMy6 {\n  font-size: 25px;\n  font-family: OgilvySerif_LightItalic;\n}\n\n.styles_goBack__teMy6 {\n  border-top-width: 1px;\n  border-left-width: 1px;\n  border-right-width: 1px;\n  border-bottom-width: 1px;\n  border-radius: 40px;\n  background-color: transparent;\n  padding: 5px 40px;\n  font-size: 15px;\n  color: rgb(59, 58, 58);\n  transition: background-color 0.5s ease;\n}\n\n.styles_goBack__teMy6:hover {\n  background-color: rgb(230, 230, 231);\n}\n\n.styles_goBack__teMy6:focus {\n  outline: 0;\n}\n", "",{"version":3,"sources":["webpack://src/pages/edit-category/styles.module.css"],"names":[],"mappings":"AAAA;EACE;;;aAGW;AACb;;AAEA;EACE,eAAe;EACf,oCAAoC;AACtC;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,uBAAuB;EACvB,wBAAwB;EACxB,mBAAmB;EACnB,6BAA6B;EAC7B,iBAAiB;EACjB,eAAe;EACf,sBAAsB;EACtB,sCAAsC;AACxC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".container {\n  /* position: absolute;\n  top: 150px;\n  left: 0;\n  right: 0; */\n}\n\n.goBack {\n  font-size: 25px;\n  font-family: OgilvySerif_LightItalic;\n}\n\n.goBack {\n  border-top-width: 1px;\n  border-left-width: 1px;\n  border-right-width: 1px;\n  border-bottom-width: 1px;\n  border-radius: 40px;\n  background-color: transparent;\n  padding: 5px 40px;\n  font-size: 15px;\n  color: rgb(59, 58, 58);\n  transition: background-color 0.5s ease;\n}\n\n.goBack:hover {\n  background-color: rgb(230, 230, 231);\n}\n\n.goBack:focus {\n  outline: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__19Krb",
	"goBack": "styles_goBack__teMy6"
};
export default ___CSS_LOADER_EXPORT___;
