import { ParagraphPropsType } from './types';

export function constructStyle(props: ParagraphPropsType): Object {
  const {
    isBold = false,
    isKursive = false,
    isUnderlined = false,
    fontFamily = 'OgilvySans_Regular',
    fontSize = 12,
    maxHeight = '100%',
    lineHeight = 1,
    fontColor = 'rgb(0,0,0)',
    overflow = 'none',
  } = props;

  return {
    fontFamily,
    fontWeight: isBold ? 'bold' : 'normal',
    fontStyle: isKursive ? 'italic' : 'normal',
    fontSize,
    maxHeight,
    lineHeight,
    color: fontColor,
    textDecoration: isUnderlined ? 'underline' : 'initial',
    overflow,
  };
}