import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import { UsersService } from "./services/users-service"
import { AuthenticatedUserType } from "./services/types"
import { RootState } from "./pages/root-reducer"
import { LoginActions } from "./pages/login/slice"

interface AuthenticationStatusesType {
  PENDING: string
  AUTHENTICATED: string
  UNAUTHENTICATED: string
}

const AUTH_STATUSES: AuthenticationStatusesType = {
  PENDING: "PENDING",
  AUTHENTICATED: "AUTHENTICATED",
  UNAUTHENTICATED: "UNAUTHENTICATED"
}

interface AuthenticationPropsType {
  children: Array<any> | any
  rolesAllowed: Array<string>
}

export default function AuthenticationRestriction(
  props: AuthenticationPropsType
) {
  const [authenticationStatus, setAuthenticationStatus] = useState(
    AUTH_STATUSES.PENDING
  )
  const { authenticatedUser } = useSelector((state: RootState) => state.login)
  const dispatch = useDispatch()
  const { push } = useHistory()

  const fetchUser = async (): Promise<void> => {
    const userId: string | null = localStorage.getItem("userId")

    if (!userId) {
      setAuthenticationStatus(AUTH_STATUSES.UNAUTHENTICATED)
      return
    }

    const user: AuthenticatedUserType | null =
      await UsersService.getInstance().getUser(userId)

    console.log('USER', user);
    dispatch(LoginActions.setAuthenticatedUser(user as AuthenticatedUserType))
    setAuthenticationStatus(
      user ? AUTH_STATUSES.AUTHENTICATED : AUTH_STATUSES.UNAUTHENTICATED
    )
  }

  useEffect(() => {
    if (authenticatedUser) {
      setAuthenticationStatus(AUTH_STATUSES.AUTHENTICATED)
      return
    }

    fetchUser()
  }, [])

  if (authenticationStatus === AUTH_STATUSES.PENDING) {
    return null
  }

  if (authenticationStatus === AUTH_STATUSES.UNAUTHENTICATED) {
    push("/")
  }

  if (
    authenticatedUser &&
    !props.rolesAllowed.includes(authenticatedUser!.role)
  ) {
    push("/menu")
  }

  return props.children
}
