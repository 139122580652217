import React, { useCallback, useEffect, useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { PurposeSectionPropsType } from "./types"

import Input from "../../../../components/input"
import HtmlEditor from "../../../../components/html-editor"

import Styles from "./styles.module.css"

export default function PurposeSection(props: PurposeSectionPropsType) {
  const { id, typeName, language, updatePurposeSection } = props

  const [title, setSectionTitle] = useState<string>(props.title)
  const [paragraph, setSectionParagraph] = useState<string>(props.paragraph)
  const [imageRoute, setSectionImageRoute] = useState<string>(props.imageRoute)

  const updateSection = useCallback((): void => {
    updatePurposeSection({
      title,
      paragraph,
      imageRoute,
      id,
      typeName,
      language
    })
  }, [id, typeName, language, title, paragraph, imageRoute])

  useEffect(() => {
    updateSection()
  }, [title, paragraph, imageRoute])

  return (
    <div className={Styles.worksTypeContainer}>
      <Row className="mx-0 w-100 pb-4">
        <Col className="px-0 pb-4 d-flex justify-content-center align-items-center w-100 py-2">
          <span className={`${Styles.title} pl-4`}>Sección de propósito</span>
        </Col>
      </Row>

      <Row className="mx-0 w-100 p-4">
        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <Input
              value={title}
              setValue={(newTitle: string) => setSectionTitle(newTitle)}
              placeholder="Título"
              label={`Título de la sección`}
            />
          </div>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <HtmlEditor
              value={paragraph}
              setValue={(newParagraph: string) =>
                setSectionParagraph(newParagraph)
              }
              placeholder="Párrafo"
              label={`Párrafo donde se describe el proósito de la Compañía`}
            />
          </div>
        </Col>
      </Row>

      <Row className="mx-0 w-100 p-4">
        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mb-4 pb-4 px-4">
          <div className="mb-1">
            <Input
              value={imageRoute}
              setValue={(newImageRoute: string) =>
                setSectionImageRoute(newImageRoute)
              }
              placeholder="Ingrese la url de la imagen"
              label={`Url de la imagen`}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}
