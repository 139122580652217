import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';

import useTimeout from '../../components/reactivity-to-general-behaviors/timeout';

import { HeaderPropsType } from './types';

import Styles from './styles.module.css';

export default function Header(props: HeaderPropsType) {
  const { onClose } = props;
  const [logoAnimationClass, setLogoAnimationClass] = useState('');

  useTimeout({
    handler: () => setLogoAnimationClass(Styles.display),
    time: 200,
  });

  return (
    <div>
      <Row className="mx-0 w-100 mb-4">
        <Col className="px-0 w-100 d-flex justify-content-center py-4">
          <Link to="/">
            <img className={`${Styles.logo} ${logoAnimationClass}`} src="/images/logo_white.png" />
          </Link>
        </Col>
      </Row>

      <img onClick={onClose} className={`${Styles.close} mt-4 mr-4`} src="/icons/cancel.png" />
    </div>
  )
}
