import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector, useDispatch } from 'react-redux';

import {
  SubcategoryType,
} from '../../../../services/categories-service';

import Select from '../../../../components/select';
import SubcategorySeletedForm from './subcategory-selected-form';
import { CategoriesActions } from '../../../category/slice';
import { RootState } from '../../../root-reducer';

import Styles from './styles.module.css';

export default function SubcategoryForm() {
  const dispatch = useDispatch();

  const {
    categoryToEdit,
    subcategories,
    subcategoriesToEdit,
    subcategorySelected,
    indexOfSubcategorySelected,
    whichSubcategoriesHaveBeenEdited,
  } = useSelector((state: RootState) => state.categories);

  const onSelectedSubcategory = (id: number) => {
    dispatch(CategoriesActions.selectSubcategory(subcategoriesToEdit[id]));
    dispatch(CategoriesActions.setIndexOfSubcategorySelected(id));
  };

  const updateSubcategoryInState = (updatedSubcategory: SubcategoryType) => {
    dispatch(CategoriesActions.editSubcategory(updatedSubcategory));
  };

  return (
    <>
      {
        subcategoriesToEdit.length > 0 && (
          <Row className="mx-4 mb-4">
            <Col xl={2} lg={2} md={2} />

            <Col xl={8} lg={8} md={8} sm={12} xs={12} className="">
              <Select
                optionSelected={indexOfSubcategorySelected}
                label="Seleccione una subcategoría"
                selectOption={onSelectedSubcategory}
                optionsLabels={subcategories.map((subcategory: SubcategoryType) => subcategory.title)}
              />
            </Col>

            <Col xl={2} lg={2} md={2} />
          </Row>
        )
      }

      {
        subcategorySelected && (
          <Row className={`${Styles.subcategoryFormContainer} w-100 mx-0 p-4`}>
            <Col className={`p-4`}>
              <SubcategorySeletedForm
                categoryId={categoryToEdit?.id as string}
                information={subcategorySelected}
                updateSubcategoryInState={updateSubcategoryInState}
                wasInformationEdited={whichSubcategoriesHaveBeenEdited[indexOfSubcategorySelected]}
              />
            </Col>
          </Row>
        )
      }
    </>
  )
}
