import { useEffect, useState } from 'react';
import Player from 'react-player';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Styles from './styles.module.css';

interface VideoPlayerPropsType {
  close: () => void,
  videoUrl: string
};

export default function VideoPlayer(props: VideoPlayerPropsType) {
  const { close, videoUrl } = props;

  const [isVideoPlaying, playVideo] = useState(false);
  const [extraClass, setExtraClass] = useState('');

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        playVideo(() => true);
        setExtraClass(() => Styles.extraVideoContainer);
      },
      250,
    );
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const closePlayer = () => {
    playVideo(() => false);
    close();
  };

  return (
    <Row className={`${Styles.videoContainer} ${extraClass} mx-0`}>
      <Col className="d-flex justify-content-center align-items-center">
        <Player
          url={videoUrl}
          controls
          width="100%"
          height="100%"
          loop
          playing={isVideoPlaying}
        />

        <img
          className={`${Styles.closeIcon} mt-3 mr-3`}
          src="/images/icons/cancel.png"
          onClick={close}
          alt="cancel-icon"
        />
      </Col>
    </Row>
  );
}
