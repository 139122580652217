import React, { useState, useEffect } from 'react';
import {ResponsiveImageType} from "../../services/types";

interface UseResponsiveImagePropsType {
  responsiveImages: Array<ResponsiveImageType>
}

interface Interval {
  from: string,
  to: string,
}

const useResponsiveImage = (props: UseResponsiveImagePropsType) => {
  const { responsiveImages } = props;

  const [imageRoute, setImageRoute] = useState<string>('');
  const [widthIntervals, setWidthIntervals] = useState<Array<Interval>>([]);
  const [heightIntervals, setHeightIntervals] = useState<Array<Interval>>([]);

  const buildIntervals = (values: Array<string>) => {
    const intervals: Array<Interval> = [];
    let previousValue = 'inf';

    values.forEach((value) => {
      intervals.push({
        from: previousValue,
        to: value,
      });

      previousValue = value;
    });

    intervals.push({
      from: previousValue,
      to: '0',
    });

    return intervals;
  };

  const buildWidthIntervals = () => {
    const widths = responsiveImages
      .filter((item) => item.width)
      .map((item) => item.width)
      .sort((a, b) => a < b ? -1 : 1);

    const intervals: Array<Interval> = buildIntervals(widths);
    setWidthIntervals(intervals);
  };

  const buildHeightIntervals = () => {
    const heights = responsiveImages
      .filter((item) => item.height)
      .map((item) => item.height)
      .sort((a, b) => a < b ? 1 : -1);

    const intervals: Array<Interval> = buildIntervals(heights);
    setHeightIntervals(intervals);
  };

  useEffect(() => {
    buildWidthIntervals();
    buildHeightIntervals();
  }, []);

  const determineImageRoute = () => {
    if (responsiveImages.length === 1 && !responsiveImages[0].width && !responsiveImages[0].height) {
      setImageRoute(responsiveImages[0].image);
      return;
    }

    const width: number = window.innerWidth;
    const height: number = window.innerHeight;

    const widthInterval = widthIntervals.find((interval: Interval) => Number(interval.to) <= width);
    let markedWidth = widthInterval ? widthInterval.from : 'inf';
    markedWidth = markedWidth === 'inf' ? '' : markedWidth;

    const heightInterval = heightIntervals.find((interval: Interval) => Number(interval.to) <= height);
    let markedHeight = heightInterval ? heightInterval.from : 'inf';
    markedHeight = markedHeight === 'inf' ? '' : markedHeight;

    const responsiveImage = responsiveImages.find((item: ResponsiveImageType) => item.height === markedHeight && item.width === markedWidth);

    if (responsiveImage) setImageRoute(responsiveImage.image);
    else setImageRoute(responsiveImages[0].image);
  };

  useEffect(() => {
    if (!widthIntervals.length || !heightIntervals.length) return;

    determineImageRoute();
    window.addEventListener("resize", determineImageRoute);
    return () => window.removeEventListener("resize", determineImageRoute);
  }, [widthIntervals.length, heightIntervals.length]);

  return {imageRoute};
};

export default useResponsiveImage;
